import { SAID_PIN_CAPTURE, SAID_PIN_ERROR } from '../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case SAID_PIN_CAPTURE:
            return { ...state, data: action.payload, error: undefined };
        case SAID_PIN_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
}
