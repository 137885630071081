import React from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';

export class IbCircularProgressbar extends React.Component {
    static propTypes = {
        seconds: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        if (props.paused === false) {
            this.state = { count: props.seconds * 1000 };
        }
    }

    state = {
        count: this.props.seconds * 1000,
    };

    componentDidMount() {
        this.timer = setInterval(this.tick, 1000);
    }

    componentWillReceiveProps() {
        if (this.props.settings) {
            if (this.props.settings.update) {
                if (this.props.settings.update === true) {
                    this.setState({ count: this.props.seconds * 1000 });
                }
            }

            if (this.props.settings.restart) {
                if (this.props.settings.restart === true) {
                    this.restart();
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    timer = null;

    restart = () => {
        clearInterval(this.timer);
        this.timer = setInterval(this.tick, 1000);
    };

    tick = () => {
        if (this.props.paused === false) {
            const count = this.state.count - 1000;
            const that = this;
            this.setState({ count }, () => {
                if (count <= 0) {
                    clearInterval(that.timer);
                    that.timer = null;
                    that.props.onComplete();
                }
            });
        }
    };

    render() {
        return (
            <div className="progress-circle">
                <CircularProgressbar
                    className="progressbar"
                    percentage={this.state.count / 10 / this.props.seconds}
                    textForPercentage={() => ''}
                    strokeWidth="3"
                />
                <div className="seconds">
                    {this.state.count / 1000}
                    <span>seconds</span>
                </div>
            </div>
        );
    }
}

export default IbCircularProgressbar;
