import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
    CAPTURE_TAX,
    captureTaxSuccessAction,
} from '../actions/captureTax';
import {
    showCommonExceptionAction,
    moveCreditCardStepAction,
    hideConfirmPayTaxAction,
} from '../actions/application';
import { CREDIT_CARD_RESET_STEPS } from '../constants';

export const captureTaxEpic = (action$, store, { captureTaxService }) =>
action$.pipe(
    ofType(CAPTURE_TAX),
    switchMap(action => {
        return captureTaxService(action.payload).pipe(
            mergeMap(() => {
                return Observable.of( 
                    captureTaxSuccessAction(),
                    hideConfirmPayTaxAction(),
                    moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.pin.stepId)
                )
            }),
            catchError(() => {
                return Observable.of(
                    hideConfirmPayTaxAction(),
                    showCommonExceptionAction()
                );
            }),
        );
    }),
);