import React, { useEffect } from "react";
import TagManager from 'react-gtm-module';
import { Config } from '../config';
import { MAP_EVENT_CATEGORY } from '../constants';

const { gtmId } = Config.googleTagManager;

export const dataTrackingModal = (action) => {
    TagManager.dataLayer({
        gtmId,
        dataLayer: {
            event: 'Show-Popup',
            category: MAP_EVENT_CATEGORY[window.location.pathname],
            action
        },
        dataLayerName: 'PageDataLayer',
    });
};

export const withModalTracking = (WrappedComponent) => {
    const TrackerWrapper = (props) => {
        useEffect(() => {
            if (props.isShow) {
                const { id } = props;
                dataTrackingModal(id);
            }
        }, [props.isShow]);

        return <WrappedComponent {...props} />;
    };
    return TrackerWrapper;
};
