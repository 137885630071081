import React from 'react';
import TagManager from 'react-gtm-module';
import { Config } from '../../config';
import { MAP_EVENT_CATEGORY } from '../../constants';
import { useSpring, animated } from 'react-spring';

export const Loader = () => <div className="loader" />;

export default function TymeButton({
    isLoading,
    children,
    className = 'btn tyme-btn btn-primary',
    onClick = () => {},
    id,
    name,
    ...props
}) {
    /* avoid loading flashes if the loading state is too short. */
    const [showLoader, setShowLoader] = React.useState(false);

    React.useEffect(() => {
        isLoading && setShowLoader(true);

        // Show loader a bits longer to avoid loading flash
        if (!isLoading && showLoader) {
            const timeout = setTimeout(() => {
                setShowLoader(false);
            }, 200);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isLoading, showLoader]);

    /* Capture the dimensions of the button before the loading happens so it doesn’t change size. */
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref.current && ref.current.getBoundingClientRect().width) {
            setWidth(ref.current.getBoundingClientRect().width);
        }
        if (ref.current && ref.current.getBoundingClientRect().height) {
            setHeight(ref.current.getBoundingClientRect().height);
        }
    }, [children]);

    const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
    const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });

    const addDataLayer = () => {
        TagManager.dataLayer({
            gtmId: Config.googleTagManager.gtmId,
            dataLayer: {
                event: 'Button-Click',
                category: MAP_EVENT_CATEGORY[window.location.pathname],
                action: name,
                label: id,
            },
            dataLayerName: 'PageDataLayer',
        });
    };

    const onClickEvent = () => {
        addDataLayer();
        onClick();
    };

    return (
        <button
            {...props}
            className={className}
            ref={ref}
            onClick={onClickEvent}
            id={id}
            name={name}
            style={
                showLoader
                    ? {
                          width: `${width}px`,
                          height: `${height}px`,
                      }
                    : {}
            }>
            {showLoader ? (
                <animated.div style={fadeOutProps}>
                    <Loader />
                </animated.div>
            ) : (
                <animated.div style={fadeInProps}>{children}</animated.div>
            )}
        </button>
    );
}
