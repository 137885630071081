import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import { getUuid } from '../../utils/utils';
import {
    SAID_PIN_SUBMIT,
    SAID_PIN_CAPTURE,
    SAID_PIN_ERROR,
    SAID_PIN_RESET_STATE,
} from '../../actions/types';

export const submitSaIdPin = (args, callback) => ({
    type: SAID_PIN_SUBMIT,
    payload: { args, callback },
});

export const submitSaIdPinEpic = action$ =>
    action$.ofType(SAID_PIN_SUBMIT).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.activation}/${Config.apiVersion}${Config.apiUri.activateActivation}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
            },
            body: {
                said: action.payload.args.said,
                pin: action.payload.args.pin,
            },
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: SAID_PIN_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(SAID_PIN_ERROR, xhr));
            }),
    );

export const resetState = () => ({ type: SAID_PIN_RESET_STATE });
