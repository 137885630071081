import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowPasswordTechnicalError } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const PasswordTechnicalErrorModal = ({ onHide, onSubmit }) => {
    const isShowPasswordTechnicalError = useSelector(getIsShowPasswordTechnicalError);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_EXCEPTION_ERROR}
            title="We can’t set up your password right now."
            type={MODAL_TYPE.ERROR}
            isShow={isShowPasswordTechnicalError}
            handleHide={onHide}>
            <div>
                <p>In the meantime, you can use your Login PIN to access your account on the TymeBank app, at TymeBank kiosks and using USSD.</p>
                <p>To set up your password later, tap “Already a TymeBank customer but new to Internet Banking” on the TymeBank Login page</p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.MODAL_PASSWORD_TECHNICAL_ERROR}
                        name={BUTTON_NAME.GOT_IT}
                        onClick={onSubmit}>
                        Got it
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

PasswordTechnicalErrorModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PasswordTechnicalErrorModal;
