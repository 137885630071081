import { createAction } from 'redux-actions';

export const SEARCH_SAID = 'SEARCH_SAID';
export const SEARCH_SAID_SUCCESS_RESET_PIN = 'SEARCH_SAID_SUCCESS_RESET_PIN';
export const SEARCH_SAID_FAILED_RESET_PIN = 'SEARCH_SAID_FAILED_RESET_PIN';
export const SEARCH_SAID_SUCCESS_RESET_PASSWORD = 'SEARCH_SAID_SUCCESS_RESET_PASSWORD';
export const SEARCH_SAID_FAILED_RESET_PASSWORD = 'SEARCH_SAID_FAILED_RESET_PASSWORD';
export const SEARCH_SAID_FAILED_NOT_ACTIVATED_RESET_PASSWORD = 'SEARCH_SAID_FAILED_NOT_ACTIVATED_RESET_PASSWORD';

export const searchSaidAction = createAction(SEARCH_SAID);
export const searchSaidSuccessResetPinAction = createAction(SEARCH_SAID_SUCCESS_RESET_PIN);
export const searchSaidFailedResetPinAction = createAction(SEARCH_SAID_FAILED_RESET_PIN);
export const searchSaidSuccessResetPasswordAction = createAction(SEARCH_SAID_SUCCESS_RESET_PASSWORD);
export const searchSaidFailedResetPasswordAction = createAction(SEARCH_SAID_FAILED_RESET_PASSWORD);
export const searchSaidFailedNotActivatedResetPasswordAction = createAction(SEARCH_SAID_FAILED_NOT_ACTIVATED_RESET_PASSWORD);
