import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import Modal from '.';
import Button from '../form-elements/button';
import timeoutIcon from '../../assets/images/timeout.png';

export class ConnectionTimeoutModal extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    body = connectionTimeout => {
        return (
            <div>
                <p>{connectionTimeout.body}</p>
            </div>
        );
    };

    footer = buttonTranslation => {
        return (
            <div>
                <Button
                    id="connection-timeout-ok"
                    type="button"
                    className="btn btn-solid"
                    name="ok"
                    data-dismiss="modal"
                    value={buttonTranslation.ok}
                />
            </div>
        );
    };

    render() {
        const { t } = this.props;
        const connectionTimeout = t(translationKeys.connectionTimeout, { returnObjects: true });
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });

        const icon = timeoutIcon;

        return (
            <Modal
                id="connectionTimeout"
                header={{
                    icon,
                    heading: connectionTimeout.heading,
                }}
                body={this.body(connectionTimeout)}
                footer={this.footer(buttonTranslation)}
            />
        );
    }
}

export default translate()(ConnectionTimeoutModal);
