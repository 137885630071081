import React from 'react';
import PropTypes from 'prop-types';
import TymeInput from './TymeInput';
import { formatInputSaid } from '../../utils/utils';
import { useRunAfterUpdate } from '../../utils/useHooks';

export const maxInputLength = 16;

export const calcCursor = (text, cursor) => {
    const formatted = formatInputSaid(text);
    const newCursor = cursor === text.length ? formatted.length : cursor;
    return [formatted, newCursor];
};

export const TymeInputSAID = ({
    label,
    value,
    error,
    placeholder = 'E.g. 920123 1234 12 3',
    onChange,
    onKeyPress,
    ...rest
}) => {
    const runAfterUpdate = useRunAfterUpdate();

    const handleChange = e => {
        const inputSaid = e.target;
        const rawValue = inputSaid.value;
        const cursor = inputSaid.selectionStart;
        const [formattedValue, newCursor] = calcCursor(rawValue, cursor);
        onChange(formattedValue);

        runAfterUpdate(() => {
            inputSaid.selectionStart = newCursor;
            inputSaid.selectionEnd = newCursor;
        });
    };

    return (
        <TymeInput
            type="tel"
            label={label}
            value={value}
            error={error}
            maxLength={maxInputLength}
            placeholder={placeholder}
            handleChange={handleChange}
            handleKeyPress={onKeyPress}
            {...rest}
        />
    );
};

TymeInputSAID.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.exact({
        message: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
};

export default TymeInputSAID;
