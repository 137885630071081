import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RESET_STEPS } from '../../constants';
import { getResetPinShowSteps, trimBlank, validateInputSaid } from '../../utils/utils';
import { useError, usePageTitle } from '../../utils/useHooks';
import TymeInputSAID, { maxInputLength } from '../../components/tyme-input/TymeInputSAID';
import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';
import StepBar from '../../components/common/StepBar';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import headerImage from '../../assets/images/header-1.png';

const StepSAID = ({ initialErrors = null, submitting = false, onSubmit, onCancel }) => {
    const [said, setSaid] = useState('');
    const [errors, setErrors] = useError(initialErrors);

    usePageTitle('Enter your ID number | TymeBank');
    React.useEffect(() => {
        said.length === maxInputLength &&
            !validateInputSaid(said) &&
            setErrors({ message: 'Invalid ID number, please try again.' });
    }, [said]);

    const handleInputChange = inputValue => {
        setErrors(null);
        setSaid(inputValue);
    };

    const isDisabledSubmit = submitting || !!errors || said.length < maxInputLength;

    const handleNext = () => {
        onSubmit(trimBlank(said));
    };

    const handleKeyPress = keyCode => {
        // Press Enter
        return keyCode === 13 && !isDisabledSubmit && handleNext();
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Enter your South African ID number</p>
                <p>Your ID number allows us to identify you.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <StepBar
                                label={RESET_STEPS.said.title}
                                currentStep={RESET_STEPS.said.stepProgress}
                                totalStep={getResetPinShowSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-said step-said-container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <TymeInputSAID
                                            label="SA ID number"
                                            value={said}
                                            error={errors}
                                            autoComplete="off"
                                            autoFocus
                                            onChange={handleInputChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="btn-control-container">
                                            <TymeButton
                                                className="btn tyme-btn btn-secondary"
                                                disabled={submitting}
                                                onClick={onCancel}>
                                                Cancel
                                            </TymeButton>
                                            <TymeButton
                                                isLoading={submitting}
                                                disabled={isDisabledSubmit}
                                                onClick={handleNext}>
                                                Next
                                            </TymeButton>
                                        </div>
                                    </div>
                                </div>
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepSAID.propTypes = {
    submitting: PropTypes.bool,
    initialErrors: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default StepSAID;
