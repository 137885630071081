import {
    VERIFY_CARD_PAN,
    VERIFY_CARD_PAN_SUCCESS_RESET_PIN,
    VERIFY_CARD_PAN_FAILED_RESET_PIN,
    VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD,
    VERIFY_CARD_PAN_FAILED_RESET_PASSWORD,
    SET_CARD_ATTEMPT_ERROR,
} from '../actions/card';

const initialState = {
    isVerifyCardPan: false,
    isVerifyCardPanFailed: false,
    isCardAttemptError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CARD_PAN:
            return {
                ...state,
                isVerifyCardPan: true,
            };
        case VERIFY_CARD_PAN_SUCCESS_RESET_PIN:
            return {
                ...state,
                isVerifyCardPan: false,
            };
        case VERIFY_CARD_PAN_FAILED_RESET_PIN:
            return {
                ...state,
                isVerifyCardPan: false,
                isVerifyCardPanFailed: true,
            };
        case VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD:
            return {
                ...state,
                isVerifyCardPan: false,
            };
        case VERIFY_CARD_PAN_FAILED_RESET_PASSWORD:
            return {
                ...state,
                isVerifyCardPan: false,
                isVerifyCardPanFailed: true,
            };
        case SET_CARD_ATTEMPT_ERROR:
            return {
                ...state,
                isCardAttemptError: true,
            };
        default:
            return state;
    }
};
