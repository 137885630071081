import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    REQUEST_MCQ,
    VERIFY_MCQ,
    requestMCQSuccessAction,
    requestMCQFailedResetPasswordAction,
    requestMCQFailedResetPinAction,
    setMCQAttemptError,
    verifyMCQFailedResetPinAction,
    verifyMCQSuccessResetPinAction,
    verifyMCQFailedResetPasswordAction,
    verifyMCQSuccessResetPasswordAction,
} from '../actions/mcq';
import { showCommonExceptionAction } from '../actions/application';
import { RESET_CREDENTIAL_TYPE, RESET_ERROR_CODES } from '../constants';

export const requestMCQEpic = (action$, store, { requestMCQService }) =>
    action$.pipe(
        ofType(REQUEST_MCQ),
        switchMap(action => {
            const { said, stepUpToken, from } = action.payload;
            return requestMCQService({
                said,
                stepUpToken,
                type: from,
            }).pipe(
                map(({ data: { sessionId, questions } }) => {
                    return requestMCQSuccessAction({ sessionId, questions });
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        const { errors } = error.response.data;
                        const errorCode = errors && errors[0].errorCode;
                        switch (from) {
                            case RESET_CREDENTIAL_TYPE.PASSWORD:
                                return Observable.of(
                                    requestMCQFailedResetPasswordAction({ errorCode }),
                                );
                            case RESET_CREDENTIAL_TYPE.PIN:
                                return Observable.of(requestMCQFailedResetPinAction({ errorCode }));
                            default:
                                return Observable.of(showCommonExceptionAction());
                        }
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );

export const verifyMCQEpic = (action$, store, { verifyMCQService }) =>
    action$.pipe(
        ofType(VERIFY_MCQ),
        switchMap(action => {
            const { sessionId, said, creditAnswers, stepUpToken, from } = action.payload;
            return verifyMCQService({
                sessionId,
                said,
                creditAnswers,
                stepUpToken,
                type: from,
            }).pipe(
                map(() => {
                    switch (from) {
                        case RESET_CREDENTIAL_TYPE.PIN:
                            return verifyMCQSuccessResetPinAction();
                        case RESET_CREDENTIAL_TYPE.PASSWORD:
                            return verifyMCQSuccessResetPasswordAction();
                        default:
                            return Observable.of(showCommonExceptionAction());
                    }
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        const { errors } = error.response.data;
                        const errorCode = errors && errors[0].errorCode;
                        if (errorCode === RESET_ERROR_CODES.VERIFY_MCQ_FAILURE_DISABLED) {
                            return Observable.of(setMCQAttemptError());
                        }
                        switch (from) {
                            case RESET_CREDENTIAL_TYPE.PIN:
                                return Observable.of(verifyMCQFailedResetPinAction());
                            case RESET_CREDENTIAL_TYPE.PASSWORD:
                                return Observable.of(verifyMCQFailedResetPasswordAction());
                            default:
                                return Observable.of(showCommonExceptionAction());
                        }
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );
