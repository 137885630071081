import {
    CHECK_ELIGIBILITY,
    CHECK_ELIGIBILITY_FAILED_RESET_PIN,
    CHECK_ELIGIBILITY_SUCCESS_RESET_PIN,
    CHECK_ELIGIBILITY_FAILED_RESET_PASSWORD,
    CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD,
} from '../actions/eligibility';

const initialState = {
    isCheckingEligibility: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHECK_ELIGIBILITY:
            return {
                ...state,
                isCheckingEligibility: true,
            };
        case CHECK_ELIGIBILITY_SUCCESS_RESET_PIN:
            return {
                ...state,
                isCheckingEligibility: false,
            };
        case CHECK_ELIGIBILITY_FAILED_RESET_PIN:
            return {
                ...state,
                isCheckingEligibility: false,
            };
        case CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD:
            return {
                ...state,
                isCheckingEligibility: false,
            };
        case CHECK_ELIGIBILITY_FAILED_RESET_PASSWORD:
            return {
                ...state,
                isCheckingEligibility: false,
            };
        default:
            return state;
    }
};
