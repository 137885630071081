import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowBlockCreditCardFeature } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const BlockCCFeatureModal = ({ onHide, onSubmit }) => {
    const isShowBlockCreditCardFeature = useSelector(getIsShowBlockCreditCardFeature);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_FEATURE_BLOCK}
            title="Temporarily blocked due to too many attempts"
            type={MODAL_TYPE.ERROR}
            isShow={isShowBlockCreditCardFeature}
            handleHide={onHide}>
            <div>
                <p>
                    For security reasons, you’re blocked from setting up your login details for the next 24 hours.
                </p>
                <p className="text-contact">
                    To remove the block before 24 hours, contact Customer Service on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.FEATURE_BLOCK_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}>
                        OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

BlockCCFeatureModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default BlockCCFeatureModal;
