import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderIcon from '../header-icon';

export class Header extends Component {
    static propTypes = {
        header: PropTypes.object.isRequired,
    };

    render() {
        const { header } = this.props;

        const iconProps = header.timer
            ? {
                  timer: header.timer,
                  time: header.timer.time,
                  complete: header.timer.complete,
              }
            : {
                  className: 'icon-image',
                  icon: header.icon,
                  heading: header.heading,
              };

        return (
            <header
                style={{
                    backgroundImage: `url(${header.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'calc(100%) calc(0%)',
                }}>
                <div className="progress-container"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-8">
                            {header.timer && <HeaderIcon {...iconProps} />}
                            <h1>{header.heading}</h1>
                            {header.description && <p>{header.description}</p>}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
