import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
    SEARCH_SAID_SUCCESS_RESET_PIN,
    SEARCH_SAID_SUCCESS_RESET_PASSWORD,
} from '../actions/searchSaid';
import {
    VERIFY_CARD_PAN_SUCCESS_RESET_PIN,
    VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD,
} from '../actions/card';
import {
    CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD,
    CHECK_ELIGIBILITY_SUCCESS_RESET_PIN,
} from '../actions/eligibility';
import {
    REQUEST_MCQ_FAILED_RESET_PASSWORD,
    REQUEST_MCQ_FAILED_RESET_PIN,
    showRequestMCQFailedModal,
    VERIFY_MCQ_SUCCESS_RESET_PASSWORD,
    VERIFY_MCQ_SUCCESS_RESET_PIN,
} from '../actions/mcq';
import {
    SET_CREDENTIAL_SUCCESS_RESET_PIN,
    SET_CREDENTIAL_SUCCESS_RESET_PASSWORD,
} from '../actions/setCredential';
import {
    showCommonExceptionAction,
    showBlockFeatureAction,
    showBlockProfileAction,
    moveStepResetPinAction,
    showSkipMCQResetPasswordAction,
    showSkipMCQResetPinAction,
} from '../actions/application';
import { RESET_CREDENTIAL_TYPE, RESET_ERROR_CODES, RESET_STEPS } from '../constants';

export const searchSaidSuccessResetPinEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_SAID_SUCCESS_RESET_PIN, SEARCH_SAID_SUCCESS_RESET_PASSWORD),
        switchMap(() => Observable.of(moveStepResetPinAction(RESET_STEPS.otp.stepId))),
    );

export const checkEligibilitySuccessResetPinEpic = action$ =>
    action$.pipe(
        ofType(CHECK_ELIGIBILITY_SUCCESS_RESET_PIN, CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD),
        switchMap(action => {
            const { profileDisabled, hasActiveCard, featureEnabled, type } = action.payload;
            if (
                typeof profileDisabled === 'undefined' ||
                typeof hasActiveCard === 'undefined' ||
                typeof featureEnabled === 'undefined'
            ) {
                return Observable.of(showCommonExceptionAction());
            }
            if (profileDisabled) {
                return Observable.of(showBlockProfileAction());
            }
            if (!featureEnabled) {
                return Observable.of(showBlockFeatureAction());
            }
            if (!profileDisabled && hasActiveCard && featureEnabled) {
                return Observable.of(moveStepResetPinAction(RESET_STEPS.card.stepId));
            }

            if (process.env.REACT_APP_RESET_PIN_PASSWORD_MCQ === 'on') {
                return Observable.of(moveStepResetPinAction(RESET_STEPS.mcq.stepId));
            }

            return Observable.of(
                type === 'password'
                    ? showSkipMCQResetPasswordAction()
                    : showSkipMCQResetPinAction(),
            );
        }),
    );

export const verifyCardPanSuccessResetPinEpic = action$ =>
    action$.pipe(
        ofType(VERIFY_CARD_PAN_SUCCESS_RESET_PIN, VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD),
        switchMap(() => {
            return Observable.of(moveStepResetPinAction(RESET_STEPS.reset.stepId));
        }),
    );

export const setCredentialSuccessResetPinEpic = action$ =>
    action$.pipe(
        ofType(SET_CREDENTIAL_SUCCESS_RESET_PIN, SET_CREDENTIAL_SUCCESS_RESET_PASSWORD),
        switchMap(() => {
            return Observable.of(moveStepResetPinAction(RESET_STEPS.success.stepId));
        }),
    );

export const verifyMCQSuccessResetPinEpic = action$ =>
    action$.pipe(
        ofType(VERIFY_MCQ_SUCCESS_RESET_PIN, VERIFY_MCQ_SUCCESS_RESET_PASSWORD),
        switchMap(() => {
            return Observable.of(moveStepResetPinAction(RESET_STEPS.reset.stepId));
        }),
    );

const REQUEST_MCQ_FAILED = [
    RESET_ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_DATA,
    RESET_ERROR_CODES.FIDES_CREDIT_MCQ_GENERIC_ERROR,
    RESET_ERROR_CODES.FIDES_CREDIT_MCQ_ID_NOT_FOUND,
    RESET_ERROR_CODES.FIDES_CREDIT_MCQ_ID_BLOCKED,
    RESET_ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA,
];

export const requestMCQFailedResetPinEpic = action$ =>
    action$.pipe(
        ofType(REQUEST_MCQ_FAILED_RESET_PIN),
        switchMap(action => {
            const { errorCode } = action.payload;
            if (REQUEST_MCQ_FAILED.includes(errorCode)) {
                return Observable.of(
                    showRequestMCQFailedModal({ errorCode, resetType: RESET_CREDENTIAL_TYPE.PIN }),
                );
            }
            return Observable.of(showCommonExceptionAction());
        }),
    );

export const requestMCQFailedResetPasswordEpic = action$ =>
    action$.pipe(
        ofType(REQUEST_MCQ_FAILED_RESET_PASSWORD),
        switchMap(action => {
            const { errorCode } = action.payload;
            if (REQUEST_MCQ_FAILED.includes(errorCode)) {
                return Observable.of(
                    showRequestMCQFailedModal({
                        errorCode,
                        resetType: RESET_CREDENTIAL_TYPE.PASSWORD,
                    }),
                );
            }
            return Observable.of(showCommonExceptionAction());
        }),
    );