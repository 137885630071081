import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import TymeButton from '../../components/common/TymeButton';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import successIcon from '../../assets/images/success.svg';

export const StepSuccess = ({ heading, notification, headerImage, onSubmit }) => {
    const pulse = useSpring({
        from: {
            opacity: 0.7,
            transform: 'scale(1)',
        },
        to: async (next, cancel) => {
            await next({ opacity: 1, transform: 'scale(1.5)' });
            await next({ opacity: 1, transform: 'scale(1)' });
        },
    });

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">{heading}</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-success step-success-container">
                                <div className="img-container">
                                    <animated.img
                                        style={pulse}
                                        src={successIcon}
                                        alt=""
                                        className="icon"
                                    />
                                </div>
                                <p className="step-success__heading">{notification}</p>
                                <div className="step-success__footer">
                                    <div className="btn-control-container">
                                        <TymeButton onClick={onSubmit}>Done</TymeButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepSuccess.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default StepSuccess;
