import { createAction } from 'redux-actions';

export const CC_SET_CREDENTIAL_PIN = 'CC_SET_CREDENTIAL_PIN';
export const CC_SET_CREDENTIAL_PIN_SUCCESS = 'CC_SET_CREDENTIAL_PIN_SUCCESS';
export const CC_SET_CREDENTIAL_PIN_FAILED= 'CC_SET_CREDENTIAL_PIN_FAILED';

export const CC_SET_CREDENTIAL_PASSWORD = 'CC_SET_CREDENTIAL_PASSWORD';
export const CC_SET_CREDENTIAL_PASSWORD_SUCCESS = 'CC_SET_CREDENTIAL_PASSWORD_SUCCESS';
export const CC_SET_CREDENTIAL_PASSWORD_FAILED= 'CC_SET_CREDENTIAL_PASSWORD_FAILED';

export const ccSetCredentialPinAction = createAction(CC_SET_CREDENTIAL_PIN);
export const ccSetCredentialPinSuccessAction = createAction(CC_SET_CREDENTIAL_PIN_SUCCESS);
export const ccSetCredentialPinFailedAction = createAction(CC_SET_CREDENTIAL_PIN_FAILED);

export const ccSetCredentialPasswordAction = createAction(CC_SET_CREDENTIAL_PASSWORD);
export const ccSetCredentialPasswordSuccessAction = createAction(CC_SET_CREDENTIAL_PASSWORD_SUCCESS);
export const ccSetCredentialPasswordFailedAction = createAction(CC_SET_CREDENTIAL_PASSWORD_FAILED);

