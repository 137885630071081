import { createAction } from 'redux-actions';

export const REQUEST_MCQ = 'REQUEST_MCQ';
export const REQUEST_MCQ_SUCCESS = 'REQUEST_MCQ_SUCCESS';
export const REQUEST_MCQ_FAILED_RESET_PASSWORD = 'REQUEST_MCQ_FAILED_RESET_PASSWORD';
export const REQUEST_MCQ_FAILED_RESET_PIN = 'REQUEST_MCQ_FAILED_RESET_PIN';
export const VERIFY_MCQ = 'VERIFY_MCQ';
export const VERIFY_MCQ_SUCCESS_RESET_PIN = 'VERIFY_MCQ_SUCCESS_RESET_PIN';
export const VERIFY_MCQ_FAILED_RESET_PIN = 'VERIFY_MCQ_FAILED_RESET_PIN';
export const VERIFY_MCQ_SUCCESS_RESET_PASSWORD = 'VERIFY_MCQ_SUCCESS_RESET_PASSWORD';
export const VERIFY_MCQ_FAILED_RESET_PASSWORD = 'VERIFY_MCQ_FAILED_RESET_PASSWORD';
export const SET_MCQ_ATTEMPT_ERROR = 'SET_MCQ_ATTEMPT_ERROR';
export const SHOW_REQUEST_MCQ_FAILED_MODAL = 'SHOW_REQUEST_MCQ_FAILED_MODAL';
export const HIDE_REQUEST_MCQ_FAILED_MODAL = 'SHOW_REQUEST_MCQ_FAILED_MODAL';

export const requestMCQAction = createAction(REQUEST_MCQ);
export const requestMCQSuccessAction = createAction(REQUEST_MCQ_SUCCESS);
export const requestMCQFailedResetPasswordAction = createAction(REQUEST_MCQ_FAILED_RESET_PASSWORD);
export const requestMCQFailedResetPinAction = createAction(REQUEST_MCQ_FAILED_RESET_PIN);
export const verifyMCQAction = createAction(VERIFY_MCQ);
export const verifyMCQSuccessResetPinAction = createAction(VERIFY_MCQ_SUCCESS_RESET_PIN);
export const verifyMCQFailedResetPinAction = createAction(VERIFY_MCQ_FAILED_RESET_PIN);
export const verifyMCQSuccessResetPasswordAction = createAction(VERIFY_MCQ_SUCCESS_RESET_PASSWORD);
export const verifyMCQFailedResetPasswordAction = createAction(VERIFY_MCQ_FAILED_RESET_PASSWORD);
export const setMCQAttemptError = createAction(SET_MCQ_ATTEMPT_ERROR);
export const showRequestMCQFailedModal = createAction(SHOW_REQUEST_MCQ_FAILED_MODAL);
export const hideRequestMCQFailedModal = createAction(HIDE_REQUEST_MCQ_FAILED_MODAL);
