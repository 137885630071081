import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import errorIcon from '../../assets/images/error.png';
import iconInfo from '../../assets/images/info.png';
import iconSuccess from '../../assets/images/success.png';
import iconBlock from '../../assets/images/locked.svg';
import iconUndefined from '../../assets/images/unidentified_customer.svg';
import iconCustomerError from '../../assets/images/customer_error.svg';
import iconClock from '../../assets/images/timer.png';
import iconCCInfo from '../../assets/images/icon_info_cc.svg';
import {MODAL_TYPE} from '../../constants';
import { withModalTracking } from '../../utils/modalTracking';

export const ModalTemplate = ({
    id,
    title = '',
    type = MODAL_TYPE.INFO,
    isShow = false,
    handleHide,
    children
}) => {
    const icon = {
        INFO: iconInfo,
        ERROR: errorIcon,
        SUCCESS: iconSuccess,
        BLOCK: iconBlock,
        UNDEFINED: iconUndefined,
        CUSTOMER_ERROR: iconCustomerError,
        TIME_OUT: iconClock,
        CC_INFO: iconCCInfo,
    };
    return (
        <Modal show={isShow} onHide={handleHide} id={id} className="modal-template">
            <Modal.Header closeButton>
                <img src={icon[type]} alt="Info" />
                <Modal.Title componentClass="span">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};

ModalTemplate.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    isShow: PropTypes.bool,
    handleHide: PropTypes.func,
    id: PropTypes.string
};

export default withModalTracking(ModalTemplate);
