import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowPinTechnicalError } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const PinTechnicalErrorModal = ({ onHide, onSubmit }) => {
    const isShowPinTechnicalError = useSelector(getIsShowPinTechnicalError);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_PIN_TECHNICAL_ERROR}
            title="We’re having some technical issues"
            type={MODAL_TYPE.ERROR}
            isShow={isShowPinTechnicalError}
            handleHide={onHide}>
            <div>
                <p>
                    We can’t set up your login details right now. Try again later, or contact Customer Service on {' '} <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.MODAL_PASSWORD_TECHNICAL_ERROR}
                        name={BUTTON_NAME.EXIT}
                        onClick={onSubmit}>
                        Exit
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

PinTechnicalErrorModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PinTechnicalErrorModal;
