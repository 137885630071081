import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePageTitle, useError } from '../../utils/useHooks';
import {
    generateArrayValues,
    getResetPinShowSteps,
    makeStringFromNumbers as makeCardPAN,
} from '../../utils/utils';
import { Config } from '../../config';
import { RESET_STEPS } from '../../constants';
import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';
import InputCardPAN from '../../components/card/InputCardPAN';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import StepBar from '../../components/common/StepBar';
import CardVerifyAttemptModal from '../../components/card/CardVerifyAttemptModal';
import headerImage from '../../assets/images/header-4.png';
import tymeBankCard from '../../assets/images/TymeBankCard.svg';

export const StepCard = ({
    submitting = false,
    isAttemptError = false,
    initialErrors,
    onCancel,
    onSubmit,
    onAttempt,
    onSwitch,
}) => {
    const [haveEmptyInput, setHaveEmptyInput] = useState(true);
    const [numbers, setNumbers] = useState(generateArrayValues(Config.cardPan.numberOfInput));
    const [errors, setErrors] = useError(initialErrors);

    usePageTitle('Input Card Detail | TymeBank');
    useEffect(() => {
        const emptyItems = numbers.filter(item => item.value === '');
        setHaveEmptyInput(emptyItems.length > 0);
    }, [numbers]);
    useEffect(() => {
        // Clear inputs if have errors
        errors && setNumbers(generateArrayValues(Config.cardPan.numberOfInput));
    }, [errors]);

    const handleChange = numbers => {
        setErrors(null);
        setNumbers(numbers);
    };
    const handleConfirm = () => {
        onSubmit(makeCardPAN(numbers));
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Card verification</p>
                <p>Next, please enter the last 4 digits of your active debit card.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <StepBar
                                label={RESET_STEPS.card.title}
                                currentStep={RESET_STEPS.card.stepProgress}
                                totalStep={getResetPinShowSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-card step-card-container">
                                <div className="step-card__main">
                                    <div className="step-card__input-container">
                                        <InputCardPAN
                                            numbers={numbers}
                                            onChange={handleChange}
                                            errors={errors}
                                            label="Last 4 digits of card"
                                        />
                                    </div>

                                    <div className="img-container">
                                        <img src={tymeBankCard} alt="Tyme Bank Card" />
                                    </div>
                                </div>
                                <div className="row step-card__footer">
                                    <div className="col-xs-12">
                                        <div className="btn-control-container">
                                            <TymeButton
                                                className="btn tyme-btn btn-secondary"
                                                disabled={submitting}
                                                onClick={onCancel}>
                                                Cancel
                                            </TymeButton>
                                            <TymeButton
                                                isLoading={submitting}
                                                disabled={haveEmptyInput || submitting}
                                                onClick={handleConfirm}>
                                                Next
                                            </TymeButton>
                                        </div>
                                    </div>
                                </div>

                                <div className="row step-card__main">
                                    <div className="col-xs-12 mobile-text">
                                        <div style={{marginTop: '4rem'}}>
                                            <p>
                                                <a className="hover-no-underline" href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        onSwitch();
                                                    }}>
                                                    Don't have card with you?
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                                <TimeOut />
                                <CardVerifyAttemptModal
                                    onSubmit={onAttempt}
                                    onHide={onAttempt}
                                    isShow={isAttemptError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepCard.propTypes = {
    submitting: PropTypes.bool,
    isAttemptError: PropTypes.bool,
    initialErrors: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAttempt: PropTypes.func.isRequired,
    onSwitch: PropTypes.func.isRequired,
};

export default StepCard;
