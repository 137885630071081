import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { MODAL_ID, MODAL_TYPE, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { hideTimeOutAction, showTimeOutAction } from '../../actions/application';
import { Config } from '../../config';
import { getIsShowTimeOut } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import CountDownCircle from './CountDownCircle';
import TymeButton from './TymeButton';
import { resetAllState } from '../../actions';
import _ from 'lodash';
import { $emit } from 'event-bus-e2z';

const INACTIVE_USER_TIME_THRESHOLD = Config.timeoutTimerWait * 1000;
const USER_ACTIVITY_THROTTLER_TIME = 100;
let userActivityTimeout = null;
let userActivityThrottlerTimeout = null;

export const TimeOut = ({ history }) => {
    const isShowTimeOut = useSelector(getIsShowTimeOut);
    const dispatch = useDispatch();

    const [remainingSeconds, setRemainingSeconds] = useState(Config.timeoutTimer);

    useEffect(() => {
        const registerTrackingEvent = fn => {
            window.addEventListener('mousemove', fn);
            window.addEventListener('scroll', fn);
            window.addEventListener('keydown', fn); //include mousedown by default
            window.addEventListener('resize', fn);
        };
        registerTrackingEvent(userActivityThrottler);
    }, []);

    useEffect(() => {
        let timeOut;
        if (isShowTimeOut) {
            if (remainingSeconds > 0) {
                timeOut = setInterval(() => {
                    setRemainingSeconds(prevSeconds => {
                        return prevSeconds - 1;
                    });
                }, 1000);
            } else {
                handleExpired();
            }
        }
        return () => clearInterval(timeOut);
    }, [remainingSeconds, isShowTimeOut]);

    const resetUserActivityTimeout = () => {
        clearTimeout(userActivityTimeout);
        userActivityTimeout = setTimeout(() => {
            inactiveUserAction();
        }, INACTIVE_USER_TIME_THRESHOLD);
    };

    //Solve performance issue for event like mouse
    const userActivityThrottler = () => {
        if (!userActivityThrottlerTimeout) {
            userActivityThrottlerTimeout = setTimeout(() => {
                resetUserActivityTimeout();
                clearTimeout(userActivityThrottlerTimeout);
                userActivityThrottlerTimeout = null;
            }, USER_ACTIVITY_THROTTLER_TIME);
        }
    };

    const inactiveUserAction = () => {
        dispatch(showTimeOutAction());
    };

    const handleHide = () => {
        dispatch(hideTimeOutAction());
    };

    const handleExpired = () => {
        if(window.location.pathname.indexOf('profile-activation') !== -1)  {
            dispatch(resetAllState());
            handleHide();
            setTimeout(() => $emit("movePageStart"), 250)
        } else {
            history.push('/reset-credentials');
        }
    };

    const percentage = Math.floor((remainingSeconds / Config.timeoutTimer) * 100);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_TIME_OUT}
            title="Are you still there?"
            type={MODAL_TYPE.TIME_OUT}
            isShow={isShowTimeOut}
            handleHide={handleHide}>
            <div>
                <p className="">
                    If you need help, please read our{' '}
                    <a href="https://www.tymebank.co.za/help-support" target="_blank" rel="noopener noreferrer">
                        <strong>FAQs</strong>
                    </a>{' '}
                    or call our Customer Service Center at <strong>0860 999 119</strong>. Please
                    continue if you need more time.
                </p>
                <div className="clock-container">
                    <CountDownCircle percentage={percentage} remainingSeconds={remainingSeconds} />
                </div>
                <p> </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.TIMEOUT_EXIT}
                        name={BUTTON_NAME.EXIT}
                        className="btn tyme-btn btn-secondary" 
                        onClick={handleExpired}
                    >
                        Exit
                    </TymeButton>
                    <TymeButton
                        id={BUTTON_ID.TIMEOUT_CONTINUE}
                        name={BUTTON_NAME.CONTINUE}
                        onClick={handleHide}
                    >
                        Continue
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

export default withRouter(TimeOut);
