import React from 'react';
import PropTypes from 'prop-types';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';

export const CreditCardFailedAttemptModal = ({ isShow = false, onHide, onSubmit }) => {
    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_CARD_MANY_ATTEMPT}
            title="You’ve been temporarily blocked for 24 hours"
            type={MODAL_TYPE.ERROR}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p>
                    Because you entered the last 4 digits of your card or loan reference number incorrectly too many times, we’ve temporarily blocked your from setting up your login details for 24 hours.
                </p>
                <p>
                    To remove the block before the 24 hours are up, contact Customer Service on  {' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.CARD_EXIT_ATTEMPT}
                        name={BUTTON_NAME.EXIT}
                        onClick={onSubmit}
                    >
                        Exit
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

CreditCardFailedAttemptModal.propTypes = {
    isShow: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CreditCardFailedAttemptModal;
