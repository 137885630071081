import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import Modal from '../modal';
import SaIdPinModals from '../enter-id-pin/modals';
import Button from '../form-elements/button';
import { modalHeader, modalBody, modalFooter } from '../modal/generic-error';
import successIcon from '../../assets/images/successful.png';
import errorIcon from '../../assets/images/error.png';
import { redirect, errorHandling } from '../../utils/utils';
import { Config } from '../../config';

export class Modals extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: '',
            errorCode: '',
        };

        this.pathname = '';
    }

    componentDidMount() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        this.pathname = this.context.router.route.location.pathname;
    }

    componentWillReceiveProps({ screen, errorCode }) {
        if (!this.props.anyTouched) {
            this.setState({
                screen,
                errorCode,
            });
        }
    }

    componentDidUpdate() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        if (this.state.errorCode) {
            errorHandling(this.state.errorCode);
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    id = () => {
        if (this.state.screen === 'success') {
            return 'successModal';
        } else if (this.state.screen === 'resetSuccess') {
            return 'resetSuccessModal';
        } else if (this.state.screen === 'resetError') {
            return 'resetErrorModal';
        }

        return 'errorModal';
    };

    heading = (passwordTranslation, resetPasswordTranslation) => {
        if (this.state.screen === 'success') {
            return passwordTranslation.success.heading;
        } else if (this.state.screen === 'resetSuccess') {
            return resetPasswordTranslation.success.heading;
        } else if (this.state.screen === 'resetError') {
            return resetPasswordTranslation.error.heading;
        }

        return modalHeader();
    };

    body = (t, passwordTranslation, resetPasswordTranslation) => {
        if (this.state.screen === 'success') {
            return <p>{passwordTranslation.success.body}</p>;
        } else if (this.state.screen === 'resetSuccess') {
            return <p>{resetPasswordTranslation.success.body}</p>;
        } else if (this.state.screen === 'resetError') {
            return <p>{resetPasswordTranslation.error.body}</p>;
        }

        return modalBody(this.props.error);
    };

    footer = buttonTranslation => {
        if (this.state.screen === 'success' || this.state.screen === 'resetSuccess') {
            return (
                <div>
                    <Link
                        id={`password-${this.state.screen}-login`}
                        className="btn btn-solid"
                        to={Config.loginUrl}
                        onClick={e => {
                            e.preventDefault();
                            window.location = Config.loginUrl;
                        }}>
                        {buttonTranslation.login}
                    </Link>
                </div>
            );
        } else if (this.state.screen === 'resetError') {
            return (
                <div>
                    <Button
                        id={`password-${this.state.screen}-ok`}
                        type="button"
                        className="btn btn-solid"
                        name="ok"
                        onClick={() => {
                            sessionStorage.clear();
                            redirect(this.context.router, '/reset-credentials');
                            this.reset();
                        }}
                        data-dismiss="modal"
                        value={buttonTranslation.ok}
                    />
                </div>
            );
        }

        return modalFooter(() => {
            sessionStorage.clear();
            this.pathname.indexOf('reset-credentials') !== -1
                ? redirect(this.context.router, '/reset-credentials')
                : redirect(this.context.router, '/');
        });
    };

    reset = () => {
        this.setState({
            screen: '',
            errorCode: '',
        });
    };

    render() {
        const { t } = this.props;
        const passwordTranslation = t(translationKeys.password, { returnObjects: true }).modals;
        const resetPasswordTranslation = t(translationKeys.resetPassword, { returnObjects: true })
            .modals;
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });

        if (!this.state.screen) {
            return null;
        }

        if (this.state.screen === 'registered') {
            return (
                <SaIdPinModals
                    screen={this.state.screen}
                    errorCode={this.state.errorCode}
                    propsReceived={() => {}}
                />
            );
        }

        return (
            <Modal
                id={this.id()}
                header={{
                    icon:
                        this.state.screen === 'success' || this.state.screen === 'resetSuccess'
                            ? successIcon
                            : errorIcon,
                    heading: this.heading(passwordTranslation, resetPasswordTranslation),
                }}
                body={this.body(t, passwordTranslation, resetPasswordTranslation)}
                footer={this.footer(buttonTranslation)}
            />
        );
    }
}

export default translate()(Modals);
