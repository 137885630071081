import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header';
import Footer from '../components/footer';

export class Index extends Component {
    static propTypes = {
        header: PropTypes.object,
        footer: PropTypes.object,
        style: PropTypes.object,
    };

    static defaultProps = {
        header: null,
        footer: null,
        style: null,
    };

    render() {
        return (
            <div className="layout">
                <div className="top">
                    {this.props.header && <Header {...this.props} />}
                    <div className="content">
                        <div className="container">
                            <div className="row">{this.props.children}</div>
                        </div>
                    </div>
                    {this.props.footer && <div className="push" />}
                </div>
                {this.props.footer && (
                    <div className="bottom">
                        <Footer {...this.props} />
                    </div>
                )}
            </div>
        );
    }
}

export default Index;
