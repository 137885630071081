import { useState } from 'react'
import { captureNaturesPurpose, fetchNaturesPurposes } from '../../api';

// import json from './nature-purpose.json';

export const useFetchNaturePurpose = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        setData([]);
        setError(false);
        try {
            const resuts = await fetchNaturesPurposes();
            setData(resuts.data);
        } catch (e) {
            // setData(json);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return {
        fetchData,
        loading,
        data,
        error,
    }
};

export const useCaptureNaturePose = ({ sessionId, natures, purposes }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState(false);

    const postData = async () => {
        setLoading(true);
        setData({});
        setError(false);
        try {
            await captureNaturesPurpose({ sessionId, natures, purposes });
            setData({ status: 200 });
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return {
        postData,
        loading,
        data,
        error,
    }
};
