import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowCommonException } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const CommonExceptionModal = ({ onHide, onSubmit }) => {
    const isShowCommonException = useSelector(getIsShowCommonException);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_EXCEPTION_ERROR}
            title="We seem to have a problem"
            type={MODAL_TYPE.ERROR}
            isShow={isShowCommonException}
            handleHide={onHide}>
            <div>
                <p>Unfortunately, you have encountered an error. Please try again later.</p>
                <p>
                    If the problem continues, contact us on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.COMMON_ERROR_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}>
                        OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

CommonExceptionModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CommonExceptionModal;
