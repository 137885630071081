import { VERIFY_RECAPTCHA_SUCCESS, VERIFY_RECAPTCHA_ERROR } from '../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case VERIFY_RECAPTCHA_SUCCESS:
            return { ...state, data: action.payload, error: undefined };
        case VERIFY_RECAPTCHA_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
}
