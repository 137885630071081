import NodeRSA from 'node-rsa';
import uuidv1 from 'uuid/v1';
import { CREDIT_CARD_RESET_STEPS, RESET_STEPS, TOGGLE_FEATURE } from '../constants';
import { Config } from '../config';
import {
    haveLowerCharacter,
    haveMinLength,
    haveNumber,
    haveSpecialCharacter,
    haveUppercaseCharacter,
} from '../components/password-check/PasswordCheck';

// eslint-disable-next-line import/no-cycle
import { isPersonalLoan } from './useHooks';

export const formatNumberOnly = input => input.replace(/[^\d]/g, '');
export const trimBlank = inputSaid => {
    return inputSaid.replace(/[\s]/g, '');
};
export const formatInputSaid = inputSaid => {
    const numberOnly = formatNumberOnly(inputSaid);
    const trimmed = trimBlank(numberOnly);
    const trimmedLength = trimmed.length;
    if (trimmedLength > 6 && trimmedLength <= 10) {
        return trimmed.replace(/^(\d{6})(\d{1,4})$/g, '$1 $2');
    }
    if (trimmedLength > 10 && trimmedLength < 13) {
        return trimmed.replace(/^(\d{6})(\d{4})(\d{1,2})$/g, '$1 $2 $3');
    }
    if (trimmedLength >= 13) {
        const validText = trimmed.substr(0, 13);
        return validText.replace(/^(\d{6})(\d{4})(\d{2})(\d{1})$/g, '$1 $2 $3 $4');
    }
    return trimmed;
};

export const generateArrayValues = length => {
    return Array.from({ length }, (value, index) => ({
        id: index,
        value: '',
    }));
};
export const checkConsecutive = array => {
    if (array.length > 1) {
        let countInc = 0;
        for (let i = 1; i < array.length; i += 1) {
            if (array[i] === array[i - 1] + 1) {
                countInc += 1;
            }
        }
        return countInc === array.length - 1;
    }
    return true;
};
export const checkRepeat = array => {
    if (array.length > 1) {
        let countSame = 0;
        for (let i = 1; i < array.length; i += 1) {
            if (array[i] === array[i - 1]) {
                countSame += 1;
            }
        }
        return countSame === array.length - 1;
    }
    return true;
};
export const makeStringFromNumbers = numbers => {
    const otpCodes = numbers.map(({ value }) => value);
    return otpCodes.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, '');
};
export const haveEmptyAnswers = questions => {
    let haveEmpty = false;
    questions.forEach(({ answers }) => {
        if (answers) {
            answers.forEach(({ answer }) => {
                if (answer === '') {
                    haveEmpty = true;
                }
            });
        }
    });
    return haveEmpty;
};
export const getResetPinShowSteps = () => Object.values(RESET_STEPS).filter(step => step.isShow);
export const getResetPinCreditCardSteps = () => {
    const step = Object.values(CREDIT_CARD_RESET_STEPS).filter(step => step.isShow);
    return (isPersonalLoan() && TOGGLE_FEATURE.NATURE_PURPOSE) ? step : step.slice(1);
};

export const redirect = (router, path) => {
    router.history.replace(path);
};
export const showModal = (modalId, show = true) => {
    if (show) {
        window.$(`#${modalId}`).modal('show');
    } else {
        window.$(`#${modalId}`).modal('hide');
    }
};

function generateLuhnDigit(inputString) {
    let total = 0;
    let count = 0;

    for (let i = 0; i < inputString.length; i += 1) {
        const multiple = (count % 2) + 1;
        count += 1;
        let temp = multiple * +inputString[i];
        temp = Math.floor(temp / 10) + (temp % 10);
        total += temp;
    }

    total = (total * 9) % 10;

    return total;
}

export const extractFromID = idNumber => {
    const checkIDNumber = idNum => {
        const number = idNum.substring(0, idNum.length - 1);
        return generateLuhnDigit(number) === +idNum[idNum.length - 1];
    };

    const getBirthdate = idNum => {
        const year = idNum.substring(0, 2);
        const currentYear = new Date().getFullYear() % 100;

        let prefix = '19';
        if (+year < currentYear) {
            prefix = '20';
        }

        const month = idNum.substring(2, 4);
        const day = idNum.substring(4, 6);
        return new Date(`${prefix}${year}/${month}/${day}`);
    };

    const getGender = idNum => (+idNum.substring(6, 7) < 5 ? 'female' : 'male');

    const getCitizenship = idNum => (+idNum.substring(10, 11) === 0 ? 'citizen' : 'resident');

    const result = {};
    result.valid = checkIDNumber(idNumber);
    result.birthdate = getBirthdate(idNumber);
    result.gender = getGender(idNumber);
    result.citizen = getCitizenship(idNumber);

    return result;
};
export const validateInputSaid = formattedInputSaid => {
    const trimmedSaid = trimBlank(formattedInputSaid);
    const verify = extractFromID(trimmedSaid);
    return verify.valid;
};
export const validateConsecutiveNumber = values => {
    const consecutiveNumbers = [];

    for (let i = 0; i < values.length; i += 1) {
        consecutiveNumbers.push(values[0] + i);
    }

    if (JSON.stringify(values) === JSON.stringify(consecutiveNumbers)) {
        return true;
    }

    return false;
};
export const validateSameNumber = values => {
    const sameNumbers = [];

    for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
            values[0] === values[i] ? sameNumbers.push(true) : sameNumbers.push(false);
        }
    }

    if (window.$.inArray(false, sameNumbers) === -1) {
        return true;
    }

    return false;
};
export const validatePinMatch = (pin, confirmPin) => {
    if (JSON.stringify(pin) === JSON.stringify(confirmPin)) {
        return true;
    }

    return false;
};
export const pinNoError = (errors, prefix = '') => {
    Object.keys(errors)
        .filter(k => k.indexOf(prefix) === 0)
        .map(k => delete errors[k]);
};
export const pinHasError = (formProps, errors, prefix = '') => {
    Object.keys(formProps)
        .filter(k => k.indexOf(prefix) === 0)
        .map(k => {
            errors[k] = ' ';

            return null;
        });
};
export const dataResponse = response => {
    if (typeof response === 'string') {
        return JSON.parse(response);
    }

    return response;
};
export const convertToByteArray = base64Encrypted => {
    const binary = window.atob(base64Encrypted);
    const bytes = new Int8Array(binary.length);

    for (let i = 0; i < binary.length; i += 1) {
        bytes[i] = binary.charCodeAt(i);
    }

    const byteArray = [];

    for (let i = 0; i < bytes.length; i += 1) {
        byteArray[i] = bytes[i];
    }

    return byteArray;
};
export const encryption = (value, time, type = '') => {
    const nonce = Math.floor(Math.random() * 1000000000);
    const payload1 = value.toString() + Config.mobileSalt;
    const key1 = new NodeRSA(Config.rsaPinPublicKey, 'public', {
        encryptionScheme: {
            scheme: 'pkcs1',
            padding: 3,
        },
    });
    const blob1 = key1.encrypt(payload1, 'base64');

    const key2 = new NodeRSA(Config.transportRsaPublicKey, 'public', { encryptionScheme: 'pkcs1' });
    const payload2 = `${nonce.toString()}:${time}:${blob1}`;
    const blob2 = key2.encrypt(payload2, 'base64');

    if (type === 'rp') {
        return blob2;
    }

    return convertToByteArray(blob2);
};
export const encryptionAuth = args => {
    const payload = `${args.said}:${args.pin}`;
    const key = new NodeRSA(Config.payloadPublicKey, 'public', { encryptionScheme: 'pkcs1' });

    return key.encrypt(payload, 'base64');
};

export const toCamelCase = str => {
    const lowerCase = str.toLowerCase();

    return lowerCase.replace(/\b[a-z]/g, f => f.toUpperCase());
};
export const getUuid = () => {
    return uuidv1();
};

export function errorHandling(errorCode, show = true, callback = () => {}) {
    switch (errorCode) {
        case '0406006': // Activation: Provided credential is not correct
            callback('invalid');
            if (show) {
                showModal('invalidModal');
            }
            break;
        case '0414001': // SAID: Invalid ID format
            callback('invalidSaId');
            if (show) {
                showModal('invalidSaId');
            }
            break;
        case '0406048': // Activation: User is already registered
            callback('registered');
            if (show) {
                showModal('registeredModal');
            }
            break;
        case '0414019': // Search SAID: Profile was not found
            callback('profileNotFound');
            if (show) {
                showModal('profileNotFound');
            }
            break;
        case '0407001': // Generate OTP: Can not generate OTP
            callback('failOtp');
            if (show) {
                showModal('failSendOtp');
            }
            break;
        case '0407003': // Verify OTP: OTP is expired
            callback('expired');
            if (show) {
                showModal('expiredOtp');
            }
            break;
        case '0407005': // Verify OTP: Max OTP attempts reached
            callback('verifyFail');
            if (show) {
                showModal('verifyOtpFail');
            }
            break;
        case 'CBSA_AUTH_E0007': // authorize: User authentication failed - <USERNAME> - remaining attempts: <REMAINING_ATTEMPTS>
            callback('invalid');
            if (show) {
                showModal('invalidModal');
            }
            break;
        case 'CBSA_AUTH_E0016': // authorize: User not found for claim <CLAIM URL> <CLAIM VALUE>
            callback('invalid');
            if (show) {
                showModal('invalidModal');
            }
            break;
        case 'CBSA_AUTH_E0022': // authorize: User is already registered to the internet banking service
            callback('registered');
            if (show) {
                showModal('registeredModal');
            }
            break;
        case 'resetPassword':
            callback('resetError');
            if (show) {
                showModal('resetErrorModal');
            }
            break;
        case 'resetPin':
            callback('resetError');
            if (show) {
                showModal('resetErrorModal');
            }
            break;
        default:
            callback('error');
            if (show) {
                showModal('errorModal');
            }
            break;
    }
}

export const validatePassword = password => {
    let result = '';
    if (password === '') {
        result = 'Please enter your password';
    } else if (!haveLowerCharacter(password)) {
        result = 'At least one lowercase letter';
    } else if (!haveUppercaseCharacter(password)) {
        result = 'At least one uppercase letter';
    } else if (!haveNumber(password)) {
        result = 'At least one number';
    } else if (!haveSpecialCharacter(password)) {
        result = 'At least one special character (e.g.#*%)';
    } else if (!haveMinLength(password)) {
        result = 'At least 8 characters';
    }
    return result;
};

export const validateCCPassword = password => {
    let result = '';
    if(password === '' ||
        !haveLowerCharacter(password) ||
        !haveUppercaseCharacter(password) ||
        !haveNumber(password) ||
        !haveSpecialCharacter(password) ||
        !haveMinLength(password)
    ) {
        result = 'Your password must contain all requirements';
    }
    return result;
};