import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE} from '../../constants';
import { getIsShowBlockProfile} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const BlockProfileModal = ({ onHide, onSubmit }) => {
    const isShowBlockProfile = useSelector(getIsShowBlockProfile);

    return (
        <ModalTemplate
            title="Access Blocked"
            type={MODAL_TYPE.BLOCK}
            isShow={isShowBlockProfile}
            handleHide={onHide}>
            <div>
                <p>
                    Due to security reasons, we've blocked your Internet Banking, Smart App, Kiosk,
                    and USSD access.
                </p>
                <p>
                    Please call our Customer Service Centre on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span> to unlock your
                    account.
                </p>
                <div className="btn-control-container">
                    <TymeButton onClick={onSubmit}>OK</TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

BlockProfileModal.propTypes = {
    isShowBlockProfile: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default BlockProfileModal;
