import { createAction } from 'redux-actions';

export const VERIFY_CARD_PAN = 'VERIFY_CARD_PAN';
export const VERIFY_CARD_PAN_SUCCESS_RESET_PIN = 'VERIFY_CARD_PAN_SUCCESS_RESET_PIN';
export const VERIFY_CARD_PAN_FAILED_RESET_PIN = 'VERIFY_CARD_PAN_FAILED_RESET_PIN';
export const VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD = 'VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD';
export const VERIFY_CARD_PAN_FAILED_RESET_PASSWORD = 'VERIFY_CARD_PAN_FAILED_RESET_PASSWORD';
export const SET_CARD_ATTEMPT_ERROR = 'SET_CARD_ATTEMPT_ERROR';

export const verifyCardPanAction = createAction(VERIFY_CARD_PAN);
export const verifyCardPanSuccessResetPinAction = createAction(VERIFY_CARD_PAN_SUCCESS_RESET_PIN);
export const verifyCardPanFailedResetPinAction = createAction(VERIFY_CARD_PAN_FAILED_RESET_PIN);
export const verifyCardPanSuccessResetPasswordAction = createAction(VERIFY_CARD_PAN_SUCCESS_RESET_PASSWORD);
export const verifyCardPanFailedResetPasswordAction = createAction(VERIFY_CARD_PAN_FAILED_RESET_PASSWORD);
export const setCardAttemptErrorAction = createAction(SET_CARD_ATTEMPT_ERROR);
