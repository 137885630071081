import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import _ from "lodash";
import RequireAuth from './components/require-auth';
import googleTagManager from './components/google-tag-manager';
import EnterIdPin from './containers/enter-id-pin';
import Password from './containers/password';
import Reset from './containers/reset-credentials';
import ResetPin from "./containers/reset-pin/ResetPin";
import ResetPassword from './containers/reset-password/ResetPassword';
import withErrorBoundary from './components/layout/ErrorBoundary';
import CreditCardSelection from './containers/credit-card/CreditCardSelection';
import CreditCardActivation from './containers/credit-card/CreditCardActivation';
import { Config } from './config';
import newRequireAuth from './utils/newRequireAuth';

import CCStepSAID from "./containers/credit-card/CCStartSAID"

import { useEventBus, isPersonalLoan } from "./utils/useHooks"

const isTurnOnResetPin = Config.resetPinToggle === 'on';

const Routes = (props) => {
    // const searchCCSaid = useSelector(state => state.searchCCSaid);
    const isPLLoan = isPersonalLoan(props)

    React.useEffect(() => {
        TagManager.initialize(Config.googleTagManager);
        const version = `iba-${Config.environment}-${Config.appVersion}`;
        document.getElementsByTagName('body')[0].setAttribute('data-version', version);

        const path = _.get(props.location, "pathname", "")
        if (path.startsWith("/credit-card") || path === "/profile-activation" || path === "/profile-activation/") {
            props.history.push(Config.appRoutes.CC_START);
        }
    }, [])

    useEventBus("movePageOtp", () => {
        props.history.push(isPLLoan ? Config.appRoutes.PERSONAL_LOAN : Config.appRoutes.CREDIT_CARD)
    });

    useEventBus("movePageStart", () => {
        props.history.push(isPLLoan ? Config.appRoutes.PL_START : Config.appRoutes.CC_START);
    });

    return (
        <div className="wrapper">
            <Switch>
                <Route exact path={Config.appRoutes.HOME} component={googleTagManager(EnterIdPin)} />
                <Route
                    exact
                    path={Config.appRoutes.ACTIVATE}
                    component={RequireAuth(googleTagManager(Password))}
                />
                <Route
                    exact
                    path={Config.appRoutes.RESET_CREDENTIAL}
                    component={googleTagManager(Reset)}
                />
                {isTurnOnResetPin ? (
                    <Route
                        exact
                        path={Config.appRoutes.RESET_PIN}
                        component={RequireAuth(googleTagManager(ResetPin))}
                    />
                ) : null}
                <Route
                    exact
                    path={Config.appRoutes.RESET_PASSWORD}
                    component={RequireAuth(googleTagManager(ResetPassword))}
                />
                <Route
                    exact
                    path={Config.appRoutes.CREDIT_CARD}
                    component={googleTagManager(CreditCardSelection)}
                />

                <Route
                    exact
                    path={Config.appRoutes.PL_START}
                    component={googleTagManager(CCStepSAID)}
                />

                <Route
                    exact
                    path={Config.appRoutes.CC_START}
                    component={googleTagManager(CCStepSAID)}
                />

                <Route
                    exact
                    path={Config.appRoutes.PERSONAL_LOAN}
                    component={googleTagManager(CreditCardSelection)}
                />

                <Route
                    exact
                    path={Config.appRoutes.PERSONAL_LOAN_CODE}
                    component={googleTagManager(CreditCardActivation)}
                />

                <Route
                    exact
                    path={Config.appRoutes.CREDIT_CARD_SET_PIN_PASSWORD}
                    component={newRequireAuth(googleTagManager(CreditCardActivation))}
                />
                <Redirect to={Config.appRoutes.HOME} />
            </Switch>
        </div>
    );
}


export default withErrorBoundary(withRouter(Routes));
