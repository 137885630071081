import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TymeBank } from '../../assets/images/icon-tyme-app.svg';
import { ReactComponent as AddMoney } from '../../assets/images/icon-add-money.svg';
import { ReactComponent as CreditCard } from '../../assets/images/icon-credit-card.svg';
import AppStore from '../../assets/images/app-store.svg';
import GooglePlayStore from '../../assets/images/googleplay-store.svg';
import HuaweiStore from '../../assets/images/huawei-store.svg';
import { Config } from '../../config';

import TymeButton from './TymeButton';

export const DownloadApp = ({ onClick }) => {
    const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    const infoApp = [
        {
            img: <TymeBank className="icon-tymebank" />,
            title: "Get the TymeBank app",
            description: "Download the TymeBank app from your app store for easy, on-the-go banking.",
        },
        {
            img: <AddMoney className="icon-money" />,
            title: "Add money and get your card",
            description: "Go to your nearest Pick n Pay or Boxer store to get a debit card, plus you can add money at till-points or make an EFT into your account."
        },
        {
            img: <CreditCard className="icon-card" />,
            title: "Get your debit card",
            description: "Go to your nearest Pick n Pay or Boxer store to print your TymeBank debit card directly from a TymeBank kiosk, in just a few minutes.",
        },
    ];

    const isMobile = () => {
        return (
            width < 768 ||
            /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mobi|Opera Mini/i.test(
                navigator.userAgent,
            )
        );
    };

    return (
        <div className="app-download-container">
            <div className="row row-no-gutters">
                {
                    infoApp.map((item, index) => (
                        <div className="col-xs-12 col-sm-4 col-md-4 app-download" key={+index}>
                            <div className="app-download__image">{item.img}</div>
                            <div className="app-download__title">{item.title}</div>
                            <div className="app-download__description">{item.description}</div>
                        </div>
                    ))
                }
            </div>
            <div className="row row-no-gutters">
                <div className="col-xs-12">
                    {isMobile() ? (
                        <div className="btn-control-container">
                            <TymeButton
                                className="btn tyme-btn btn-primary app-download-button"
                                onClick={onClick}>
                                Download the app now
                            </TymeButton>
                        </div>) : (
                        <div className="download-app-desktop">
                            <div className="avaible-app">Available on</div>
                            <div className="btn-download-app-desktop">
                                <a href={Config.googlePlayUrl} target="_blank" rel="noreferrer"><img src={GooglePlayStore} alt="Google Play" title="Google Play" /></a>
                                <a href={Config.appleStoreUrl} target="_blank" rel="noreferrer"><img src={AppStore} alt="Apple Store" title="Apple Store" /></a>
                                <a href={Config.huaweiUrl} target="_blank" rel="noreferrer"><img src={HuaweiStore} alt="Huawei Store" title="Huawei Store" /></a>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
    );
};

DownloadApp.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default DownloadApp;