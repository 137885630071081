import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPE, CUSTOMER_SERVICE_CENTRE } from '../../constants';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../../components/common/TymeButton';

export const CanNotSendOTPModal = ({ isShow = false, onHide, onSubmit }) => {
    return (
        <ModalTemplate
            title="OTP not sent"
            type={MODAL_TYPE.ERROR}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p>Our system could not send an SMS to your cellphone number.</p>
                <p>
                    For assistance, please contact us on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton onClick={onSubmit}>OK</TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

CanNotSendOTPModal.propTypes = {
    isShow: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CanNotSendOTPModal;
