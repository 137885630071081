import header1 from './assets/images/header-1.png';
import header2 from './assets/images/header-2.png';
import header3 from './assets/images/header-3.png';
import header4 from './assets/images/header-4.png';
import header5 from './assets/images/header-5.png';
import header6 from './assets/images/header-6.png';
import header7 from './assets/images/header-7.png';
import header8 from './assets/images/header-8.png';
import header9 from './assets/images/header-9.png';
import header10 from './assets/images/header-10.png';
import header11 from './assets/images/header-11.png';
import header12 from './assets/images/header-12.png';

export const ERROR_CODES = {
    FIDES_CREDIT_MCQ_INSUFFICENT_DATA: '0609007',
    FIDES_CREDIT_MCQ_GENERIC_ERROR: '0609008',
    FIDES_CREDIT_MCQ_ID_NOT_FOUND: '0609009',
    FIDES_CREDIT_MCQ_ID_BLOCKED: '0609010',
    FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA: '0609011',
    FIDES_OTHER_UNKNOWN_ERROR: '0609012',
};

export const MCQ = {
    FIDES_ERROR_CODES: {
        CRITICAL: [
            ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_DATA,
            ERROR_CODES.FIDES_CREDIT_MCQ_ID_NOT_FOUND,
            ERROR_CODES.FIDES_CREDIT_MCQ_ID_BLOCKED,
            ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA,
            ERROR_CODES.FIDES_OTHER_UNKNOWN_ERROR,
        ],
        NON_CRITICAL: [ERROR_CODES.FIDES_CREDIT_MCQ_GENERIC_ERROR],
    },
};

export const RESET_CREDENTIAL_TYPE = {
    PASSWORD: 'password',
    PIN: 'pin',
};

export const SESSION_STORAGE_ITEMS = {
    RESET_CREDENTIAL_TYPE: 'resetCredentialType',
};

export const CUSTOMER_SERVICE_CENTRE = '0860 999 119';
export const CUSTOMER_SERVICE_CENTRE_EXTRA = '0861 007 250';

export const MODAL_TYPE = {
    INFO: 'INFO',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    BLOCK: 'BLOCK',
    UNDEFINED: 'UNDEFINED',
    CUSTOMER_ERROR: 'CUSTOMER_ERROR',
    TIME_OUT: 'TIME_OUT',
    CC_INFO: 'CC_INFO',
};

export const headerImgs = [
    header1,
    header2,
    header3,
    header4,
    header5,
    header6,
    header7,
    header8,
    header9,
    header10,
    header11,
    header12,
];
export const RESET_STEPS = {
    said: {
        stepId: 1,
        stepProgress: 1,
        title: 'Step 1 of 4',
        isShow: true,
    },
    otp: {
        stepId: 2,
        stepProgress: 2,
        title: 'Step 2 of 4',
        isShow: true,
    },
    card: {
        stepId: 3,
        stepProgress: 3,
        title: 'Step 3 of 4',
        isShow: true,
    },
    reset: {
        stepId: 4,
        stepProgress: 4,
        title: 'Step 4 of 4',
        isShow: true,
    },
    success: {
        stepId: 5,
        stepProgress: 5,
        title: 'Success',
        isShow: false,
    },
    mcq: {
        stepId: 6,
        stepProgress: 3,
        title: 'Step 3 of 4',
        isShow: false,
    },
};
export const INITIAL_OTP_INPUT_ATTEMPT = 1;
export const INITIAL_OTP_RESEND_ATTEMPT = 1;
export const SESSION_STORAGE_KEY = {
    ACCESS_TOKEN: 'accessToken'
};
export const RESET_ERROR_CODES = {
    CARD_PAN_FAILURE_FIRST: '0414102',
    CARD_PAN_FAILURE_SECOND: '0414103',
    CARD_PAN_FAILURE_DISABLED: '0414104',
    VERIFY_MCQ_FAILURE_DISABLED: '0414203',
    FIDES_CREDIT_MCQ_INSUFFICENT_DATA: '0609007',
    FIDES_CREDIT_MCQ_GENERIC_ERROR: '0609008',
    FIDES_CREDIT_MCQ_ID_NOT_FOUND: '0609009',
    FIDES_CREDIT_MCQ_ID_BLOCKED: '0609010',
    FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA: '0609011',
};
export const NOT_ACTIVATED_ERROR_CODE = '0414021';

export const CREDIT_CARD_RESET_STEPS = {
    said: {
        stepId: 1,
        stepProgress: 1,
        title: 'Step 1 of 6',
        isShow: true,
    },
    otp: {
        stepId: 2,
        stepProgress: 2,
        title: 'Step 2 of 6',
        isShow: true,
    },
    creditCard: {
        stepId: 3,
        stepProgress: 3,
        title: 'Step 3 of 6',
        isShow: true,
    },
    crs: {
        stepId: 4,
        stepProgress: 4,
        title: 'Step 4 of 6',
        isShow: true,
    },
    pin: {
        stepId: 5,
        stepProgress: 5,
        title: 'Step 5 of 6',
        isShow: true,
    },
    password: {
        stepId: 6,
        stepProgress: 6,
        title: 'Step 6 of 6',
        isShow: true,
    },
    success: {
        stepId: 7,
        stepProgress: 7,
        title: 'Success',
        isShow: false,
    },
    unSuccess: {
        stepId: 8,
        stepProgress: 8,
        title: 'unSuccess',
        isShow: false,
    },
    naturePurpose: {
        stepId: 14,
        isShow: true,
    },
};

export const CREDIT_CARD_ERROR_CODES = {
    CREDIT_CARD_SETUP_REQUEST: '0407010',
    CREDIT_CARD_ACCOOUNT_ACTIVATED: '0468002',
    CREDIT_CARD_FEATURE_BLOCKED: '0468003',
    CREDIT_CARD_PROFILE_BLOCKED: '0468005',
    CREDIT_CARD_PAN_NOT_ACTIVE: '0468004',
    CREDIT_CARD_PAN_NOT_ELIGIBLE: '0468018',
    CREDIT_CARD_PAN_FAILURE_FIRST_SECOND: '0468007',
    CREDIT_CARD_PAN_FAILURE_MANY_ATTEMPTS: '0468008',
    CREDIT_CARD_NOT_REMOVE_FICA: '0468023',
    CREDIT_CARD_PROFILE_STILL_HOLD: '0468019',
    INVALID_INPUT_FIELD: '0468001',
    INVALID_SESSIONID: '0456018',
};

export const CC_CREDENTIAL_TYPE = {
    PIN: 'PIN',
    PASSWORD: 'PASSWORD'
};

export const DEVICE = {
    IOS: 'IOS',
    ANDROID: 'ANDROID',
    HUAWEI: 'HUAWEI',
};

export const MAP_EVENT_CATEGORY = {
    '/credit-card': 'Credit Card Home',
    '/credit-card/setPinPassword': 'Credit Card Activation (/credit-card/setPinPassword)'
};

export const MODAL_ID = {
    MODAL_FEATURE_BLOCK: 'modalFeatureBlock',
    MODAL_PROFILE_BLOCK: 'modalProfileBlock',
    MODAL_EXCEPTION_ERROR: 'modalExceptionModal',
    MODAL_CONFIRM_BEFORE_EXIT: 'mocalConfirmBeforeExit',
    MODAL_CONFIRM_PAY_TAX: 'modalConfirmPayTax',
    MODAL_NOT_ELLIGIBLE: 'modalNotElligible',
    MODAL_NOT_ACTIVE_CARD: 'modalNotActiveCreditCard',
    MODAL_NOT_CREDIT_CARD_CUSTOMER: 'modalNotCreditCardCustomer',
    MODAL_NOT_REMOVE_FICA: 'modalNotRemoveFica',
    MODAL_TIME_OUT: 'modalTimeout',
    MODAL_PASSWORD_TECHNICAL_ERROR: 'modalPasswordTechnicalError',
    MODAL_RETRY_RESULT: 'modalRetryResult',
    MODAL_CARD_MANY_ATTEMPT: 'modalCardFailedManyAttempt',
    MODAL_PIN_TECHNICAL_ERROR: 'modalPinTechnicalError',
    MODAL_SKIP_MCQ_RESET_PIN: 'modalSkipMCQResetPin',
    MODAL_SKIP_MCQ_RESET_PASSWORD: 'modalSkipMCQResetPassword'
}

export const BUTTON_ID = {
    START_SESSION_CANCEL: 'cancel-start-session',
    LET_DO_IT: 'let-do-it',
    SAID_CANCEL: 'said-cancel',
    SAID_NEXT: 'said-next',
    OTP_CANCEL: 'otp-cancel',
    OTP_CONFIRM: 'otp-confirm',
    OTP_RESEND: 'otp-resend',
    CARD_CANCEL: 'card-cancel',
    CARD_NEXT: 'card-next',
    CRS_YES: 'crs-yes',
    CRS_NO: 'crs-no',
    CRS_YES_CONFIRM_MODAL: 'crs-yes-confirm-modal',
    CRS_NO_MODAL: 'crs-no-modal',
    PIN_CANCEL: 'pin-cancel',
    PIN_NEXT: 'pin-next',
    PASSWORD_CANCEL: 'password-cancel',
    PASSWORD_CONFIRM: 'password-confirm',
    DO_LATER: 'password-set-later',
    DOWNLOAD_APP_MOBILE: 'download-app-mobile',
    DOWNLOAD_APPLE_STORE: 'download-apple-store',
    DOWNLOAD_GOOGLE_PLAY: 'download-google-play',
    DOWNLOAD_HUAWEI: 'download-huawei',
    FEATURE_BLOCK_OK: 'feature-block-ok-on-modal',
    PROFILE_BLOCK_OK: 'profile-block-ok-on-modal',
    COMMON_ERROR_OK: 'common-error-ok-on-modal',
    CONFIRM_BEFORE_EXIT_YES: 'confirm-before-exit-yes-on-modal',
    CONFIRM_BEFORE_EXIT_NO: 'confirm-before-exit-no-on-modal',
    CRS_CONFIRM_YES: 'crs-confirm-yes-on-modal',
    CRS_CONFIRM_NO: 'crs-confirm-no-on-modal',
    NOT_ELLIGIBLE_OK: 'card-not-elligible-ok-on-modal',
    NOT_ACTIVE_CARD_GOT_IT: 'card-not-active-card-gotit-on-modal',
    NOT_CUSTOMER_CLOSE: 'said-not-customer-close-on-modal',
    NOT_CUSTOMER_BACK_LOGIN: 'said-not-customer-back-on-modal',
    NOT_FICA_BACK_LOGIN: 'said-not-fica-back-on-modal',
    TRY_AGAIN: "try-again",
    CARD_EXIT_ATTEMPT: 'card-error-attempt-on-modal',
    TIMEOUT_EXIT: 'timeout-exit-on-modal',
    TIMEOUT_CONTINUE: 'timeout-continue-on-modal',
    SKIP_MCQ_RESET_PIN_OK: 'skip-mcq-reset-pin-ok-on-modal',
    SKIP_MCQ_RESET_PASSWORD_OK: 'skip-mcq-reset-password-ok-on-modal',
};

export const BUTTON_NAME = {
    LET_DO_IT: 'Let’s do it',
    NEXT: 'Next',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    RESEND: 'Otp Resend',
    DOLATER: `I'll do this later`,
    DOWNLOAD_APP: 'Download the app now',
    YES: 'Yes',
    NO: 'No',
    OK: 'Ok',
    GOT_IT: 'Got it',
    BACK_LOGIN: 'Back to login page',
    YES_CONFIRM: 'Yes, I confirm',
    CLOSE: 'Close',
    TRY_AGAIN: "Try again",
    EXIT: 'Exit',
    CONTINUE: 'Continue'
};

export const RETRY_DELAY_TIME = 5000;
export const ATTEMPT = 0;
export const MAX_RETRY_ATTEMPT = 3;

export const TOGGLE_FEATURE = {
    NATURE_PURPOSE: true,
}
