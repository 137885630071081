import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import Recaptcha from 'react-google-invisible-recaptcha';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import * as actions from './actions';
import { Config } from '../../config';
import { verifyRecaptcha } from '../recaptcha/actions';
import { resetAllState, getAccessToken, getGoogleTime } from '../../actions';
import { generateOtp } from '../../components/otp/actions';
import Index from '../../layouts';
import Pin from '../../components/pin';
import Label from '../../components/form-elements/label';
import Textbox from '../../components/form-elements/textbox';
import Button from '../../components/form-elements/button';
import Modals from '../../components/enter-id-pin/modals';
import Loading from '../../components/loading';
import headerImage from '../../assets/images/header-1.png';
import {
    dataResponse,
    encryption,
    showModal,
    extractFromID,
    encryptionAuth,
    errorHandling,
} from '../../utils/utils';
import ConnectionTimeoutModal from '../../components/modal/connection-timeout';
import WelcomeNewUserModal from '../../components/modals/WelcomeNewUserModal';

let errors = {};

const isTurnOnResetPin = Config.resetPinToggle === 'on';

export class EnterIdPin extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: '',
            errorCode: '',
            error: '',
            idNumber: '',
            pin: '',
            cellNumber: '',
            otpTimer: 0,
            submit: false,
            time: null,
        };
    }

    componentDidMount() {
        document.title = Config.pageTitle.enterIdPin;
        document.body.className = 'enter-id-pin';

        this.prepopulateSaid()

        window.scrollTo(0, 0);
        sessionStorage.clear();
        errors = {};
    }

    prepopulateSaid = () => {
        const { idNumber, change } = this.props;
        this.setState({
            idNumber
        })
        change('saId', idNumber)
    }

    componentWillReceiveProps({
        tokenData,
        tokenError,
        timeData,
        timeError,
        saIdPin,
        saIdPinError,
        generateData,
        generateError,
        recaptcha,
    }) {
        if (tokenError === undefined && tokenData === undefined && !recaptcha) {
            this.setState({
                screen: '',
            });
        }

        if (tokenError) {
            if (tokenError.response) {
                const response = dataResponse(tokenError.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                if (errorCode) {
                    errorHandling(errorCode);
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        }

        if (saIdPinError) {
            if (saIdPinError.response) {
                const response = dataResponse(saIdPinError.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                const errorMessage = response.errors
                    ? response.errors[0].errorMessage
                    : response.errorMessage;

                if (errorCode) {
                    if (errorCode === '0400001' && errorMessage.indexOf('not found') !== -1) {
                        showModal('invalidModal');
                    } else {
                        errorHandling(errorCode);
                    }
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        }

        if (saIdPin) {
            if (generateError) {
                if (generateError.response) {
                    const generateResponse = dataResponse(generateError.response);

                    const otpErrorCode = generateResponse.errors
                        ? generateResponse.errors[0].errorCode
                        : generateResponse.errorCode;

                    if (otpErrorCode) {
                        errorHandling(otpErrorCode);
                    }
                } else {
                    errorHandling('');
                }
            } else if (generateData) {
                showModal('verifyModal');
            } else {
                errorHandling('');
            }
        }

        if (
            tokenData === null ||
            tokenError === null ||
            timeData === null || timeError === null ||
            saIdPin === null || saIdPinError === null
        ) {
            errorHandling('');
        }

        if (timeData) {
            if (!timeData.xhr.response) {
                errorHandling('');
            }
        }

        if (timeError) {
            if (timeError.response) {
                const response = dataResponse(timeError.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                if (errorCode) {
                    errorHandling(errorCode);
                }
            } else {
                errorHandling('');
            }
        }

        if (recaptcha) {
            if (recaptcha.data) {
                if (recaptcha.data.token && Object.keys(recaptcha.data.token).length > 0) {
                    sessionStorage.setItem('accessToken', recaptcha.data.token.access_token);
                    sessionStorage.setItem('idToken', recaptcha.data.token.id_token);
                    sessionStorage.setItem('cookies', JSON.stringify(recaptcha.data.token.cookies));
                } else if (recaptcha.data.errorMessage) {
                    const response = dataResponse(recaptcha.data);

                    const errorCode = response.errorMessage;

                    if (errorCode) {
                        errorHandling(errorCode);
                    } else {
                        errorHandling('');
                    }
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    onResolved = () => {
        const args = {
            said: this.state.idNumber,
            pin: this.state.pin,
        };

        const encrypted = encryptionAuth(args);

        this.props.verifyRecaptcha(
            {
                response: this.recaptcha.getResponse(),
                encrypted,
                type: 'iba',
            },
            data => {
                this.setSubmit(false);

                if (data) {
                    const response = dataResponse(data);

                    if (
                        response.success &&
                        response.token &&
                        Object.keys(response.token).length > 0
                    ) {
                        this.getAccessToken(args, 'time');
                    } else if (response.errorMessage) {
                        const errorCode = response.errorMessage;

                        if (errorCode) {
                            this.genericErrorHandling(errorCode, false);
                        } else {
                            this.genericErrorHandling('', false);
                        }
                    } else {
                        this.genericErrorHandling('', false);
                    }
                } else {
                    this.genericErrorHandling('', false);
                }
            },
        );
    };

    setSubmit = submit => {
        this.setState({ submit });
    };

    getAccessToken = (args, type = 'token') => {
        this.setSubmit(true);
        this.props.getAccessToken(
            args,
            data => {
                this.setSubmit(false);

                if (data) {
                    if (data.response) {
                        const response = dataResponse(data.response);

                        const errorCode = response.errors
                            ? response.errors[0].errorCode
                            : response.errorCode;

                        if (errorCode) {
                            this.genericErrorHandling(errorCode, false);
                        } else if (data.status === 200) {
                            if (type === 'time') {
                                sessionStorage.setItem('timeAccessToken', response.access_token);
                                this.getGoogleTime(args);
                            } else {
                                this.genericErrorHandling(response.error);
                            }
                        } else {
                            this.genericErrorHandling('', false);
                        }
                    } else if (data.status === 0) {
                        showModal('connectionTimeout');
                    } else {
                        this.genericErrorHandling('', false);
                    }
                } else {
                    this.genericErrorHandling('', false);
                }
            },
            type,
        );
    };

    getGoogleTime = args => {
        this.setSubmit(true);

        this.props.getGoogleTime(data => {
            this.setSubmit(false);

            if (data) {
                if (data.xhr) {
                    const response = dataResponse(data.xhr.response);

                    this.setState({ time: response });

                    this.callApiSaIdPin(args);
                } else if (data.response) {
                    const response = dataResponse(data.response);

                    const errorCode = response.errors
                        ? response.errors[0].errorCode
                        : response.errorCode;

                    if (errorCode) {
                        this.genericErrorHandling(errorCode, false);
                    }
                } else if (data.status === 0) {
                    showModal('connectionTimeout');
                } else {
                    this.genericErrorHandling('', false);
                }
            } else {
                this.genericErrorHandling('', false);
            }
        }, 'time');
    };

    formSubmit = args => {
        const luhn = extractFromID(args.saId.replace(/[\s]/g, ''));

        this.recaptcha.reset();

        if (luhn.birthdate.toString() !== 'Invalid Date' && luhn.valid) {
            this.setSubmit(true);
            this.recaptcha.execute();
        }
    };

    callApiSaIdPin = args => {
        this.setSubmit(true);

        const time = this.state.time ? this.state.time : new Date().getTime().toString();

        const encryptedPin = encryption(args.pin, time);

        this.props.submitSaIdPin(
            {
                said: args.said,
                pin: encryptedPin,
            },
            data => {
                this.setSubmit(false);

                if (data) {
                    if (data.response) {
                        const response = dataResponse(data.response);

                        const errorCode = response.errors
                            ? response.errors[0].errorCode
                            : response.errorCode;

                        const errorMessage = response.errors
                            ? response.errors[0].errorMessage
                            : response.errorMessage;

                        if (errorCode) {
                            if (
                                errorCode === '0400001' &&
                                errorMessage.indexOf('not found') !== -1
                            ) {
                                this.setState({
                                    screen: 'invalid',
                                    errorCode,
                                });
                            } else {
                                this.genericErrorHandling(errorCode, false);
                            }
                        } else if (data.status === 200) {
                            sessionStorage.setItem('requestToken', data.response.requestToken);
                            this.requestOtp();
                        } else {
                            this.genericErrorHandling('', false);
                        }
                    } else if (data.status === 0) {
                        showModal('connectionTimeout');
                    } else {
                        this.genericErrorHandling('', false);
                    }
                } else {
                    this.genericErrorHandling('', false);
                }
            },
        );
    };

    handlePinChange = values => {
        this.setState({
            pin: values,
        });
    };

    reset = () => {
        errors = {};

        this.setState({
            screen: '',
            errorCode: '',
            error: '',
            cellNumber: '',
            otpTimer: 0,
            submit: false,
            time: null,
        });
    };

    requestOtp = () => {
        this.setSubmit(true);

        this.props.generateOtp(
            {
                requestToken: sessionStorage.getItem('requestToken'),
            },
            otpData => {
                this.setSubmit(false);

                if (otpData) {
                    if (otpData.response) {
                        const otpResponse = dataResponse(otpData.response);

                        const otpErrorCode = otpResponse.errors
                            ? otpResponse.errors[0].errorCode
                            : otpResponse.errorCode;

                        if (otpErrorCode) {
                            this.genericErrorHandling(otpErrorCode, false);
                        } else {
                            this.setState({
                                screen: 'otp',
                                cellNumber: otpResponse.deliverTo,
                                otpTimer: Config.otp.timer,
                            });
                        }
                    } else if (otpData.status === 0) {
                        showModal('connectionTimeout');
                    } else {
                        this.genericErrorHandling('', false);
                    }
                } else {
                    this.genericErrorHandling('', false);
                }
            },
        );
    };

    genericErrorHandling = (errorCode = '', show = true) => {
        errorHandling(errorCode, show, screen => {
            this.setState({
                screen,
                errorCode,
            });
        });
    };

    closeOtp = () => {
        this.setState({
            screen: '',
            errorCode: '',
        });
    };

    assignErrorObj = error => {
        if (error) {
            if (error.response) {
                const response = dataResponse(error.response);

                if (response.access_token) {
                    return null;
                }

                return response;
            }

            return {
                'Status code': error.status,
                'Status text': error.statusText,
            };
        }

        return null;
    };

    render() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const isNewAccount = queryParams.get('newAccount');

        // render the 'Welcome New User' modal if the user's account has just been created.
        if (isNewAccount) {
            return <WelcomeNewUserModal />
        }

        const { t, handleSubmit } = this.props;
        const copy = t(translationKeys.enterIdPin, { returnObjects: true });
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });

        let errorObj = null;

        if (this.props.generateError || this.props.generateError === null) {
            errorObj = this.assignErrorObj(this.props.generateError);
        } else if (this.props.generateData === null) {
            errorObj = this.assignErrorObj(this.props.generateData);
        } else if (this.props.verifyError || this.props.verifyError === null) {
            errorObj = this.assignErrorObj(this.props.verifyError);
        } else if (this.props.verifyData === null) {
            errorObj = this.assignErrorObj(this.props.verifyData);
        } else if (this.props.saIdPinError) {
            errorObj = this.assignErrorObj(this.props.saIdPinError);
        } else if (this.props.tokenData) {
            errorObj = this.assignErrorObj(this.props.tokenData);
        } else if (this.props.tokenError) {
            errorObj = this.assignErrorObj(this.props.tokenError);
        }

        return (
            <Index
                header={{
                    image: headerImage,
                    heading: copy.heading,
                    description: copy.prompt,
                }}
                footer={{
                    content: true,
                }}>
                <div className="col-md-12">
                    <form onSubmit={handleSubmit(this.formSubmit)}>
                        <p>{copy.body}</p>

                        <Label htmlFor="saId" labelText={copy.idLabel} />
                        <Textbox
                            name="saId"
                            className="form-control"
                            placeholder={copy.placeholder}
                            maxlength="16"
                            onChange={e => {
                                this.setState({
                                    idNumber: e.target.value.replace(/[\s]/g, ''),
                                    error: '',
                                });
                            }}
                            error={this.state.error}
                            autoComplete="off"
                            autoFocus="autofocus"
                            numberType="true"
                            fieldFormat="###### #### ## #"
                            type="tel"
                        />

                        <Pin
                            ref={pin => {
                                this.pin = pin;
                            }}
                            id="create-pin"
                            prefix="create_"
                            onChange={this.handlePinChange}
                            label={copy.pinLabel}
                        />

                        <div style={{ marginTop: isTurnOnResetPin ? '3rem' : '2rem' }}>
                            {isTurnOnResetPin ? (
                                <a href="/reset-credentials" style={{ fontWeight: 500 }}>
                                    Forgot your login PIN?
                                </a>
                            ) : null}
                        </div>

                        <Button
                            id="enter-id-pin-next"
                            className="btn btn-solid"
                            type="submit"
                            value={buttonTranslation.next}
                            name="next"
                            disabled={
                                !this.state.idNumber ||
                                !this.state.pin ||
                                !(
                                    this.state.idNumber.length === 13 && this.state.pin.length === 4
                                ) ||
                                this.state.submit
                                    ? 'disabled'
                                    : ''
                            }
                        />

                        {!isTurnOnResetPin ? (
                            <div style={{ marginTop: '2.5rem' }}>
                                <p style={{ fontStyle: 'italic', fontSize: '14px', fontWeight: 400 }}>
                                    *Did you forget your login PIN? You can reset it on the TymeBank
                                    App or your nearest Kiosk.
                                </p>
                            </div>
                        ) : null}

                        <Recaptcha
                            ref={recaptcha => {
                                this.recaptcha = recaptcha;
                            }}
                            sitekey={Config.googleRecaptcha.sitekey}
                            onResolved={this.onResolved}
                        />
                    </form>
                </div>
                <ConnectionTimeoutModal />
                <Modals
                    screen={this.state.screen}
                    errorCode={this.state.errorCode}
                    error={errorObj}
                    otpTimer={this.state.otpTimer}
                    cellNumber={this.state.cellNumber}
                    idNumber={this.state.idNumber}
                    setSubmit={submit => this.setSubmit(submit)}
                    genericErrorHandling={(errorCode, show) =>
                        this.genericErrorHandling(errorCode, show)
                    }
                    closeOtp={() => this.closeOtp()}
                    resetPage={() => this.reset()}
                />
                {this.state.submit && <Loading />}
            </Index>
        );
    }
}

function mapStateToProps(state) {
    return {
        tokenData: state.token.data,
        tokenError: state.token.error,
        saIdPin: state.saIdPin.data,
        saIdPinError: state.saIdPin.error,
        timeData: state.time.data,
        timeError: state.time.error,
        generateData: state.otp.generateData,
        generateError: state.otp.generateError,
        verifyData: state.otp.verifyData,
        verifyError: state.otp.verifyError,
        recaptcha: state.recaptcha,
        idNumber: state.searchSaid.said,
    };
}

function validate(formProps) {
    const errorTranslation = i18n.t(translationKeys.enterIdPin, { returnObjects: true }).errors;

    if (!formProps.saId) {
        errors = {};
    } else if (formProps.saId.replace(/[\s]/g, '').length < 13) {
        errors = {};
    } else if (formProps.saId) {
        const luhn = extractFromID(formProps.saId.replace(/[\s]/g, ''));

        if (luhn.birthdate.toString() === 'Invalid Date' || !luhn.valid) {
            errors.saId = errorTranslation.invalid;
        }
    }

    return errors;
}

export default withRouter(
    reduxForm({
        form: 'simple',
        validate,
    })(
        translate()(
            connect(mapStateToProps, {
                ...actions,
                resetAllState,
                verifyRecaptcha,
                getAccessToken,
                getGoogleTime,
                generateOtp,
            })(EnterIdPin),
        ),
    ),
);

