import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import _ from 'lodash';

import {
    VERIFY_RECAPTCHA,
    VERIFY_RECAPTCHA_SUCCESS,
    VERIFY_RECAPTCHA_ERROR,
} from '../../actions/types';
import {
    showCommonExceptionAction,
} from '../../actions/application';

import { Config } from '../../config';

export const verifyRecaptcha = (args, callback) => ({
    type: VERIFY_RECAPTCHA,
    payload: { args, callback },
});

export const verifyRecaptchaEpic = action$ =>
    action$.ofType(VERIFY_RECAPTCHA).mergeMap(action => {
        let body;
        let uri;

        switch (action.payload.args.type) {
            case 'iba':
                uri = Config.proxyUri.siteverifyIba;
                body = {
                    response: action.payload.args.response,
                    payload: action.payload.args.encrypted,
                };
                break;
            default:
                uri = Config.proxyUri.siteverify;
                body = {
                    response: action.payload.args.response,
                };
                break;
        }

        const callback = action.payload.callback || (function () {})

        return Observable.ajax({
            url: Config.proxy + uri,
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        })
            .map(({ response }) => {
                callback(response)
                return {
                    type: VERIFY_RECAPTCHA_SUCCESS,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                callback(xhr.response)
                return Observable.of(errorHelper(VERIFY_RECAPTCHA_ERROR, xhr.response), showCommonExceptionAction());
            });
    });
