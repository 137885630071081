import React from 'react';
import { useSpring, animated } from 'react-spring';
import joinclass from 'joinclass';

export const Header = ({ image, children, className }) => {
    const moveBottom = useSpring({
        from: {
            transform: 'translateY(-50px)',
            opacity: 0,
        },
        to: {
            transform: 'translateX(0)',
            opacity: 1,
        },

    });
    const moveLeft = useSpring({
        from: {
            transform: 'translateX(-50px)',
            opacity: 0,
        },
        to: {
            transform: 'translateX(0)',
            opacity: 1,
        },

    });

    return (
        <div className={joinclass(`header`, className)}>
            <div className="header-container container">
                <div className="row">
                    <animated.img className="header-image" src={image} alt="" style={moveBottom} />
                    <animated.div className="header-content col-xs-10 col-sm-12" style={moveLeft}>{children}</animated.div>
                </div>
            </div>
        </div>
    );
};

export default Header;
