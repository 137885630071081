import React from 'react';

const InputCardPANItem = React.forwardRef(({ inputValue = '', onChange, isPL, ...rest }, ref) => {
    return (
        <div className="input-card-pan-item-container">
            <input
                className="input-control"
                type={isPL ? 'text' : 'tel'}
                maxLength="1"
                value={inputValue}
                onChange={onChange}
                onFocus={e => e.target.select()}
                ref={ref}
                {...rest}
            />
        </div>
    );
});

export default InputCardPANItem;
