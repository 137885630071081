import { createAction } from 'redux-actions';

export const CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY';
export const CHECK_ELIGIBILITY_SUCCESS_RESET_PIN = 'CHECK_ELIGIBILITY_SUCCESS_RESET_PIN';
export const CHECK_ELIGIBILITY_FAILED_RESET_PIN = 'CHECK_ELIGIBILITY_FAILED_RESET_PIN';
export const CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD = 'CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD';
export const CHECK_ELIGIBILITY_FAILED_RESET_PASSWORD = 'CHECK_ELIGIBILITY_FAILED_RESET_PASSWORD';

export const checkEligibilityAction = createAction(CHECK_ELIGIBILITY);
export const checkEligibilitySuccessResetPinAction = createAction(
    CHECK_ELIGIBILITY_SUCCESS_RESET_PIN,
);
export const checkEligibilitySuccessResetPasswordAction = createAction(
    CHECK_ELIGIBILITY_SUCCESS_RESET_PASSWORD,
);
