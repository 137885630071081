import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import get from 'lodash/get';
import { $emit } from 'event-bus-e2z';

import {
    SEARCH_CREDIT_CARD_SAID,
    searchCreditCardSaidSuccessAction,
    searchingCreditCardSaidAction,
    searchCreditCardSaidFailedAction,
} from '../actions/searchCCSaid';

import {
    showNotCreditCardCustomerAction,
    showCommonExceptionAction,
    showBlockCreditCardProfileAction,
    showBlockCreditCardFeatureAction,
    showNotRemoveFicaAction,
    moveCreditCardStepAction
} from '../actions/application';

import {
    captureTCAction
} from '../actions/captureTC';

import { CREDIT_CARD_RESET_STEPS, CREDIT_CARD_ERROR_CODES } from '../constants';

export const searchCCSaidEpic = (action$, store, { searchCCSaidService }) =>
    action$.pipe(
        ofType(SEARCH_CREDIT_CARD_SAID),
        switchMap(action => {
            const idNumber = action.payload;
            return searchCCSaidService(action.payload).pipe(
                mergeMap((res) => {
                    const data = JSON.parse(res.xhr.response).sessionId;
                    return Observable.of(
                        searchCreditCardSaidSuccessAction({ sessionId: data }),
                        captureTCAction({
                            sessionId: data,
                            consent: true
                        })
                    )
                }),
                catchError(error => {
                    const status = get(error, 'status');
                    if (error && status !== 401) {
                        const jsonResponse = JSON.parse(error.xhr.response);
                        const errorCode =
                            jsonResponse && jsonResponse.errorCode ||
                            jsonResponse && jsonResponse.errors && jsonResponse.errors[0].errorCode;
                        switch (errorCode) {
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_SETUP_REQUEST:
                                // TODO: Fix it later
                                const requestToken = error.xhr // eslint-disable-line
                                    ? error.xhr.getResponseHeader('request-token')
                                    : '';
                                return Observable.of(
                                    searchingCreditCardSaidAction({
                                        idNumber,
                                        requestToken
                                    }),
                                    searchCreditCardSaidFailedAction(),
                                    moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.otp.stepId),
                                    () => $emit("movePageOtp")
                                );
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_ACCOOUNT_ACTIVATED:
                                return Observable.of(showNotCreditCardCustomerAction());
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_FEATURE_BLOCKED:
                                return Observable.of(showBlockCreditCardFeatureAction());
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PROFILE_BLOCKED:
                                return Observable.of(showBlockCreditCardProfileAction());
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_NOT_REMOVE_FICA:
                                return Observable.of(showNotRemoveFicaAction());
                            default:
                                return Observable.of(showCommonExceptionAction());
                        }
                    } else {
                        return Observable.of(showCommonExceptionAction());
                    }
                }),
            );
        }),
    );