import { PASSWORD_CAPTURE, PASSWORD_ERROR } from '../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case PASSWORD_CAPTURE:
            return { ...state, response: action.payload, error: undefined };
        case PASSWORD_ERROR:
            return { ...state, response: undefined, error: action.error };
        default:
            return state;
    }
}
