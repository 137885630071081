import { Observable } from 'rxjs';
import axios from 'axios';
import { Config } from './config';
import { getUuid } from './utils/utils';

export const baseUrl = `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}`;

export const searchSaidService = ({ said, type }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.searchId}?type=${type}`;
    const data = { saId: said };
    return Observable.fromPromise(axios.post(url, data));
};

export const requestOTPService = ({ requestToken }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpGenerate}`;
    const data = { requestToken };
    const config = {
        headers: {
            'Delivery-Method': 'SMS',
        },
    };
    return Observable.fromPromise(axios.post(url, data, config));
};

export const verifyOTPService = ({ otpCode, requestToken }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpVerify}`;
    const data = { otpCode, requestToken };
    const config = {
        headers: {
            'Delivery-Method': 'SMS',
        },
    };
    return Observable.fromPromise(axios.post(url, data, config));
};

export const verifyCardService = ({ said, cardPAN, stepUpToken, type }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.verifyCardPan}?type=${type}`;
    const data = { saId: said, last4digitsCardPan: cardPAN, stepUpToken };
    return Observable.fromPromise(axios.post(url, data));
};

export const requestMCQService = ({ said, stepUpToken, type }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.getMCQ}?type=${type}`;
    const data = { saId: said, stepUpToken };
    return Observable.fromPromise(axios.post(url, data));
};

export const setCredentialService = ({ newCredential, type, said, stepUpToken }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.setCredentialNew}`;
    const data = { newCredential, type, saId: said, stepUpToken };
    return Observable.fromPromise(axios.post(url, data));
};

export const checkEligibilityService = ({ said, type }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.checkEligibility}?type=${type}`;
    const data = { saId: said };
    return Observable.fromPromise(axios.post(url, data));
};

export const verifyMCQService = ({ sessionId, said, creditAnswers, stepUpToken, type }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.verifyMCQ}?type=${type}`;
    const data = {
        sessionId,
        saId: said,
        creditAnswers,
        stepUpToken,
    };
    return Observable.fromPromise(axios.post(url, data));
};

export const fetchAccessToken = () => {
    return axios.post(
        `${Config.rootUrl}/token`,
        'grant_type=client_credentials&scope=device_not_authorized',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Basic ${btoa(process.env.REACT_APP_REGISTERED_TOKEN_HEADERS)}`,
            },
        },
    );
};

export const getTimeAccessTokenService = () => {
    const url = `${Config.rootUrl}/token`;
    const data = 'grant_type=client_credentials&scope=device_not_authorized';
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(process.env.REACT_APP_TIME_TOKEN_HEADERS)}`,
        },
    };
    return Observable.fromPromise(axios.post(url, data, config));
};

export const getTimeService = timeAccessToken => {
    const url = `${Config.rootUrl}/ts/v1.0.0/time`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Session-Id': getUuid(),
            Authorization: `Bearer ${timeAccessToken}`,
        },
    };
    return Observable.fromPromise(axios.get(url, config));
};

export const verifyRecaptchaService = ({ response }) => {
    const url = `${Config.proxy}${Config.proxyUri.siteverify}`;
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const data = { response }
    return Observable.fromPromise(axios.post(url, data, config));
};

export const searchCCSaidService = (idNumber) => {
    return Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}${Config.apiUri.creditCardSession}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            Timestamp: new Date().toISOString(),
            Channel: 'Channel.Web',
            'Caller-Id': 'Channel.Web',
            'Device-Info': btoa(
                JSON.stringify({
                    device_name: window.navigator.userAgent,
                    device_os: '',
                    device_id: '',
                    sim_info: [],
                    mac: '',
                    geolocation: '',
                }),
            ),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': getUuid(),
            'Step-Up-Token': sessionStorage.getItem('stepUpToken') || '',
        },
        body: {
            idNumber
        },
        responseType: 'xml',
    });
};

export const captureTCService = (payload) => {
    const url = `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}${Config.apiUri.captureTC}`;
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const data = payload
    return Observable.fromPromise(axios.post(url, data, config));
};

export const verifyCreditCardService = ({ sessionId, cardPan }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}${Config.apiUri.creditCardIdentification}`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
    }
    const data = { sessionId, cardPan };
    return Observable.fromPromise(axios.post(url, data, config));
};

export const captureTaxService = ({ sessionId, crs }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}${Config.apiUri.captureTax}`;
    const data = { sessionId, crs };
    return Observable.fromPromise(axios.post(url, data));
};

export const creditCardSetCredentialService = ({ sessionId, type, credential }) => {
    const url = `${Config.rootUrl}/${Config.apiContext.creditCardOnboarding}/${Config.apiVersion}${Config.apiUri.creditCardSetCredential}`;
    const data = { sessionId, type, credential };
    return Observable.fromPromise(axios.post(url, data));
};

export const fetchNaturesPurposes = () => {
    return axios.get(
        `${baseUrl}${Config.apiUri.naturePurpose}`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        },
    );
};

export const captureNaturesPurpose = ({ sessionId, natures, purposes }) => {
    const url = `${baseUrl}${Config.apiUri.naturePurpose}`;
    const data = { sessionId, natureAndPurposes: [...natures, ...purposes] };
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
    });
};