import React from 'react';
import loadingIcon from '../../assets/images/loading.svg';

const Loading = () => (
    <div className="loading">
        <div className="overlay" />
        <img src={loadingIcon} alt="loading..." title="loading..." />
    </div>
);

export default Loading;
