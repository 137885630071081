import { MOVE_STEP } from '../actions/application';
import {
    SEARCH_SAID,
    SEARCH_SAID_FAILED_RESET_PIN,
    SEARCH_SAID_FAILED_RESET_PASSWORD
} from '../actions/searchSaid';
import { REQUEST_OTP, VERIFY_OTP } from '../actions/otp';
import {
    VERIFY_CARD_PAN_FAILED_RESET_PIN,
    VERIFY_CARD_PAN_FAILED_RESET_PASSWORD,
} from '../actions/card';
import { RESET_STEPS } from '../constants';

const initialState = {
    step: RESET_STEPS.said.stepId,
    errors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SAID:
            return {
                ...state,
                errors: null,
            };
        case SEARCH_SAID_FAILED_RESET_PIN:
            return {
                ...state,
                errors: action.payload,
            };
        case SEARCH_SAID_FAILED_RESET_PASSWORD:
            return {
                ...state,
                errors: action.payload,
            };
        case MOVE_STEP:
            return {
                ...state,
                step: action.payload,
            };
        case REQUEST_OTP:
            return {
                ...state,
                errors: null,
            };
        case VERIFY_OTP:
            return {
                ...state,
                errors: null,
            };
        case VERIFY_CARD_PAN_FAILED_RESET_PIN:
            return {
                ...state,
                errors: action.payload,
            };
        case VERIFY_CARD_PAN_FAILED_RESET_PASSWORD:
            return {
                ...state,
                errors: action.payload,
            };

        default:
            return state;
    }
};
