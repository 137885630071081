import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton  from '../common/TymeButton';
import { getIsShowCreditCardNotEligible} from '../../selectors';
import { CUSTOMER_SERVICE_CENTRE_EXTRA, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';

export const CreditCardNotEligibleModal = ({ onHide, onSubmit }) => {
    const isShowCreditCardNotEligible = useSelector(getIsShowCreditCardNotEligible);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_NOT_ELLIGIBLE}
            title="Your card isn’t currently active"
            type={MODAL_TYPE.ERROR}
            isShow={isShowCreditCardNotEligible}
            handleHide={onHide}>
            <div>
                <p>You might just need to activate it with a quick call to Customer Service. It’s also possible that the card is blocked, expired or cancelled.</p>
                <p>To find out, and for help with fixing the issue, call Customer Service on <span className="font-bold">{CUSTOMER_SERVICE_CENTRE_EXTRA}</span></p>
                <div className="btn-control-container">
                    <TymeButton 
                        id={BUTTON_ID.NOT_ELLIGIBLE_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}
                    >
                            OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

CreditCardNotEligibleModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CreditCardNotEligibleModal;
