import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, concatMap } from 'rxjs/operators';
import {
    SET_CREDENTIAL,
    setCredentialSuccessResetPinAction,
    setCredentialSuccessResetPasswordAction,
} from '../actions/setCredential';
import { showCommonExceptionAction } from '../actions/application';
import { RESET_CREDENTIAL_TYPE } from '../constants';
import { encryption } from '../utils/utils';

export const setCredentialObservable = ({
    time,
    newCredential,
    type,
    said,
    stepUpToken,
    from,
    setCredentialService,
}) => {
    const encryptedCredential = encryption(newCredential, time, 'rp');
    return setCredentialService({
        newCredential: encryptedCredential,
        type,
        said,
        stepUpToken,
    }).pipe(
        map(() => {
            switch (from) {
                case RESET_CREDENTIAL_TYPE.PIN:
                    return setCredentialSuccessResetPinAction();
                case RESET_CREDENTIAL_TYPE.PASSWORD:
                    return setCredentialSuccessResetPasswordAction();
                default:
                    return showCommonExceptionAction();
            }
        }),
        catchError(() => {
            return Observable.of(showCommonExceptionAction());
        }),
    );
};

export const setCredentialWithClientTimeObservable = ({
    newCredential,
    type,
    said,
    stepUpToken,
    from,
    setCredentialService,
}) => {
    const time = new Date().getTime().toString();
    return setCredentialObservable({
        time,
        newCredential,
        type,
        said,
        stepUpToken,
        from,
        setCredentialService,
    });
};

export const setCredentialEpic = (
    action$,
    store,
    { setCredentialService, getTimeService, getTimeAccessTokenService },
) =>
    action$.pipe(
        ofType(SET_CREDENTIAL),
        switchMap(action => {
            const { newCredential, type, said, stepUpToken, from } = action.payload;
            return getTimeAccessTokenService().pipe(
                concatMap(response => {
                    const timeAccessToken = response.data.access_token;
                    return getTimeService(timeAccessToken).pipe(
                        concatMap(response => {
                            const time = response.data;
                            return setCredentialObservable({
                                time,
                                newCredential,
                                type,
                                said,
                                stepUpToken,
                                from,
                                setCredentialService,
                            });
                        }),
                        catchError(() => {
                            return setCredentialWithClientTimeObservable({
                                newCredential,
                                type,
                                said,
                                stepUpToken,
                                from,
                                setCredentialService,
                            });
                        }),
                    );
                }),
                catchError(() => {
                    return setCredentialWithClientTimeObservable({
                        newCredential,
                        type,
                        said,
                        stepUpToken,
                        from,
                        setCredentialService,
                    });
                }),
            );
        }),
    );
