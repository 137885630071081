import { RESET_PASSWORD_CAPTURE, RESET_PASSWORD_ERROR } from '../../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case RESET_PASSWORD_CAPTURE:
            return { ...state, response: action.payload, error: undefined };
        case RESET_PASSWORD_ERROR:
            return { ...state, response: undefined, error: action.error };
        default:
            return state;
    }
}
