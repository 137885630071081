import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { requestOtpAction, verifyOtpAction } from '../../../actions/otp';
import { Config } from '../../../config';
import { 
    INITIAL_OTP_INPUT_ATTEMPT,
    CREDIT_CARD_RESET_STEPS,
    BUTTON_ID, 
    BUTTON_NAME,
} from '../../../constants';
import { useError, usePageTitle, useBodyClass } from '../../../utils/useHooks';
import { generateArrayValues, getResetPinCreditCardSteps, makeStringFromNumbers } from '../../../utils/utils';
import * as SELECTORS from '../../../selectors';
import TymeButton from '../../../components/common/TymeButton';
import InputOTP from '../../../components/new-otp/InputOTP';
import InnerLoading from '../../../components/common/InnerLoading';
import CanNotSendOTPModal from '../../../components/new-otp/CanNotSendOTPModal';
import InputAttemptOTPModal from '../../../components/new-otp/InputAttemptOTPModal';
import ResendAttemptOTPModal from '../../../components/new-otp/ResendAttemptOTPModal';
import Page from '../../../components/layout/Page';
import Header from '../../../components/layout/Header';
import StepBar from '../../../components/common/StepBar';
import CountDownCircle from '../../../components/common/CountDownCircle';
import TimeOut from '../../../components/common/TimeOut';
import headerImage from '../../../assets/images/header-1.png';
import Loading from '../../../components/loading';

const CCStepOTP = ({
    submitting = false,
    initialErrors,
    otpResendAttempt,
    onSubmit,
    onExpiredExceed,
    onNotSent,
    onResend,
    onInputAttempt,
    onCancel,
}) => {
    const dispatch = useDispatch();

    const requestToken = useSelector(SELECTORS.getCreditCardRequestToken);
    const isRequestingOTP = useSelector(SELECTORS.getIsRequestingOTP);
    const isRequestOTPFailed = useSelector(SELECTORS.getIsRequestingOTPFailed);
    const cellphoneNumber = useSelector(SELECTORS.getCellphoneNumber);
    const isVerifyingOTP = useSelector(SELECTORS.getIsVerifyingOTP);
    const isVerifyOTPFailed = useSelector(SELECTORS.getIsVerifyingOTPFailed);
    const initialRemainingSeconds = useSelector(SELECTORS.getOtpRemainingSecond);
    const stepUpToken = useSelector(SELECTORS.getStepUpToken);

    const [remainingOtpSeconds, setRemainingOtpSeconds] = useState(initialRemainingSeconds);
    const [otps, setOtps] = useState(generateArrayValues(Config.otp.numberOfInput));
    const [numberOfInputAttempt, setNumberOfInputAttempt] = useState(INITIAL_OTP_INPUT_ATTEMPT);
    const [isEmptyInput, setIsEmptyInput] = useState(true);
    const [isInputAttemptError, setInputAttemptError] = useState(false);
    const [isOtpExpired, setIsOtpExpired] = useState(false);
    const [isOtpExpiredExceed, setIsOtpExpiredExceed] = useState(false);
    const [errors, setErrors] = useError(initialErrors);

    usePageTitle('Enter your OTP | TymeBank');
    useBodyClass('credit-card');

    useEffect(() => {
        if(requestToken) {
            dispatch(requestOtpAction({ requestToken }));
        }
    }, [requestToken]);

    useEffect(() => {
        setRemainingOtpSeconds(initialRemainingSeconds);
    }, [initialRemainingSeconds]);

    useEffect(() => {
        let timeInterval;
        if (remainingOtpSeconds !== null) {
            if (
                remainingOtpSeconds > 0 &&
                !submitting &&
                !isOtpExpired &&
                !isInputAttemptError &&
                !isOtpExpiredExceed
            ) {
                // Count down
                timeInterval = setInterval(() => {
                    setRemainingOtpSeconds(prevSeconds => {
                        return prevSeconds - 1;
                    });
                }, 1000);
            }

            if (remainingOtpSeconds === 0) {
                otpResendAttempt === Config.otp.maxResend
                    ? setIsOtpExpiredExceed(true)
                    : setIsOtpExpired(true);
            }
        }

        return () => clearInterval(timeInterval);
    }, [remainingOtpSeconds, submitting, isOtpExpired, isInputAttemptError, isOtpExpiredExceed]);

    useEffect(() => {
        const emptyItems = otps.filter(item => item.value === '');
        setIsEmptyInput(emptyItems.length > 0);
    }, [otps]);

    useEffect(() => {
        stepUpToken && onSubmit(); // Verify OTP Success
    }, [stepUpToken]);

    useEffect(() => {
        if (isVerifyOTPFailed) {
            numberOfInputAttempt > Config.otp.maxInput - 1 && setInputAttemptError(true);
            switch (numberOfInputAttempt) {
                case 1:
                    setErrors({
                        message: `The OTP you entered is incorrect, please try again.`,
                    });
                    break;
                case 2:
                    setErrors({
                        message: `The OTP you entered is incorrect, you have one more chance to try again.`,
                    });
                    break;
                default:
                    break;
            }
            setOtps(generateArrayValues(Config.otp.numberOfInput));
            setNumberOfInputAttempt(prevAttempt => prevAttempt + 1);
        }
    }, [isVerifyOTPFailed]);

    const handleOtpChange = otps => {
        setErrors(null);
        setOtps(otps);
    };
    const handleOtpConfirm = () => {
        const otpCode = makeStringFromNumbers(otps);
        dispatch(
            verifyOtpAction({
                otpCode,
                requestToken,
            }),
        );
    };

    const otpPercentage = Math.floor((remainingOtpSeconds / Config.otp.timer) * 100);
    const isRequestOTPSuccess = !isRequestingOTP && !isRequestOTPFailed && remainingOtpSeconds !== null;
    const isShowInput = isRequestOTPSuccess && !isInputAttemptError && !isOtpExpiredExceed ;
    const isDisabledConfirm = isEmptyInput || submitting || isOtpExpired || isVerifyingOTP;

    return (
        <Page>
            <Header image={headerImage}>
                {!isRequestingOTP && (
                    <div className="step-otp__header">
                        <CountDownCircle
                            percentage={otpPercentage}
                            remainingSeconds={remainingOtpSeconds}
                        />
                    </div>
                )}
                <p className="heading">Enter your One-Time-Pin (OTP)</p>
                <p className="verify-cellphone">To verify your cellphone number, enter the OTP sent to <strong>{cellphoneNumber}</strong></p>
            </Header>
            <div className="page-content">
                <div className="container">
                    {!isRequestingOTP && (
                        <div className="row">
                            <div className="col-xs-12 credit-card-step-bar">
                                <StepBar
                                    label={CREDIT_CARD_RESET_STEPS.otp.title}
                                    currentStep={CREDIT_CARD_RESET_STEPS.otp.stepProgress}
                                    totalStep={getResetPinCreditCardSteps().length}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xs-12 resetPin">
                            <div className="step-otp step-otp-container">
                                {isRequestingOTP && <InnerLoading label="Sending OTP" />}
                                {isShowInput && (
                                    <div className="step-otp__main">
                                        <p className="step-otp__heading">
                                            For your security, this OTP will expired in{' '}
                                            {Config.otp.timer} seconds. You need to enter it as soon
                                            as you receive it.
                                        </p>
                                        <div className="step-otp__input-container">
                                            <InputOTP
                                                otps={otps}
                                                errors={errors}
                                                disabled={isOtpExpired}
                                                onChange={handleOtpChange}
                                            />
                                        </div>
                                        <div className="step-otp__input-container">
                                            {isOtpExpired && (
                                                <TymeButton
                                                    id={BUTTON_ID.OTP_RESEND}
                                                    name={BUTTON_NAME.RESEND}
                                                    className="btn tyme-btn btn-link"
                                                    onClick={onResend}>
                                                    RESEND OTP
                                                </TymeButton>
                                            )}
                                        </div>
                                        <div className="btn-control-container">
                                            <TymeButton
                                                id={BUTTON_ID.OTP_CANCEL}
                                                name={BUTTON_NAME.CANCEL}
                                                className="btn tyme-btn btn-secondary"
                                                onClick={onCancel}>
                                                Cancel
                                            </TymeButton>
                                            <TymeButton
                                                id={BUTTON_ID.OTP_CONFIRM}
                                                name={BUTTON_NAME.CONFIRM}
                                                disabled={isDisabledConfirm}
                                                onClick={handleOtpConfirm}>
                                                Confirm
                                            </TymeButton>
                                        </div>
                                    </div>
                                )}
                                <InputAttemptOTPModal
                                    isShow={isInputAttemptError}
                                    onSubmit={onInputAttempt}
                                    onHide={onInputAttempt}
                                />
                                <CanNotSendOTPModal
                                    isShow={isRequestOTPFailed}
                                    onSubmit={onNotSent}
                                    onHide={onNotSent}
                                />
                                <ResendAttemptOTPModal
                                    isShow={isOtpExpiredExceed}
                                    onSubmit={onExpiredExceed}
                                    onHide={onExpiredExceed}
                                />
                                 { submitting || isVerifyingOTP && <Loading /> }
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

CCStepOTP.propTypes = {
    submitting: PropTypes.bool,
    initialErrors: PropTypes.object,
    requestToken: PropTypes.string,
    isRequestingOTP: PropTypes.bool,
    cellphoneNumber: PropTypes.string,
    isRequestOTPFailed: PropTypes.bool,
    isVerifyingOTP: PropTypes.bool,
    isVerifyOTPFailed: PropTypes.bool,
    initialRemainingSeconds: PropTypes.number,
    stepUpToken: PropTypes.string,
    otpResendAttempt: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onNotSent: PropTypes.func.isRequired,
    onResend: PropTypes.func.isRequired,
    onInputAttempt: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onExpiredExceed: PropTypes.func.isRequired,
};

export default CCStepOTP;
