import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    VERIFY_RECAPTCHA,
    verifyRecaptchaSuccess,
} from '../actions/recaptcha';
import { showCommonExceptionAction } from '../actions/application';

export const newVerifyRecaptchaEpic = (action$, store, { verifyRecaptchaService }) =>
action$.pipe(
    ofType(VERIFY_RECAPTCHA),
    switchMap(action => {
        const { response } = action.payload;
        return verifyRecaptchaService({ response }).pipe(
            map(({ data: { token: { access_token } } }) => { // eslint-disable-line
                sessionStorage.setItem('accessToken', access_token);
                return verifyRecaptchaSuccess({ access_token });
            }),
            catchError(() => {
                return Observable.of(showCommonExceptionAction());
            }),
        );
    }),
);
