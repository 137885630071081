import {
    CC_SET_CREDENTIAL_PIN,
    CC_SET_CREDENTIAL_PIN_SUCCESS,
    CC_SET_CREDENTIAL_PIN_FAILED
} from '../actions/creditCardSetCredential';

import {
    SHOW_COMMON_EXCEPTION,
    MOVE_CREDIT_CARD_STEP,
    SHOW_PIN_TECHNICAL_ERROR,
} from '../actions/application';

const initialState = {
    isCCSettingPin: false,
    isCCSettingPinSuccess: false,
    isCCSettingPinFailed: false,
    errorCode: undefined,
    errorAttempt: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CC_SET_CREDENTIAL_PIN:
            return {
                ...state,
                isCCSettingPin: true,
                errorAttempt: 0
            };

        case CC_SET_CREDENTIAL_PIN_SUCCESS:
            return {
                ...state,
                isCCSettingPin: false,
                isCCSettingPinSuccess: true
            };

        case CC_SET_CREDENTIAL_PIN_FAILED:
            return {
                ...state,
                isCCSettingPin: false,
                isCCSettingPinFailed: true,
                errorCode: action.payload,
                errorAttempt: initialState.errorAttempt + 1
            };

        case MOVE_CREDIT_CARD_STEP: 
            return {
                ...state,
                isCCSettingPin: false
            };

        case SHOW_COMMON_EXCEPTION:
            return {
                isCCSettingPin: false,
                errorCode: undefined
            };

        case SHOW_PIN_TECHNICAL_ERROR:
            return {
                ...state,
                isCCSettingPin: false
            }
            
        default:
            return state;
    }
};
