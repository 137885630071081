import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowConfirmPayTax} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const ConfirmPayTaxModal = ({ onHide, onSubmitYes, onSubmitNo }) => {
    const isShowConfirmPayTax = useSelector(getIsShowConfirmPayTax);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_CONFIRM_PAY_TAX}
            title="Are you sure that you’re liable to pay tax outside SA?"
            type={MODAL_TYPE.INFO}
            isShow={isShowConfirmPayTax}
            handleHide={onHide}>
            <div>
                <p>We’re required by SARS to collect this information from you so that it can be exchanged with other countries’ tax authorities under CRS (Common Reporting Standards).
                    If you confirm that you’re liable to pay tax in another country, one of our agents will call you to get more exact details.</p>
                <p>Your banking profile will be locked until we’ve got these details from you.</p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.CRS_CONFIRM_YES}
                        name={BUTTON_NAME.YES_CONFIRM}
                        className="btn tyme-btn btn-secondary" 
                        onClick={onSubmitYes}
                    >
                        Yes, I confirm
                    </TymeButton>
                    <TymeButton
                        id={BUTTON_ID.CRS_CONFIRM_NO}
                        name={BUTTON_NAME.NO}
                        onClick={onSubmitNo}
                    >
                        No
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

ConfirmPayTaxModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmitYes: PropTypes.func.isRequired,
    onSubmitNo: PropTypes.func.isRequired,
};

export default ConfirmPayTaxModal;
