import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../../actions';
import { Config } from '../../../config';
import { RESET_PIN_SUBMIT, RESET_PIN_CAPTURE, RESET_PIN_ERROR } from '../../../actions/types';
import { getUuid } from '../../../utils/utils';

export const resetPin = (args, callback) => ({
    type: RESET_PIN_SUBMIT,
    payload: { args, callback },
});

export const resetPinEpic = action$ =>
    action$.ofType(RESET_PIN_SUBMIT).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.setCredential}?type=pin`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
                'Step-Up-Token': sessionStorage.getItem('stepUpToken'),
            },
            body: {
                sessionId: action.payload.args.sessionId,
                saId: sessionStorage.getItem('idNumber'),
                newPin: action.payload.args.pin,
                creditAnswers: action.payload.args.mcqAnswers,
            },
            responseType: 'xml',
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: RESET_PIN_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(RESET_PIN_ERROR, xhr));
            }),
    );
