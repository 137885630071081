import React from 'react';
import iconLock from '../../assets/images/lock.png';
import iconLogo from '../../assets/images/logo-black.png';

export const Footer = () => {
    return (
        <div className="footer footer-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-7 info">
                        <img
                            className="icon pull-left hidden-xs"
                            src={iconLock}
                            alt="lock"
                        />
                        <p>
                            Your security is extremely important to us. All information is
                            constantly protected.
                        </p>
                    </div>
                    <div className="col-sm-5 branding">
                        <img src={iconLogo} alt="" />
                        <p>
                            &copy; {new Date().getFullYear()} TymeBank
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
