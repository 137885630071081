import { MOVE_CREDIT_CARD_STEP } from '../actions/application';
import {
    SEARCH_CREDIT_CARD_SAID,
    SEARCH_CREDIT_CARD_SAID_FAILED
} from '../actions/searchCCSaid';

import {
    VERIFY_CREDIT_CARD_PAN_FAILED,
} from '../actions/creditCard';

import { REQUEST_OTP, VERIFY_OTP } from '../actions/otp';
import { CREDIT_CARD_RESET_STEPS } from '../constants';

const initialState = {
    step: CREDIT_CARD_RESET_STEPS.otp.stepId,
    errors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CREDIT_CARD_SAID:
            return {
                ...state,
                errors: null,
            };
        case SEARCH_CREDIT_CARD_SAID_FAILED:
            return {
                ...state,
                errors: action.payload,
            };
        case MOVE_CREDIT_CARD_STEP:
            return {
                ...state,
                step: action.payload,
            };
        case REQUEST_OTP:
            return {
                ...state,
                errors: null,
            };
        case VERIFY_OTP:
            return {
                ...state,
                errors: null,
            };
        case VERIFY_CREDIT_CARD_PAN_FAILED:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
