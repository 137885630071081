import React, { Component } from 'react';
import AppCrash from './AppCrash';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <AppCrash />;
        }

        return this.props.children;
    }
}

export default Component => props => (
    <ErrorBoundary>
        <Component {...props} />
    </ErrorBoundary>
);
