import {
    OTP_GENERATED,
    OTP_VERIFIED,
    OTP_GENERATE_ERROR,
    OTP_VERIFY_ERROR,
    OTP_RESET_STATE,
} from '../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case OTP_GENERATED:
            return { ...state, generateData: action.payload, generateError: undefined };
        case OTP_VERIFIED:
            return { ...state, verifyData: action.payload, verifyError: undefined };
        case OTP_GENERATE_ERROR:
            return { ...state, generateData: undefined, generateError: action.error };
        case OTP_VERIFY_ERROR:
            return { ...state, verifyData: undefined, verifyError: action.error };
        case OTP_RESET_STATE:
            state = {};
            return state;
        default:
            return state;
    }
}
