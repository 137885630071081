import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE_EXTRA, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowNoActiveCreditCard } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';
import { isPersonalLoan } from "../../utils/useHooks"

export const NotActiveCreditCardModal = ({ onHide, onSubmit, ...props }) => {
    const isShowNoActiveCreditCard = useSelector(getIsShowNoActiveCreditCard);
    const isPL = isPersonalLoan(props);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_NOT_ACTIVE_CARD}
            title={isPL ?
                "It looks like your loan hasn’t been disbursed yet" :
                "Looks like you haven’t activated your card yet"}
            type={MODAL_TYPE.CC_INFO}
            isShow={isShowNoActiveCreditCard}
            handleHide={onHide}>
            <div>
                <p>{isPL ?
                    "You’ll need to wait for the loan disbursement to be finalised before you can set up your login details."
                    : "You need to activate your credit card before you can set up your login details."}
                </p>
                <p className="card-not-active">
                    {isPL ?
                        <React.Fragment>Contact us at {' '} <span className="font-bold">{CUSTOMER_SERVICE_CENTRE_EXTRA}</span> if you need any further assistance.˝</React.Fragment>
                        : <React.Fragment>Activating is easy. You just need to call <span className="font-bold">{CUSTOMER_SERVICE_CENTRE_EXTRA}</span> and follow the prompts.</React.Fragment>}
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.NOT_ACTIVE_CARD_GOT_IT}
                        name={BUTTON_NAME.GOT_IT}
                        onClick={onSubmit}>
                        Got it
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

NotActiveCreditCardModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default NotActiveCreditCardModal;
