import React from 'react';
import PropTypes from 'prop-types';
import { CREDIT_CARD_RESET_STEPS, BUTTON_ID, BUTTON_NAME } from '../../../constants';
import { getResetPinCreditCardSteps } from '../../../utils/utils';
import { usePageTitle, useBodyClass } from '../../../utils/useHooks';
import TymeButton from '../../../components/common/TymeButton';
import TimeOut from '../../../components/common/TimeOut';
import StepBar from '../../../components/common/StepBar';
import Page from '../../../components/layout/Page';
import Header from '../../../components/layout/Header';
import headerImage from '../../../assets/images/header-4.png';
import Loading from '../../../components/loading';

const CCStepTax = ({ submitting = false, onYes, onNo }) => {
    usePageTitle('Capture CRS Tax | TymeBank');
    useBodyClass('credit-card');

    return (
        <Page>
            <Header image={headerImage}>
                <p className="pre-heading-tax">One more thing before we set up your Login PIN...</p>
                <p className="heading">Are you a tax resident outside of South Africa?</p>
                <p className="sub-heading sub-heading-tax">We’re asking you this because when you create your Login PIN 
                and password, you’ll have a free TymeBank EveryDay account.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.crs.title}
                                currentStep={CREDIT_CARD_RESET_STEPS.crs.stepProgress}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="step-creditcard-tax">
                                <div className="btn-control-container-crs">
                                    <TymeButton
                                        id={BUTTON_ID.CRS_YES}
                                        name={BUTTON_NAME.YES}
                                        className="btn tyme-btn btn-secondary"
                                        onClick={onYes}>
                                        Yes
                                    </TymeButton>
                                    <TymeButton
                                        id={BUTTON_ID.CRS_NO}
                                        name={BUTTON_NAME.NO}
                                        className="btn tyme-btn btn-primary"
                                        onClick={onNo}>
                                        No
                                    </TymeButton>
                                </div>
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                    {submitting && <Loading />}
                </div>
            </div>
        </Page>
    );
};

CCStepTax.propTypes = {
    submitting: PropTypes.bool,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
};

export default CCStepTax;
