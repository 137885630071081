import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'rxjs';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import Routes from './routes';
import './assets/sass/index.scss';
import store from './store';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

require('bootstrap/dist/js/bootstrap.min.js');

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
);
