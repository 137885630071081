import {
    SEARCH_CREDIT_CARD_SAID,
    SEARCH_CREDIT_CARD_SAID_SUCCESS,
    SEARCH_CREDIT_CARD_SAID_FAILED,
    SEARCHING_CREDIT_CARD_SAID
} from '../actions/searchCCSaid';

import {
    SHOW_BLOCK_CREDIT_CARD_FEATURE,
    SHOW_NOT_CREDIT_CARD_CUSTOMER,
    SHOW_BLOCK_CREDIT_CARD_PROFILE,
    SHOW_COMMON_EXCEPTION,
    SHOW_NOT_REMOVE_FICA
} from '../actions/application';

import {
    REQUEST_OTP,
    REQUEST_OTP_SUCCESS,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
} from '../actions/otp';


const initialState = {
    isSearchingCCSaid: false,
    success: false,
    failed: false,
    idNumber: '',
    sessionId: '',
    requestToken: '',
    isCheckingCreditCardEligibility: false,
    errorCode: undefined,
    identifier: null // personal-loan
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CREDIT_CARD_SAID:
            return {
                ...state,
                idNumber: action.payload,
                isSearchingCCSaid: true,
            };
        case SEARCH_CREDIT_CARD_SAID_SUCCESS:
            return {
                ...state,
                sessionId: action.payload.sessionId,
                isSearchingCCSaid: false,
                success: true,
                identifier: action.payload.identifier,
            };
        case SEARCH_CREDIT_CARD_SAID_FAILED:
            return {
                ...state,
                isSearchingCCSaid: false,
                failed: true,
                errorCode: action.payload,
            };

        case SEARCHING_CREDIT_CARD_SAID: 
            return {
                ...state,
                isSearchingCCSaid: true,
                requestToken: action.payload.requestToken,
                isCheckingCreditCardEligibility: true,
                errorCode: undefined,
            };

        case REQUEST_OTP:
            return {
                ...state,
                isSearchingCCSaid: false,
                isCheckingCreditCardEligibility: false
            }

        case REQUEST_OTP_SUCCESS:
            return {
                ...state,
                isSearchingCCSaid: false,
                isCheckingCreditCardEligibility: false
            }

        case VERIFY_OTP:
            return {
                ...state,
                isSearchingCCSaid: false,
                isCheckingCreditCardEligibility: false
            }

        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isSearchingCCSaid: false,
                isCheckingCreditCardEligibility: false
            }

        case SHOW_BLOCK_CREDIT_CARD_FEATURE:
            return {
                ...state,
                isSearchingCCSaid: false,
            };

        case SHOW_NOT_CREDIT_CARD_CUSTOMER:
            return {
                ...state,
                isSearchingCCSaid: false
            };

        case SHOW_BLOCK_CREDIT_CARD_PROFILE:
            return {
                ...state,
                isSearchingCCSaid: false,
            };

        case SHOW_COMMON_EXCEPTION:
            return {
                isSearchingCCSaid: false
            };
        
        case SHOW_NOT_REMOVE_FICA:
            return {
                ...state,
                isSearchingCCSaid: false
            };

        default:
            return state;
    }
};
