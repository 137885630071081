import React from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';

export const CountDownCircle = ({ percentage, remainingSeconds }) => {
    return (
        <div className="progress-circle">
            <CircularProgressbar
                className="progressbar"
                percentage={percentage}
                textForPercentage={() => ''}
                strokeWidth="3"
            />
            <div className="seconds">
                {remainingSeconds}
                <span>seconds</span>
            </div>
        </div>
    );
};

CountDownCircle.propTypes = {
    percentage: PropTypes.number.isRequired,
    remainingSeconds: PropTypes.number,
};
export default CountDownCircle;
