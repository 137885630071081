import { RESET_STATE, GET_ACCESS_TOKEN, GET_GOOGLE_TIME } from './types';

export function errorHelper(actionType, error) {
    return {
        type: actionType,
        error,
    };
}
export function resetAllState() {
    return dispatch => {
        dispatch({ type: RESET_STATE });
    };
}
export function getAccessToken(args, callback, access = 'token') {
    return {
        type: GET_ACCESS_TOKEN,
        payload: {
            args,
            access,
            callback,
        },
    };
}
export function getGoogleTime(callback) {
    return {
        type: GET_GOOGLE_TIME,
        payload: callback,
    };
}
