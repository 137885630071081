import {
    CAPTURE_TAX,
    CAPTURE_TAX_SUCCESS,
    CAPTURE_TAX_FAILED,
} from '../actions/captureTax';

import {
    SHOW_COMMON_EXCEPTION,
    SHOW_CONFIRM_PAY_TAX
} from '../actions/application';

const initialState = {
    success: false,
    failed: false,
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CAPTURE_TAX:
            return {
                ...state,
                loading: true
            };
        case CAPTURE_TAX_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CAPTURE_TAX_FAILED:
            return {
                ...state,
                loading: false,
                failed: true
            };

        case SHOW_COMMON_EXCEPTION:
            return {
                loading: false
            };

        case SHOW_CONFIRM_PAY_TAX:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};
