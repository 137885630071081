import { RESET_PIN_CAPTURE, RESET_PIN_ERROR } from '../../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case RESET_PIN_CAPTURE:
            return { ...state, response: action.payload, error: undefined };
        case RESET_PIN_ERROR:
            return { ...state, response: undefined, error: action.error };
        default:
            return state;
    }
}

