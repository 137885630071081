import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';
import { isPersonalLoan } from '../../utils/useHooks';
import { TOGGLE_FEATURE } from '../../constants';

export const StepBar = ({ label, currentStep, totalStep, replaceStep, stepPL = false }) => {

    const isPL = (isPersonalLoan() && TOGGLE_FEATURE.NATURE_PURPOSE) || stepPL;

    // we insert on tab 4 for  PL
    let newStep = replaceStep || currentStep;
    if (isPL) {
        if (currentStep >= 4) {
            newStep += 1;
        }
    }
    label = `Step ${newStep} of ${totalStep}`;

    const progressing = useSpring({
        from: {
            width: `${((newStep - 1) / totalStep) * 100}%`,
        },
        to: {
            width: `${(newStep / totalStep) * 100}%`,
        },
        delay: 500,
        config: config.slow,
    });

    return (
        <div className="stepbar-container">
            <div className="stepbar-item">
                {label}
                <animated.span className="stepbar-progress" style={progressing} />
            </div>
        </div>
    );
};

StepBar.propTypes = {
    label: PropTypes.string,
    currentStep: PropTypes.number,
    totalStep: PropTypes.number.isRequired,
    replaceStep: PropTypes.number,
    stepPL: PropTypes.bool,
};

export default StepBar;
