import { createAction } from 'redux-actions';

export const SEARCH_CREDIT_CARD_SAID = 'SEARCH_CREDIT_CARD_SAID';
export const SEARCH_CREDIT_CARD_SAID_SUCCESS = 'SEARCH_CREDIT_CARD_SAID_SUCCESS';
export const SEARCH_CREDIT_CARD_SAID_FAILED = 'SEARCH_CREDIT_CARD_SAID_FAILED';
export const SEARCHING_CREDIT_CARD_SAID = 'SEARCHING_CREDIT_CARD_SAID'

export const searchCreditCardSaidAction = createAction(SEARCH_CREDIT_CARD_SAID);
export const searchCreditCardSaidSuccessAction = createAction(SEARCH_CREDIT_CARD_SAID_SUCCESS);
export const searchCreditCardSaidFailedAction = createAction(SEARCH_CREDIT_CARD_SAID_FAILED);
export const searchingCreditCardSaidAction = createAction(SEARCHING_CREDIT_CARD_SAID);