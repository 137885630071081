import React from 'react';
import PropTypes from 'prop-types';

const TymeInput = ({
    label,
    value = '',
    error = null,
    type = 'text',
    containerClassName = 'input-container',
    labelClassName = 'input-label',
    inputClassName = 'input-control',
    handleBlur,
    handleChange,
    handleKeyPress = () => {},
    ...rest
}) => {
    return (
        <div className={containerClassName}>
            <label className={labelClassName}>{label}</label>
            <div className={`${error ? 'has-error' : ''}`}>
                <input
                    className={inputClassName}
                    type={type}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyPress={e => handleKeyPress(e.which)}
                    {...rest}
                />
                <div className="help-block">{error ? error.message : ''}</div>
            </div>
        </div>
    );
};

TymeInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.exact({
        message: PropTypes.string,
    }),
    type: PropTypes.string,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleKeyPress: PropTypes.func,
};

export default TymeInput;
