import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    REQUEST_OTP,
    VERIFY_OTP,
    requestOtpFailedAction,
    requestOtpSuccessAction,
    verifyOtpFailedAction,
    verifyOtpSuccessAction,
} from '../actions/otp';
import { Config } from '../config';
import { showCommonExceptionAction } from '../actions/application';

export const requestOtpEpic = (action$, store, { requestOTPService }) =>
    action$.pipe(
        ofType(REQUEST_OTP),
        switchMap(action => {
            const { requestToken } = action.payload;
            return requestOTPService({ requestToken }).pipe(
                map(({ data: { deliverTo } }) => {
                    return requestOtpSuccessAction({
                        cellphoneNumber: deliverTo,
                        otpRemainingSecond: Config.otp.timer,
                    });
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        return Observable.of(requestOtpFailedAction());
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );

export const verifyOTPEpic = (action$, store, { verifyOTPService }) =>
    action$.pipe(
        ofType(VERIFY_OTP),
        switchMap(action => {
            const { otpCode, requestToken } = action.payload;
            return verifyOTPService({ otpCode, requestToken }).pipe(
                map(({ data: { stepUpToken } }) => {
                    sessionStorage.setItem('stepUpToken', stepUpToken);
                    return verifyOtpSuccessAction({ stepUpToken });
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        return Observable.of(verifyOtpFailedAction());
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );
