import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    PASSWORD_SUBMIT,
    PASSWORD_CAPTURE,
    PASSWORD_ERROR,
    LOGOUT_SUBMIT,
    LOGOUT_CAPTURE,
    LOGOUT_ERROR,
} from '../../actions/types';
import { getUuid } from '../../utils/utils';

export const submitPassword = (args, callback) => ({
    type: PASSWORD_SUBMIT,
    payload: { args, callback },
});

export const submitPasswordEpic = action$ =>
    action$.ofType(PASSWORD_SUBMIT).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.activation}/${Config.apiVersion}${Config.apiUri.passwordActivation}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
                'Step-Up-Token': sessionStorage.getItem('stepUpToken'),
            },
            body: {
                said: sessionStorage.getItem('idNumber'),
                password: action.payload.args.password,
            },
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: PASSWORD_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(PASSWORD_ERROR, xhr));
            }),
    );

export const activationLogout = (args, callback) => ({
    type: LOGOUT_SUBMIT,
    payload: { args, callback },
});

export const activationLogoutEpic = action$ =>
    action$.ofType(LOGOUT_SUBMIT).mergeMap(action =>
        Observable.ajax({
            url: `${Config.proxy}${Config.proxyUri.logout}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                id_token: action.payload.args.idToken,
                cookies: action.payload.args.cookies,
            },
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: LOGOUT_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(LOGOUT_ERROR, xhr));
            }),
    );
