import React from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import translationKeys from '../../i18n/translationKeys';
import Button from '../form-elements/button';

import { ReactComponent as SecurityIcon } from '../../assets/images/security.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/checked.svg';

export function WelcomeNewUserModal({ history, t }) {
    const translatedTexts = t(translationKeys.welcomeNewUser, { returnObjects: true });
    const buttonTranslation = t(translationKeys.button, { returnObjects: true });

    const gotoCreatePin = () => {
        history.push(`/`);
    };

    return (
        <div className="welcome-modal-wrapper">
            <div className="content-box">
                <div className="security-img">
                    <SecurityIcon />
                </div>
                <h1>{translatedTexts.heading}</h1>
                <ul className="benefit-list">
                    <li className="benefit-items">
                        <CheckedIcon />
                        <div>
                            <span>{translatedTexts.steps.title}</span>
                            <ul className="child-list">
                                <li>{translatedTexts.steps.first}</li>
                                <li>{translatedTexts.steps.second}</li>
                                <li>{translatedTexts.steps.third}</li>
                            </ul>
                        </div>
                    </li>
                    <li className="benefit-items icon-center">
                        <CheckedIcon />
                        <span>{translatedTexts.warning}</span>
                    </li>
                </ul>
                <Button
                    id="create-password"
                    className="btn btn-solid btn-create-password"
                    type="submit"
                    value={buttonTranslation.createPassword}
                    name="create-password"
                    onClick={gotoCreatePin}
                />
            </div>
        </div>
    );
}

export default withRouter(translate()(WelcomeNewUserModal));
