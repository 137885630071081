export default {
    button: 'button',
    errors: 'errors',
    password: 'password',
    otp: 'otp',
    mcq: 'mcq',
    enterIdPin: 'enterIdPin',
    timeout: 'timeout',
    footer: 'footer',
    connectionTimeout: 'connectionTimeout',
    reset: 'reset',
    resetPassword: 'resetPassword',
    resetPin: 'resetPin',
    creditCardSelection: 'creditCardSelection',
    said: 'enterSaid',
    welcomeNewUser: 'welcomeNewUser',
};
