import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    CHECK_ELIGIBILITY,
    checkEligibilitySuccessResetPinAction,
    checkEligibilitySuccessResetPasswordAction,
} from '../actions/eligibility';
import { showCommonExceptionAction } from '../actions/application';
import { RESET_CREDENTIAL_TYPE } from '../constants';

export const checkEligibilityEpic = (action$, store, { checkEligibilityService }) =>
    action$.pipe(
        ofType(CHECK_ELIGIBILITY),
        switchMap(action => {
            const { said, from } = action.payload;
            return checkEligibilityService({ said, type: from }).pipe(
                map(({ data: { profileDisabled, hasActiveCard, featureEnabled } }) => {
                    switch (from) {
                        case RESET_CREDENTIAL_TYPE.PIN:
                            return checkEligibilitySuccessResetPinAction({
                                type: from,
                                profileDisabled,
                                hasActiveCard,
                                featureEnabled,
                            });
                        case RESET_CREDENTIAL_TYPE.PASSWORD:
                            return checkEligibilitySuccessResetPasswordAction({
                                type: from,
                                profileDisabled,
                                hasActiveCard,
                                featureEnabled,
                            });
                        default:
                            return Observable.of(showCommonExceptionAction());
                    }
                }),
                catchError(() => {
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );
