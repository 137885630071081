import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    SEARCH_SAID,
    searchSaidFailedResetPinAction,
    searchSaidSuccessResetPinAction,
    searchSaidSuccessResetPasswordAction,
    searchSaidFailedResetPasswordAction,
    searchSaidFailedNotActivatedResetPasswordAction,
} from '../actions/searchSaid';
import { showCommonExceptionAction, emptyAction } from '../actions/application';
import { RESET_CREDENTIAL_TYPE, NOT_ACTIVATED_ERROR_CODE } from '../constants';

export const searchSaidEpic = (action$, store, { searchSaidService }) =>
    action$.pipe(
        ofType(SEARCH_SAID),
        switchMap(action => {
            const { said, from } = action.payload;
            return searchSaidService({ said, type: from }).pipe(
                map(({ data: { requestToken } }) => {
                    switch (from) {
                        case RESET_CREDENTIAL_TYPE.PIN:
                            return searchSaidSuccessResetPinAction({
                                said,
                                requestToken,
                            });
                        case RESET_CREDENTIAL_TYPE.PASSWORD:
                            return searchSaidSuccessResetPasswordAction({
                                said,
                                requestToken,
                            });
                        default:
                            return emptyAction();
                    }
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        const { response: { data: { errors }} } = error;
                        const { errorCode } = errors[0];
                        switch (from) {
                            case RESET_CREDENTIAL_TYPE.PIN:
                                return Observable.of(
                                    searchSaidFailedResetPinAction({
                                        message: 'Invalid ID number, please try again.',
                                    }),
                                );
                            case RESET_CREDENTIAL_TYPE.PASSWORD:
                                if (errorCode === NOT_ACTIVATED_ERROR_CODE) {
                                    return Observable.of(
                                        searchSaidFailedNotActivatedResetPasswordAction({
                                            said
                                        }),
                                    );
                                }
                                return Observable.of(
                                    searchSaidFailedResetPasswordAction({
                                        message: 'Invalid ID number, please try again.',
                                    }),
                                );
                            default:
                                return Observable.of(showCommonExceptionAction());
                        }
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );
