import {
    REQUEST_OTP,
    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILED,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
} from '../actions/otp';

import {
    CAPTURE_TC,
    CAPTURE_TC_SUCCESS,
    CAPTURE_TC_FAILED,
} from '../actions/captureTC';

import {
    SEARCH_CREDIT_CARD_SAID,
    SEARCH_CREDIT_CARD_SAID_SUCCESS,
    SEARCH_CREDIT_CARD_SAID_FAILED
} from '../actions/searchCCSaid';

import {
    SHOW_BLOCK_CREDIT_CARD_FEATURE,
    SHOW_BLOCK_CREDIT_CARD_PROFILE,
    SHOW_COMMON_EXCEPTION
} from '../actions/application';

const initialState = {
    isRequestingOTP: false,
    isRequestOTPFailed: false,
    isVerifyingOTP: false,
    isVerifyOTPFailed: false,
    cellphoneNumber: '',
    otpRemainingSecond: null,
    stepUpToken: '',
    isVerifyOTPSuccess: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_OTP:
            return {
                ...state,
                otpRemainingSecond: null,
                isRequestingOTP: true,
                isRequestOTPFailed: false,
            };
        case REQUEST_OTP_SUCCESS: {
            const { cellphoneNumber, otpRemainingSecond } = action.payload;
            return {
                ...state,
                cellphoneNumber,
                otpRemainingSecond,
                isRequestingOTP: false,
                isRequestOTPFailed: false,
            };
        }
        case REQUEST_OTP_FAILED:
            return {
                ...state,
                cellphoneNumber: '',
                otpRemainingSecond: null,
                isRequestingOTP: false,
                isRequestOTPFailed: true,
            };
        case VERIFY_OTP:
            return {
                ...state,
                isVerifyingOTP: true,
                isVerifyOTPFailed: false,
            };
        case VERIFY_OTP_SUCCESS: {
            const { stepUpToken } = action.payload;
            return {
                ...state,
                stepUpToken,
                isVerifyingOTP: false,
                isVerifyOTPFailed: false,
                isVerifyOTPSuccess: true,
            };
        }
        case VERIFY_OTP_FAILED:
            return {
                ...state,
                stepUpToken: '',
                isVerifyingOTP: false,
                isVerifyOTPFailed: true,
            };

        case SEARCH_CREDIT_CARD_SAID:
            return {
                ...state,
                isVerifyingOTP: true
            };
        
        case SEARCH_CREDIT_CARD_SAID_SUCCESS:
            return {
                ...state,
                isVerifyingOTP: false
            };
        
        case SEARCH_CREDIT_CARD_SAID_FAILED:
            return {
                ...state,
                isVerifyingOTP: false
            };

        case CAPTURE_TC:
            return {
                ...state,
                isVerifyingOTP: true
            };

        case CAPTURE_TC_SUCCESS:
            return {
                ...state,
                isVerifyingOTP: false
            };

        case CAPTURE_TC_FAILED:
            return {
                ...state,
                isVerifyingOTP: false
            };

        case SHOW_BLOCK_CREDIT_CARD_FEATURE:
            return {
                ...state,
                isVerifyingOTP: false
            };

        case SHOW_BLOCK_CREDIT_CARD_PROFILE:
            return {
                ...state,
                isVerifyingOTP: false
            };

        case SHOW_COMMON_EXCEPTION: 
            return {
                ...state,
                isVerifyingOTP: false
            };
        
        default:
            return state;
    }
};
