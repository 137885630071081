import { createAction } from 'redux-actions';

export const SHOW_COMMON_EXCEPTION = 'SHOW_COMMON_EXCEPTION';
export const HIDE_COMMON_EXCEPTION = 'HIDE_COMMON_EXCEPTION';
export const SHOW_BLOCK_FEATURE = 'SHOW_BLOCK_FEATURE';
export const HIDE_BLOCK_FEATURE = 'HIDE_BLOCK_FEATURE';
export const SHOW_BLOCK_PROFILE = 'SHOW_BLOCK_PROFILE';
export const HIDE_BLOCK_PROFILE = 'HIDE_BLOCK_PROFILE';
export const SHOW_CONFIRM_BEFORE_EXIT = 'SHOW_CONFIRM_BEFORE_EXIT';
export const HIDE_CONFIRM_BEFORE_EXIT = 'HIDE_CONFIRM_BEFORE_EXIT';
export const MOVE_STEP = 'MOVE_STEP';
export const EMPTY_ACTION = 'EMPTY_ACTION';
export const SHOW_TIMEOUT = 'SHOW_TIMEOUT';
export const HIDE_TIMEOUT = 'HIDE_TIMEOUT';

export const SHOW_BLOCK_CREDIT_CARD_PROFILE = 'SHOW_BLOCK_CREDIT_CARD_PROFILE';
export const HIDE_BLOCK_CREDIT_CARD_PROFILE = 'HIDE_BLOCK_CREDIT_CARD_PROFILE';
export const MOVE_CREDIT_CARD_STEP = 'MOVE_CREDIT_CARD_STEP';
export const SHOW_BLOCK_CREDIT_CARD_FEATURE = 'SHOW_BLOCK_CREDIT_CARD_FEATURE';
export const HIDE_BLOCK_CREDIT_CARD_FEATURE = 'HIDE_BLOCK_CREDIT_CARD_FEATURE';
export const SHOW_NOT_CREDIT_CARD_CUSTOMER = 'SHOW_NOT_CREDIT_CARD_CUSTOMER';
export const HIDE_NOT_CREDIT_CARD_CUSTOMER = 'HIDE_NOT_CREDIT_CARD_CUSTOMER';
export const SHOW_NOT_ACTIVE_CREDIT_CARD = 'SHOW_NOT_ACTIVE_CREDIT_CARD';
export const HIDE_NOT_ACTIVE_CREDIT_CARD = 'HIDE_NOT_ACTIVE_CREDIT_CARD';
export const SHOW_CREDIT_CARD_NOT_ELIGIBLE = 'SHOW_CREDIT_CARD_NOT_ELIGIBLE';
export const HIDE_CREDIT_CARD_NOT_ELIGIBLE = 'HIDE_CREDIT_CARD_NOT_ELIGIBLE';
export const SHOW_CONFIRM_PAY_TAX = 'SHOW_CONFIRM_PAY_TAX';
export const HIDE_CONFIRM_PAY_TAX = 'HIDE_CONFIRM_PAY_TAX';
export const SHOW_NOT_REMOVE_FICA = 'SHOW_NOT_REMOVE_FICA';
export const HIDE_NOT_REMOVE_FICA = 'HIDE_NOT_REMOVE_FICA';
export const SHOW_SET_PASSWORD_LATER_MODAL = 'SHOW_SET_PASSWORD_LATER_MODAL';
export const HIDE_SET_PASSWORD_LATER_MODAL = 'HIDE_SET_PASSWORD_LATER_MODAL';
export const SHOW_PASSWORD_TECHNICAL_ERROR = 'SHOW_PASSWORD_TECHNICAL_ERROR';
export const HIDE_PASSWORD_TECHNICAL_ERROR = 'HIDE_PASSWORD_TECHNICAL_ERROR';
export const SHOW_RETRY_RESULT_MODAL = 'SHOW_RETRY_RESULT_MODAL';
export const HIDE_RETRY_RESULT_MODAL = 'HIDE_RETRY_RESULT_MODAL';
export const SHOW_PIN_TECHNICAL_ERROR = 'SHOW_PIN_TECHNICAL_ERROR';
export const HIDE_PIN_TECHNICAL_ERROR = 'HIDE_PIN_TECHNICAL_ERROR';
export const SHOW_SKIP_MCQ_RESET_PIN_MODAL = 'SHOW_SKIP_MCQ_RESET_PIN_MODAL';
export const HIDE_SKIP_MCQ_RESET_PIN_MODAL = 'HIDE_SKIP_MCQ_RESET_PIN_MODAL';
export const SHOW_SKIP_MCQ_RESET_PASSWORD_MODAL = 'SHOW_SKIP_MCQ_RESET_PASSWORD_MODAL';
export const HIDE_SKIP_MCQ_RESET_PASSWORD_MODAL = 'HIDE_SKIP_MCQ_RESET_PASSWORD_MODAL';

export const showCommonExceptionAction = createAction(SHOW_COMMON_EXCEPTION);
export const hideCommonExceptionAction = createAction(HIDE_COMMON_EXCEPTION);
export const showBlockFeatureAction = createAction(SHOW_BLOCK_FEATURE);
export const hideBlockFeatureAction = createAction(HIDE_BLOCK_FEATURE);
export const showBlockProfileAction = createAction(SHOW_BLOCK_PROFILE);
export const hideBlockProfileAction = createAction(HIDE_BLOCK_PROFILE);
export const showConfirmBeforeExitAction = createAction(SHOW_CONFIRM_BEFORE_EXIT);
export const hideConfirmBeforeExitAction = createAction(HIDE_CONFIRM_BEFORE_EXIT);
export const emptyAction = createAction(EMPTY_ACTION);
export const moveStepResetPinAction = createAction(MOVE_STEP);
export const showTimeOutAction = createAction(SHOW_TIMEOUT);
export const hideTimeOutAction = createAction(HIDE_TIMEOUT);

export const showBlockCreditCardProfileAction = createAction(SHOW_BLOCK_CREDIT_CARD_PROFILE);
export const hideBlockCreditCardProfileAction = createAction(HIDE_BLOCK_CREDIT_CARD_PROFILE);
export const moveCreditCardStepAction = createAction(MOVE_CREDIT_CARD_STEP);
export const showBlockCreditCardFeatureAction = createAction(SHOW_BLOCK_CREDIT_CARD_FEATURE);
export const hideBlockCreditCardFeatureAction = createAction(HIDE_BLOCK_CREDIT_CARD_FEATURE);
export const showNotCreditCardCustomerAction = createAction(SHOW_NOT_CREDIT_CARD_CUSTOMER);
export const hideNotCreditCardCustomerAction = createAction(HIDE_NOT_CREDIT_CARD_CUSTOMER);
export const showNotActiveCreditCardAction = createAction(SHOW_NOT_ACTIVE_CREDIT_CARD);
export const hideNotActiveCreditCardAction = createAction(HIDE_NOT_ACTIVE_CREDIT_CARD);
export const showCreditCardNotEligibleAction = createAction(SHOW_CREDIT_CARD_NOT_ELIGIBLE);
export const hideCreditCardNotEligibleAction = createAction(HIDE_CREDIT_CARD_NOT_ELIGIBLE);
export const showConfirmPayTaxAction = createAction(SHOW_CONFIRM_PAY_TAX);
export const hideConfirmPayTaxAction = createAction(HIDE_CONFIRM_PAY_TAX);
export const showNotRemoveFicaAction = createAction(SHOW_NOT_REMOVE_FICA);
export const hideNotRemoveFicaAction = createAction(HIDE_NOT_REMOVE_FICA);
export const showSetPasswordLaterModalAction = createAction(SHOW_SET_PASSWORD_LATER_MODAL);
export const hideSetPasswordLaterModalAction = createAction(HIDE_SET_PASSWORD_LATER_MODAL);
export const showPasswordTechnicalErrorAction = createAction(SHOW_PASSWORD_TECHNICAL_ERROR);
export const hidePasswordTechnicalErrorAction = createAction(HIDE_PASSWORD_TECHNICAL_ERROR);
export const showRetryResultModalAction = createAction(SHOW_RETRY_RESULT_MODAL);
export const hideRetryResultModalAction = createAction(HIDE_RETRY_RESULT_MODAL);
export const showPinTechnicalErrorAction = createAction(SHOW_PIN_TECHNICAL_ERROR);
export const hidePinTechnicalErrorAction = createAction(HIDE_PIN_TECHNICAL_ERROR);
export const showSkipMCQResetPinAction = createAction(SHOW_SKIP_MCQ_RESET_PIN_MODAL);
export const hideSkipMCQResetPinAction = createAction(HIDE_SKIP_MCQ_RESET_PIN_MODAL);
export const showSkipMCQResetPasswordAction = createAction(SHOW_SKIP_MCQ_RESET_PASSWORD_MODAL);
export const hideSkipMCQResetPasswordAction = createAction(HIDE_SKIP_MCQ_RESET_PASSWORD_MODAL);