export const RESET_STATE = 'reset_state';
export const GET_ACCESS_TOKEN = 'get_access_token';
export const GET_ACCESS_TOKEN_SUCCESS = 'get_access_token_success';
export const GET_ACCESS_TOKEN_ERROR = 'get_access_token_error';
export const GET_GOOGLE_TIME = 'get_google_time';
export const GET_GOOGLE_TIME_SUCCESS = 'get_google_time_success';
export const GET_GOOGLE_TIME_ERROR = 'get_google_time_error';
export const VERIFY_RECAPTCHA = 'verify_recaptcha';
export const VERIFY_RECAPTCHA_SUCCESS = 'verify_recaptcha_success';
export const VERIFY_RECAPTCHA_ERROR = 'verify_recaptcha_error';
export const SAID_PIN_SUBMIT = 'said_pin_submit';
export const SAID_PIN_CAPTURE = 'said_pin_capture';
export const SAID_PIN_ERROR = 'said_pin_error';
export const SAID_PIN_RESET_STATE = 'said_pin_reset_state';
export const OTP_GENERATE = 'otp_generate';
export const OTP_GENERATED = 'otp_generated';
export const OTP_GENERATE_ERROR = 'otp_generate_error';
export const OTP_VERIFY = 'otp_verify';
export const OTP_VERIFIED = 'otp_verified';
export const OTP_VERIFY_ERROR = 'otp_verify_error';
export const OTP_RESET_STATE = 'otp_reset_state';
export const PASSWORD_SUBMIT = 'password_submit';
export const PASSWORD_CAPTURE = 'password_capture';
export const PASSWORD_ERROR = 'password_error';
export const RESET_PASSWORD_SAID_SUBMIT = 'reset_password_said_submit';
export const RESET_PASSWORD_SAID_CAPTURE = 'reset_password_said_capture';
export const RESET_PASSWORD_SAID_ERROR = 'reset_password_said_error';
export const RESET_PASSWORD_SAID_RESET_STATE = 'reset_password_said_reset_state';
export const MCQ_DATA_REQUEST = 'mcq_data_request';
export const MCQ_DATA_REQUEST_CAPTURE = 'mcq_data_request_capture';
export const MCQ_DATA_REQUEST_ERROR = 'mcq_data_request_error';
export const RESET_PASSWORD_SUBMIT = 'reset_password_submit';
export const RESET_PASSWORD_CAPTURE = 'reset_password_capture';
export const RESET_PASSWORD_ERROR = 'reset_password_error';
export const RESET_PIN_SUBMIT = 'reset_pin_submit';
export const RESET_PIN_CAPTURE = 'reset_pin_capture';
export const RESET_PIN_ERROR = 'reset_pin_error';
export const LOGOUT_SUBMIT = 'logout_submit';
export const LOGOUT_CAPTURE = 'logout_capture';
export const LOGOUT_ERROR = 'logout_error';


