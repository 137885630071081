import { LOGOUT_CAPTURE, LOGOUT_ERROR } from '../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case LOGOUT_CAPTURE:
            return { ...state, response: action.payload, error: undefined };
        case LOGOUT_ERROR:
            return { ...state, response: undefined, error: action.error };
        default:
            return state;
    }
}
