import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import * as actions from './actions';
import Modal from '../modal';
import Pin from '../pin';
import Button from '../form-elements/button';
import { pinHasError, showModal, redirect, dataResponse, errorHandling } from '../../utils/utils';
import modalIcon from '../../assets/images/security.png';
import errorIcon from '../../assets/images/error.png';
import withBoldPhoneNumber from '../common/withBoldPhoneNumber';

let attempt = 1;
let errCode = '';
let errors = {};

export class Otp extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: 'verify',
            resendAttempt: 1,
            otp: '',
            icon: '',
            paused: false,
            error: '',
            submit: false,
        };
    }

    componentDidMount() {
        attempt = 1;
        errCode = '';

        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });
    }

    componentWillReceiveProps({ verifyData }) {
        if (verifyData) {
            if (verifyData.status === 200) {
                const response = dataResponse(verifyData.response);

                this.props.closeOtp();

                sessionStorage.removeItem('requestToken');
                sessionStorage.setItem('idNumber', this.props.idNumber);
                sessionStorage.setItem('stepUpToken', response.stepUpToken);

                if (
                    this.context.router.route.location.pathname.indexOf('reset-credentials') !== -1
                ) {
                    redirect(this.context.router, '/reset-credentials/mcq/start');
                } else {
                    redirect(this.context.router, '/password');
                }
            } else {
                errorHandling('');
            }
        }
    }

    componentDidUpdate() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        if (this.state.screen === 'verify' && !this.props.anyTouched) {
            this.otp['input-0'].focus();
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    onResend = () => {
        this.props.reset();

        if (this.state.resendAttempt === 3) {
            this.setState({
                screen: 'resend',
                icon: errorIcon,
            });

            showModal('resendLimitModal');
        } else {
            this.props.setSubmit(true);

            this.props.generateOtp(
                {
                    requestToken: sessionStorage.getItem('requestToken'),
                },
                data => {
                    this.props.setSubmit(false);

                    if (data) {
                        if (data.response) {
                            const response = dataResponse(data.response);

                            const errorCode = response.errors
                                ? response.errors[0].errorCode
                                : response.errorCode;

                            if (errorCode) {
                                if (errorCode === '0407001') {
                                    // cannot generate OTP
                                    this.setState({
                                        screen: 'failOtp',
                                        icon: errorIcon,
                                    });
                                } else {
                                    this.props.genericErrorHandling(errorCode, false);
                                }
                            } else if (data.status === 201) {
                                this.setState({
                                    screen: 'verify',
                                });
                            }
                        } else {
                            this.props.genericErrorHandling('', false);
                        }
                    } else {
                        this.props.genericErrorHandling('', false);
                    }
                },
            );

            this.reset();

            this.setState({
                screen: '',
                resendAttempt: this.state.resendAttempt + 1,
            });
        }
    };

    onCancel = () => {
        this.reset();

        sessionStorage.clear();

        if (this.props.pathname.indexOf('/reset-credentials') !== -1) {
            redirect(this.context.router, '/reset-credentials');
        } else {
            this.props.resetPage();
        }
    };

    onConfirm = args => {
        this.setState({
            submit: true,
            paused: true,
        });
        this.props.setSubmit(true);

        const values = Object.keys(args)
            .map(k => args[k])
            .join('');

        this.props.verifyOtp(
            {
                otpCode: values,
                requestToken: sessionStorage.getItem('requestToken'),
            },
            data => {
                this.setState({ submit: false });
                this.props.setSubmit(false);

                if (data) {
                    if (data.response) {
                        const response = dataResponse(data.response);

                        const errorCode = response.errors
                            ? response.errors[0].errorCode
                            : response.errorCode;

                        if (errorCode) {
                            if (errorCode === '0407002') {
                                // invalid OTP
                                errCode = errorCode;

                                validate(args);

                                this.setState({
                                    error: errors.pin_value,
                                    paused: false,
                                });
                            } else if (errorCode === '0407003') {
                                // OTP expired
                                this.setState({
                                    screen: 'expired',
                                    icon: modalIcon,
                                });
                            } else if (errorCode === '0407005') {
                                // max attempts reached
                                this.setState({
                                    screen: 'verifyFail',
                                    icon: errorIcon,
                                });
                            } else {
                                this.props.genericErrorHandling(errorCode, false);
                            }
                        }
                    } else {
                        this.props.genericErrorHandling('');
                    }
                } else {
                    this.props.genericErrorHandling('');
                }

                this.checkAttempts();
            },
        );
    };

    checkAttempts = () => {
        if (errCode === '0407002' && attempt === 3) {
            errorHandling(errCode);
        } else {
            attempt += 1;
        }
    };

    otpTimeout = () => {
        this.setState({
            screen: 'expired',
            icon: modalIcon,
        });

        showModal('expiredModal');
    };

    handleInputChange = values => {
        if (errCode) {
            errCode = '';
            errors = {};

            this.setState({
                error: '',
            });
        }

        this.setState({
            otp: values,
        });
    };

    reset = () => {
        this.setState({
            resendAttempt: 1,
            otp: '',
            icon: '',
            error: '',
            submit: false,
        });

        attempt = 1;
        errCode = '';

        this.props.resetState();
    };

    id = () => {
        if (this.state.screen === 'verifyFail') {
            return 'verifyFailModal';
        } else if (this.state.screen === 'resend' && this.state.resendAttempt === 3) {
            return 'resendLimitModal';
        } else if (this.state.screen === 'expired') {
            return 'expiredModal';
        } else if (this.state.screen === 'failOtp') {
            return 'failSendOtp';
        }

        return 'verifyModal';
    };

    heading = otpTranslation => {
        if (this.state.screen === 'verifyFail') {
            return otpTranslation.verifyFail.heading;
        } else if (this.state.screen === 'resend' && this.state.resendAttempt === 3) {
            return otpTranslation.resendLimit.heading;
        } else if (this.state.screen === 'expired') {
            return otpTranslation.expired.heading;
        } else if (this.state.screen === 'failOtp') {
            return otpTranslation.sendFail.heading;
        }

        return otpTranslation.verify.heading;
    };

    body = (t, otpTranslation, buttonTranslation) => {
        if (this.state.screen === 'verifyFail') {
            return withBoldPhoneNumber(otpTranslation.verifyFail.body);
        } else if (this.state.screen === 'resend' && this.state.resendAttempt === 3) {
            return withBoldPhoneNumber(otpTranslation.resendLimit.body);
        } else if (this.state.screen === 'expired') {
            return (
                <p>
                    {t(otpTranslation.expired.body, {
                        cellNumber: this.props.generateData
                            ? this.props.generateData.response.deliverTo
                            : '',
                        attempt: this.state.resendAttempt,
                    })}
                </p>
            );
        } else if (this.state.screen === 'failOtp') {
            return withBoldPhoneNumber(otpTranslation.sendFail.body);
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
                <div className="modal-body">
                    <p>
                        {t(otpTranslation.verify.body, {
                            cellNumber: this.props.generateData
                                ? this.props.generateData.response.deliverTo
                                : '',
                        })}
                    </p>
                    <Pin
                        ref={otp => {
                            this.otp = otp;
                        }}
                        type="otp"
                        onChange={this.handleInputChange}
                        error={this.state.error}
                        label={otpTranslation.verify.label}
                    />
                </div>
                <div className="modal-footer flex-container">
                    <Button
                        type="button"
                        className="btn btn-contour secondary"
                        name="cancel"
                        onClick={this.onCancel}
                        data-dismiss="modal"
                        value={buttonTranslation.cancel}
                    />
                    <Button
                        id="otp-confirm"
                        type="submit"
                        className="btn btn-solid primary"
                        name="confirm"
                        btnRef={btn => {
                            this.btn = btn;
                        }}
                        value={buttonTranslation.confirm}
                        disabled={this.state.otp.length < 4 || this.state.submit ? 'disabled' : ''}
                    />
                </div>
            </form>
        );
    };

    footer = buttonTranslation => {
        if (this.state.screen === 'verifyFail') {
            return (
                <div>
                    <Button
                        id={`otp-${this.state.screen}-cancel`}
                        type="button"
                        className="btn btn-solid"
                        name="cancel"
                        onClick={this.onCancel}
                        data-dismiss="modal"
                        value={buttonTranslation.ok}
                    />
                </div>
            );
        } else if (this.state.screen === 'resend' && this.state.resendAttempt === 3) {
            return (
                <div>
                    <Button
                        id={`otp-${this.state.screen}-cancel`}
                        type="button"
                        className="btn btn-solid"
                        name="cancel"
                        onClick={this.onCancel}
                        data-dismiss="modal"
                        value={buttonTranslation.cancel}
                    />
                </div>
            );
        } else if (this.state.screen === 'expired') {
            return (
                <div className="flex-container">
                    <Button
                        type="button"
                        className="btn btn-contour secondary"
                        name="cancel"
                        onClick={this.onCancel}
                        data-dismiss="modal"
                        value={buttonTranslation.cancel}
                    />
                    <Button
                        id={`otp-${this.state.screen}-resend`}
                        type="button"
                        className="btn btn-solid primary"
                        name="resend"
                        onClick={this.onResend}
                        data-dismiss="modal"
                        value={buttonTranslation.resendOtp}
                    />
                </div>
            );
        } else if (this.state.screen === 'failOtp') {
            return (
                <div>
                    <Button
                        id={`otp-${this.state.screen}-ok`}
                        type="button"
                        className="btn btn-solid"
                        name="ok"
                        onClick={this.onCancel}
                        data-dismiss="modal"
                        value={buttonTranslation.ok}
                    />
                </div>
            );
        }

        return null;
    };

    render() {
        const { t } = this.props;
        const otpTranslation = t(translationKeys.otp, { returnObjects: true });
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });
        let timer = null;

        if (!this.state.screen) {
            return null;
        }

        if (this.state.screen === 'verify') {
            timer = {
                time: this.state.changeCell ? 0 : this.props.otpTimer,
                paused: this.state.paused,
                complete: () => this.otpTimeout(),
            };
        }

        return (
            <Modal
                id={this.id()}
                header={{
                    icon: this.state.icon,
                    timer,
                    heading: this.heading(otpTranslation),
                }}
                body={
                    this.state.screen !== 'verify'
                        ? this.body(t, otpTranslation, buttonTranslation)
                        : null
                }
                footer={this.footer(buttonTranslation)}
                custom={
                    this.state.screen === 'verify'
                        ? this.body(t, otpTranslation, buttonTranslation)
                        : null
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        identityData: state.saIdPin.data,
        identityError: state.saIdPin.error,
        generateData: state.otp.generateData,
        generateError: state.otp.generateError,
        verifyData: state.otp.verifyData,
        verifyError: state.otp.verifyError,
    };
}

function validate(formProps) {
    const otpTranslation = i18n.t(translationKeys.otp, { returnObjects: true });

    const values = Object.keys(formProps).map(k => Number(formProps[k]));

    if (values.length < 4) {
        errors = {};
    } else if (errCode === '0407002' && attempt < 3) {
        let attemptText = '';
        switch (attempt) {
            case 1:
                attemptText = '2 attempts';
                break;
            case 2:
                attemptText = '1 attempt';
                break;
            default:
                attemptText = '';
        }
        errors.pin_value = i18n.t(otpTranslation.verify.errors.invalid, { attempt: attemptText });

        pinHasError(formProps, errors);
    }

    return errors;
}

export default reduxForm({
    form: 'otpForm',
})(translate()(connect(mapStateToProps, actions)(Otp)));
