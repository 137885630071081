import {
    SHOW_COMMON_EXCEPTION,
    HIDE_COMMON_EXCEPTION,
    SHOW_BLOCK_FEATURE,
    HIDE_BLOCK_FEATURE,
    SHOW_BLOCK_PROFILE,
    HIDE_BLOCK_PROFILE,
    SHOW_CONFIRM_BEFORE_EXIT,
    HIDE_CONFIRM_BEFORE_EXIT,
    SHOW_TIMEOUT,
    HIDE_TIMEOUT,
    SHOW_BLOCK_CREDIT_CARD_PROFILE,
    HIDE_BLOCK_CREDIT_CARD_PROFILE,
    SHOW_BLOCK_CREDIT_CARD_FEATURE,
    HIDE_BLOCK_CREDIT_CARD_FEATURE,
    SHOW_NOT_CREDIT_CARD_CUSTOMER,
    HIDE_NOT_CREDIT_CARD_CUSTOMER,
    SHOW_NOT_ACTIVE_CREDIT_CARD,
    HIDE_NOT_ACTIVE_CREDIT_CARD,
    SHOW_CREDIT_CARD_NOT_ELIGIBLE,
    HIDE_CREDIT_CARD_NOT_ELIGIBLE,
    SHOW_CONFIRM_PAY_TAX,
    HIDE_CONFIRM_PAY_TAX,
    SHOW_NOT_REMOVE_FICA,
    HIDE_NOT_REMOVE_FICA,
    SHOW_SET_PASSWORD_LATER_MODAL,
    HIDE_SET_PASSWORD_LATER_MODAL,
    SHOW_PASSWORD_TECHNICAL_ERROR,
    HIDE_PASSWORD_TECHNICAL_ERROR,
    SHOW_RETRY_RESULT_MODAL,
    HIDE_RETRY_RESULT_MODAL,
    SHOW_PIN_TECHNICAL_ERROR,
    HIDE_PIN_TECHNICAL_ERROR,
    SHOW_SKIP_MCQ_RESET_PIN_MODAL,
    HIDE_SKIP_MCQ_RESET_PIN_MODAL,
    SHOW_SKIP_MCQ_RESET_PASSWORD_MODAL,
    HIDE_SKIP_MCQ_RESET_PASSWORD_MODAL
} from '../actions/application';

const initialState = {
    isShowCommonException: false,
    isShowBlockFeature: false,
    isShowBlockProfile: false,
    isShowConfirmBeforeExit: false,
    isShowTimeOut: false,
    isShowBlockCreditCardProfile: false,
    isShowNoActiveCreditCard: false,
    isShowBlockCreditCardFeature: false,
    isShowNotCreditCardCustomer: false,
    isShowCreditCardNotEligible: false,
    isShowConfirmPayTax: false,
    isShowNotRemoveFica: false,
    isShowSetPasswordLaterModal: false,
    isShowPasswordTechnicalError: false,
    isShowRetryResult: false,
    isShowPinTechnicalError: false,
    isShowSkipMCQResetPin: false,
    isShowSkipMCQResetPassword: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_COMMON_EXCEPTION:
            return {
                ...state,
                isShowCommonException: true,
            };
        case HIDE_COMMON_EXCEPTION:
            return {
                ...state,
                isShowCommonException: false,
            };
        case SHOW_BLOCK_FEATURE:
            return {
                ...state,
                isShowBlockFeature: true,
            };
        case HIDE_BLOCK_FEATURE:
            return {
                ...state,
                isShowBlockFeature: false,
            };
        case SHOW_BLOCK_PROFILE:
            return {
                ...state,
                isShowBlockProfile: true,
            };
        case HIDE_BLOCK_PROFILE:
            return {
                ...state,
                isShowBlockProfile: false,
            };
        case SHOW_CONFIRM_BEFORE_EXIT:
            return {
                ...state,
                isShowConfirmBeforeExit: true,
            };
        case HIDE_CONFIRM_BEFORE_EXIT:
            return {
                ...state,
                isShowConfirmBeforeExit: false,
            };
        case SHOW_TIMEOUT:
            return {
                ...state,
                isShowTimeOut: true,
            };
        case HIDE_TIMEOUT:
            return {
                ...state,
                isShowTimeOut: false,
            };
        case SHOW_BLOCK_CREDIT_CARD_PROFILE:
            return {
                ...state,
                isShowBlockCreditCardProfile: true,
            };
        case HIDE_BLOCK_CREDIT_CARD_PROFILE:
            return {
                ...state,
                isShowBlockCreditCardProfile: false,
            };
        case SHOW_NOT_ACTIVE_CREDIT_CARD:
            return {
                ...state,
                isShowNoActiveCreditCard: true,
            };
        case HIDE_NOT_ACTIVE_CREDIT_CARD:
            return {
                ...state,
                isShowNoActiveCreditCard: false,
            }
        case SHOW_BLOCK_CREDIT_CARD_FEATURE:
            return {
                ...state,
                isShowBlockCreditCardFeature: true,
            };
        case HIDE_BLOCK_CREDIT_CARD_FEATURE:
            return {
                ...state,
                isShowBlockCreditCardFeature: false,
            };
        case SHOW_NOT_CREDIT_CARD_CUSTOMER:
            return {
                ...state,
                isShowNotCreditCardCustomer: true,
            };
        case HIDE_NOT_CREDIT_CARD_CUSTOMER:
            return {
                ...state,
                isShowNotCreditCardCustomer: false,
            };
        case SHOW_CREDIT_CARD_NOT_ELIGIBLE:
            return {
                ...state,
                isShowCreditCardNotEligible: true,
            };
        case HIDE_CREDIT_CARD_NOT_ELIGIBLE:
            return {
                ...state,
                isShowCreditCardNotEligible: false,
            };
        case SHOW_CONFIRM_PAY_TAX:
            return {
                ...state,
                isShowConfirmPayTax: true,
            };
        case HIDE_CONFIRM_PAY_TAX:
            return {
                ...state,
                isShowConfirmPayTax: false,
            };
        case SHOW_NOT_REMOVE_FICA:
            return {
                ...state,
                isShowNotRemoveFica: true,
            };
        case HIDE_NOT_REMOVE_FICA:
            return {
                ...state,
                isShowNotRemoveFica: false,
            };
        case SHOW_SET_PASSWORD_LATER_MODAL:
            return {
                ...state,
                isShowSetPasswordLaterModal: true,
            };
        case HIDE_SET_PASSWORD_LATER_MODAL:
            return {
                ...state,
                isShowSetPasswordLaterModal: false,
            };
        case SHOW_PASSWORD_TECHNICAL_ERROR:
            return {
                ...state,
                isShowPasswordTechnicalError: true,
            };
        case HIDE_PASSWORD_TECHNICAL_ERROR:
            return {
                ...state,
                isShowPasswordTechnicalError: false,
            };
        case SHOW_RETRY_RESULT_MODAL: 
            return {
                ...state,
                isShowRetryResult: true,
            };
        case HIDE_RETRY_RESULT_MODAL:
            return {
                ...state,
                isShowRetryResult: false,
            };
        case SHOW_PIN_TECHNICAL_ERROR: 
            return {
                ...state,
                isShowPinTechnicalError: true,
            };
        case HIDE_PIN_TECHNICAL_ERROR:
            return {
                ...state,
                isShowPinTechnicalError: false,
            };
        case SHOW_SKIP_MCQ_RESET_PIN_MODAL: 
            return {
                ...state,
                isShowSkipMCQResetPin: true,
            };
        case HIDE_SKIP_MCQ_RESET_PIN_MODAL:
            return {
                ...state,
                isShowSkipMCQResetPin: false,
            };
        case SHOW_SKIP_MCQ_RESET_PASSWORD_MODAL: 
            return {
                ...state,
                isShowSkipMCQResetPassword: true,
            };
        case HIDE_SKIP_MCQ_RESET_PASSWORD_MODAL:
            return {
                ...state,
                isShowSkipMCQResetPassword: false,
            };
        default:
            return state;
    }
};
