import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export class Label extends Component {
    static propTypes = {
        labelClass: PropTypes.string,
        htmlFor: PropTypes.string.isRequired,
        labelText: PropTypes.string.isRequired,
        labelDescription: PropTypes.string,
    };

    static defaultProps = {
        labelClass: '',
        labelDescription: '',
    };

    render() {
        const componentClasses = ['label-container'];
        if (this.props.labelClass) {
            componentClasses.push(this.props.labelClass);
        }

        return (
            <div className={componentClasses.join(' ')}>
                <label
                    {..._.omit(this.props, ['labelClass', 'labelText', 'labelDescription'])}
                    htmlFor={this.props.htmlFor}>
                    {this.props.labelText}
                </label>
                {this.props.labelDescription && <p>{this.props.labelDescription}</p>}
            </div>
        );
    }
}

export default Label;
