import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators';
import {
    CC_SET_CREDENTIAL_PASSWORD,
    ccSetCredentialPasswordSuccessAction
} from '../actions/creditCardSetCredential';
import { encryption } from '../utils/utils';

import {
    showPasswordTechnicalErrorAction
} from '../actions/application';

export const ccSetCredentialObservable = ({
    sessionId,
    credential,
    type,
    time,
    creditCardSetCredentialService,
}) => {
    const encryptedCredential = encryption(credential, time, 'rp');
    return creditCardSetCredentialService({
        sessionId,
        credential: encryptedCredential,
        type,
        time
    }).pipe(
        mergeMap(() => {
            return Observable.of( 
                ccSetCredentialPasswordSuccessAction()
            )
        }),
        catchError(error => {
            const { errors } = error.response.data;
            const errorCode = 
                error.response.data.errorCode || 
                errors && errors[0].errorCode;
            if (error.response && error.response.status !== 401) {
                return Observable.of(
                    showPasswordTechnicalErrorAction(errorCode)
                )
            }
            return Observable.of(showPasswordTechnicalErrorAction(errorCode));
        }),
    );
};

export const ccSetCredentialWithClientTimeObservable = ({
    sessionId,
    credential,
    type,
    creditCardSetCredentialService,
}) => {
    const time = new Date().getTime().toString();
    return ccSetCredentialObservable({
        sessionId,
        credential,
        type,
        time,
        creditCardSetCredentialService,
    });
};

export const setCredentialCCPasswordEpic = (
    action$,
    store,
    { creditCardSetCredentialService, getTimeService, getTimeAccessTokenService },
) =>
    action$.pipe(
        ofType(CC_SET_CREDENTIAL_PASSWORD),
        switchMap(action => {
            const { sessionId, credential, type } = action.payload;
            return getTimeAccessTokenService().pipe(
                concatMap(response => {
                    const timeAccessToken = response.data.access_token;
                    return getTimeService(timeAccessToken).pipe(
                        concatMap(response => {
                            const time = response.data;
                            return ccSetCredentialObservable({
                                sessionId,
                                credential,
                                type,
                                time,
                                creditCardSetCredentialService,
                            });
                        }),
                        catchError(() => {
                            return ccSetCredentialWithClientTimeObservable({
                                sessionId,
                                credential,
                                type,
                                creditCardSetCredentialService,
                            });
                        }),
                    );
                }),
                catchError(() => {
                    return ccSetCredentialWithClientTimeObservable({
                        sessionId,
                        credential,
                        type,
                        creditCardSetCredentialService,
                    });
                }),
            );
        }),
    );
