import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../assets/images/loading.svg';

const InnerLoading = ({ label }) => {
    return (
        <div className="inner-loading-container">
            <img src={Loading} alt="Loading" />
            <p>{label}</p>
        </div>
    );
};
InnerLoading.propTypes = {
    label: PropTypes.string,
};
export default InnerLoading;
