import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import { resetState } from '../../containers/enter-id-pin/actions';
import { resetState as resetOtpState } from '../otp/actions';
import Otp from '../otp';
import Modal from '../modal';
import { modalHeader, modalBody, modalFooter } from '../modal/generic-error';
import Button from '../form-elements/button';
import Loading from '../loading';
import infoIcon from '../../assets/images/info.png';
import errorIcon from '../../assets/images/error.png';
import { Config } from '../../config';
import { redirect, errorHandling} from '../../utils/utils';
import withBoldPhoneNumber from '../common/withBoldPhoneNumber';

export class Modals extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: '',
            errorCode: '',
        };

        this.pathname = '';
    }

    componentDidMount() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        this.pathname = this.context.router.route.location.pathname;
    }

    componentWillReceiveProps({ screen, errorCode }) {
        if (!this.props.anyTouched) {
            this.setState({
                screen,
                errorCode,
            });
        }
    }

    componentDidUpdate() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        if (this.state.errorCode) {
            errorHandling(this.state.errorCode);
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    id = () => {
        if (this.state.screen === 'registered') {
            return 'registeredModal';
        } else if (this.state.screen === 'invalid') {
            return 'invalidModal';
        } else if (this.state.screen === 'loading') {
            return 'loadingModal';
        } else if (this.state.screen === 'failOtp') {
            return 'failSendOtp';
        } else if (this.state.screen === 'invalidSaId') {
            return 'invalidSaId';
        } else if (this.state.screen === 'profileNotFound') {
            return 'profileNotFound';
        }

        return 'errorModal';
    };

    heading = (enterIdPinTranslation, otpTranslation, enterIdTranslation) => {
        if (this.state.screen === 'registered') {
            return enterIdPinTranslation.registered.heading;
        } else if (this.state.screen === 'invalid') {
            return enterIdPinTranslation.invalid.heading;
        } else if (this.state.screen === 'loading') {
            return enterIdPinTranslation.loading.heading;
        } else if (this.state.screen === 'failOtp') {
            return otpTranslation.sendFail.heading;
        } else if (this.state.screen === 'invalidSaId') {
            return enterIdTranslation.invalid.heading;
        } else if (this.state.screen === 'profileNotFound') {
            return enterIdTranslation.profileNotFound.heading;
        }

        return modalHeader();
    };

    body = (enterIdPinTranslation, otpTranslation, enterIdTranslation) => {
        if (this.state.screen === 'registered') {
            return withBoldPhoneNumber(enterIdPinTranslation.registered.body);
        } else if (this.state.screen === 'invalid') {
            return withBoldPhoneNumber(enterIdPinTranslation.invalid.body);
        } else if (this.state.screen === 'loading') {
            return (
                <div>
                    <p>{enterIdPinTranslation.loading.body}</p>
                    <Loading />
                </div>
            );
        } else if (this.state.screen === 'failOtp') {
            return <p>{otpTranslation.sendFail.body}</p>;
        } else if (this.state.screen === 'invalidSaId') {
            withBoldPhoneNumber(enterIdTranslation.invalid.body);
        } else if (this.state.screen === 'profileNotFound') {
            return <p>{enterIdTranslation.profileNotFound.body}</p>;
        }

        return modalBody(this.props.error);
    };

    footer = buttonTranslation => {
        if (this.state.screen === 'registered') {
            return (
                <div>
                    <Link
                        id={`enter-id-pin-${this.state.screen}-login`}
                        className="btn btn-solid"
                        to={Config.loginUrl}
                        onClick={e => {
                            e.preventDefault();
                            window.location = Config.loginUrl;
                        }}>
                        {buttonTranslation.login}
                    </Link>
                </div>
            );
        } else if (this.state.screen === 'invalid') {
            return (
                <div className="btn-container clearfix">
                    <Button
                        id={`enter-id-pin-${this.state.screen}-tryAgain`}
                        type="button"
                        className="btn btn-solid pull-right"
                        name="tryAgain"
                        onClick={() => {
                            this.reset();
                            this.props.resetPage();
                        }}
                        data-dismiss="modal"
                        value={buttonTranslation.tryAgain}
                    />
                    <Link
                        id={`enter-id-pin-${this.state.screen}-register`}
                        className="btn btn-contour pull-right"
                        to={Config.registerUrl}
                        onClick={e => {
                            e.preventDefault();
                            window.location = Config.registerUrl;
                        }}>
                        {buttonTranslation.register}
                    </Link>
                </div>
            );
        } else if (this.state.screen === 'loading') {
            return null;
        } else if (this.state.screen === 'failOtp') {
            return (
                <div>
                    <Button
                        id={`enter-id-pin-${this.state.screen}-ok`}
                        type="button"
                        className="btn btn-solid"
                        name="ok"
                        onClick={() => {
                            sessionStorage.clear();
                            this.props.resetPage();
                            this.reset();
                        }}
                        data-dismiss="modal"
                        value={buttonTranslation.ok}
                    />
                </div>
            );
        } else if (this.state.screen === 'invalidSaId') {
            return (
                <div>
                    <Button
                        id={`enter-id-pin-${this.state.screen}-ok`}
                        type="button"
                        className="btn btn-solid"
                        name="ok"
                        onClick={() => {
                            this.props.resetPage();
                            this.reset();
                        }}
                        data-dismiss="modal"
                        value={buttonTranslation.ok}
                    />
                </div>
            );
        }

        return modalFooter(() => {
            sessionStorage.clear();
            this.pathname.indexOf('reset-credentials') !== -1
                ? redirect(this.context.router, '/reset-credentials')
                : this.props.resetPage();
            this.reset();
        });
    };

    reset = () => {
        if (sessionStorage.getItem('stepUpToken')) {
            sessionStorage.removeItem('stepUpToken');
        }

        this.setState({
            screen: '',
            errorCode: '',
        });

        this.props.resetState();
        this.props.resetOtpState();
    };

    render() {
        const { t } = this.props;
        const enterIdPinTranslation = t(translationKeys.enterIdPin, { returnObjects: true }).modals;
        const otpTranslation = t(translationKeys.otp, { returnObjects: true });
        const enterIdTranslation = t(translationKeys.reset, { returnObjects: true }).enterId.modals;
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });

        if (!this.state.screen) {
            return null;
        }

        if (this.state.screen === 'otp') {
            return (
                <Otp
                    idNumber={this.props.idNumber}
                    otpTimer={this.props.otpTimer}
                    setSubmit={this.props.setSubmit}
                    genericErrorHandling={(errorCode, show) =>
                        this.props.genericErrorHandling(errorCode, show)
                    }
                    closeOtp={() => this.props.closeOtp()}
                    resetPage={() => this.props.resetPage()}
                    pathname={this.pathname}
                />
            );
        }

        return (
            <Modal
                id={this.id()}
                header={{
                    icon: this.state.screen === 'loading' ? infoIcon : errorIcon,
                    heading: this.heading(
                        enterIdPinTranslation,
                        otpTranslation,
                        enterIdTranslation,
                    ),
                }}
                body={this.body(enterIdPinTranslation, otpTranslation, enterIdTranslation)}
                footer={this.footer(buttonTranslation)}
            />
        );
    }
}

export default translate()(connect(null, { resetState, resetOtpState })(Modals));
