import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Recaptcha from 'react-google-invisible-recaptcha';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import Index from '../../layouts';
import Button from '../../components/form-elements/button';
import Modal from '../../components/modal';
import ConnectionTimeoutModal from '../../components/modal/connection-timeout';
import Loading from '../../components/loading';
import { modalHeader, modalBody, modalFooter } from '../../components/modal/generic-error';
import { resetAllState, getAccessToken } from '../../actions';
import { verifyRecaptcha } from '../recaptcha/actions';
import { redirect, dataResponse, errorHandling } from '../../utils/utils';
import { Config } from '../../config';
import { SESSION_STORAGE_ITEMS, RESET_CREDENTIAL_TYPE } from '../../constants';
import headerImage from '../../assets/images/header-1.png';
import errorIcon from '../../assets/images/error.png';

const isTurnOnResetPin = Config.resetPinToggle === 'on';
const isTurnOnIBActivationURL = Config.ibActivationURLToggle === 'on';

const i18nInfo = i18n.t(translationKeys.reset, { returnObjects: true })
const copy = isTurnOnResetPin ? i18nInfo.information : i18nInfo.informationWithoutPIN;

export class ResetInformation extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: '',
            submit: false,
        };
    }

    componentDidMount() {
        document.title = Config.pageTitle.reset;
        document.body.className = 'reset-information';
        window.scrollTo(0, 0);

        this.props.resetAllState();

        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        if (sessionStorage.getItem('accessToken')) {
            sessionStorage.clear();
        }
    }

    componentWillReceiveProps({ recaptcha }) {
        if (recaptcha) {
            if (recaptcha.data) {
                if (recaptcha.data.token && Object.keys(recaptcha.data.token).length > 0) {
                    sessionStorage.setItem('accessToken', recaptcha.data.token.access_token);
                    const resetCredentialType = sessionStorage.getItem(
                        SESSION_STORAGE_ITEMS.RESET_CREDENTIAL_TYPE,
                    );
                    const isResetPin =
                        resetCredentialType && resetCredentialType === RESET_CREDENTIAL_TYPE.PIN;
                    const isResetPassword =
                        resetCredentialType && resetCredentialType === RESET_CREDENTIAL_TYPE.PASSWORD;
                    if (isResetPin) {
                        redirect(this.context.router, '/reset-credentials/resetPin');
                    }
                    if (isResetPassword) {
                        redirect(this.context.router, '/reset-credentials/resetPassword');
                    }
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        }
    }

    componentDidUpdate() {
        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });

        if (this.state.submit) {
            if (!this.props.data) {
                errorHandling('');
            }
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    onResolved = () => {
        this.props.verifyRecaptcha({ response: this.recaptcha.getResponse() }, data => {
            this.setState({ submit: false });

            if (data) {
                if (!data.success || !data.token || Object.keys(data.token).length < 1) {
                    this.genericErrorHandling('', false);
                }
            } else {
                this.genericErrorHandling('', false);
            }
        });
    };

    submitResetExecute = () => {
        this.setState({
            submit: true,
            screen: '',
        });

        this.recaptcha.reset();
        this.recaptcha.execute();
    };

    toEnterId = () => {
        sessionStorage.setItem(
            SESSION_STORAGE_ITEMS.RESET_CREDENTIAL_TYPE,
            RESET_CREDENTIAL_TYPE.PASSWORD,
        );
        this.submitResetExecute();
    };

    toEnterPin = () => {
        sessionStorage.setItem(
            SESSION_STORAGE_ITEMS.RESET_CREDENTIAL_TYPE,
            RESET_CREDENTIAL_TYPE.PIN,
        );
        this.submitResetExecute();
    };

    genericErrorHandling = (errorCode = '', show = true) => {
        errorHandling(errorCode, show, screen => {
            this.setState({
                screen,
            });
        });
    };

    assignErrorObj = error => {
        if (error) {
            if (error.response) {
                const response = dataResponse(error.response);

                if (response.access_token) {
                    return null;
                }

                return response;
            }

            return {
                'Status code': error.status,
                'Status text': error.statusText,
            };
        }

        return null;
    };

    render() {
        const { t } = this.props;
        const buttonTranslation = t(translationKeys.button, { returnObjects: true });

        let errorObj = null;

        if (this.props.error) {
            errorObj = this.assignErrorObj(this.props.error);
        } else if (this.props.data) {
            errorObj = this.assignErrorObj(this.props.data);
        }

        return (
            <Index
                header={{
                    image: headerImage,
                    heading: copy.heading,
                    description: copy.description,
                }}
                footer={{
                    content: true,
                }}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <p className="bodyCopy" style={{ marginBottom: '.5rem' }}>{copy.body.copy}</p>
                            <ul style={{ paddingLeft: '16px' }}>
                                <li style={{ marginBottom: '.5rem' }}>{copy.body.handy.said}</li>
                                <li style={{ marginBottom: '.5rem' }}>{copy.body.handy.cell}</li>
                                <li style={{ marginBottom: '.5rem' }}>Your TymeBank debit card</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="buttonWrapper" style={{marginTop: 0}}>
                                <Button
                                    id="reset-credentials-reset"
                                    type="button"
                                    className="btn btn-contour resetPassword"
                                    name="reset"
                                    value={buttonTranslation.resetPassword}
                                    onClick={this.toEnterId}
                                />
                                {isTurnOnResetPin ? (
                                    <Button
                                        id="reset-credentials-resetPin"
                                        type="button"
                                        className="btn btn-contour"
                                        name="resetPin"
                                        onClick={this.toEnterPin}
                                        value={buttonTranslation.resetPin}
                                    />
                                ) : null}
                            </div>

                            <div style={{marginTop: '4rem'}}>
                                {!isTurnOnResetPin ? (
                                    <p style={{fontStyle: 'italic', fontSize: '14px', fontWeight: 400, marginBottom: 0}}>
                                        *Did you forget your login PIN? You can reset it on the
                                        TymeBank App or your nearest Kiosk.
                                    </p>
                                ) : null}
                                {isTurnOnIBActivationURL ? (
                                    <p style={{fontStyle: "italic", fontSize: "14px", fontWeight: 400}}>
                                        *Already TymeBank customer but new to Internet Banking? Let's activate your Internet Banking profile! &nbsp;
                                        <a className="hover-no-underline" style={{fontWeight: 500, fontStyle: 'normal'}} href="/">
                                            <span style={{fontSize: '2rem', color: '#337ab7'}}>→</span> CLICK HERE
                                        </a>
                                    </p>
                                ) : null}
                            </div>

                            <Recaptcha
                                ref={recaptcha => {
                                    this.recaptcha = recaptcha;
                                }}
                                sitekey={Config.googleRecaptcha.sitekey}
                                onResolved={this.onResolved}
                            />
                        </div>
                    </div>
                </div>
                <ConnectionTimeoutModal />
                {this.state.screen === 'error' && (
                    <Modal
                        id="errorModal"
                        header={{
                            icon: errorIcon,
                            heading: modalHeader(),
                        }}
                        body={modalBody(errorObj)}
                        footer={modalFooter()}
                    />
                )}
                {this.state.submit && <Loading />}
            </Index>
        );
    }
}

function mapStateToProps(state) {
    return {
        recaptcha: state.recaptcha,
    };
}

export default translate()(
    connect(mapStateToProps, { resetAllState, verifyRecaptcha, getAccessToken })(ResetInformation),
);
