import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderIcon from '../header-icon';

export class Modal extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired,
        body: PropTypes.object,
        footer: PropTypes.object,
        custom: PropTypes.object,
    };

    render() {
        const { header, body, footer, custom } = this.props;

        const iconProps = header.timer
            ? {
                  timer: header.timer,
                  time: header.timer.time,
                  complete: header.timer.complete,
              }
            : {
                  icon: header.icon,
                  heading: header.heading,
              };

        let finder = 'modal';

        if (this.props.id !== 'reachedTimeout') {
            sessionStorage.setItem('modal', this.props.id);
            finder = 'modal finder';
        }

        return (
            <div id={this.props.id} className={finder} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="row">
                            <div className="col-md-12">
                                {header && (
                                    <div className="modal-header">
                                        <div className="row">
                                            <div className="col-md-2 col-sm-2">
                                                <HeaderIcon {...iconProps} />
                                            </div>
                                            <div className="col-md-10 col-sm-10">
                                                <h2>{header.heading}</h2>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {body && !custom && <div className="modal-body">{body}</div>}
                                {footer && !custom && <div className="modal-footer">{footer}</div>}
                                {custom}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
