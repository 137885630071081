import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getResetPinShowSteps, validatePassword } from '../../utils/utils';
import { usePageTitle } from '../../utils/useHooks';
import { RESET_STEPS } from '../../constants';
import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';
import StepBar from '../../components/common/StepBar';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import headerImage from '../../assets/images/header-12.png';
import TymeInputPassword from '../../components/tyme-input/TymeInputPassword';
import PasswordCheck from '../../components/password-check/PasswordCheck';

const StepReset = ({ submitting = false, onCancel, onSubmit }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorInput, setErrorInput] = useState(null);
    const [errorConfirm, setErrorConfirm] = useState(null);

    usePageTitle('Reset Password | TymeBank');

    useEffect(() => {
        setErrorInput(null);
        setErrorConfirm(null);
    }, [password, confirmPassword]);

    const validate = () => {
        const validatePasswordResult = validatePassword(password);
        if (validatePasswordResult !== '') {
            return validatePasswordResult;
        }
        if (password !== confirmPassword) {
            return 'Your confirm password does not match your password';
        }
        return '';
    };

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };

    const handleConfirm = () => {
        const validateResult = validate();
        if (validateResult === '') {
            setErrorInput(null);
            setErrorConfirm(null);
            onSubmit(password);
        } else if (validateResult === 'Not match') {
            setErrorInput(null);
            setErrorConfirm({ message: validateResult });
        } else {
            setErrorInput({ message: validateResult });
            setErrorConfirm(null);
        }
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Create your new Internet Banking password</p>
                <p className="mb-0">Now enter and confirm your new Internet Banking password.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <StepBar
                                label={RESET_STEPS.reset.title}
                                currentStep={RESET_STEPS.reset.stepId}
                                totalStep={getResetPinShowSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-reset resetPassword step-reset-container">
                                <div className="step-reset__heading">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <p className="mb-0">
                                                Your password will be used to login to Internet
                                                Banking.
                                            </p>
                                            <p>Don't share this password with anyone.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="step-reset__main">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6 col-md-5">
                                            <TymeInputPassword
                                                label="Password"
                                                placeholder="Enter your new password"
                                                value={password}
                                                autoComplete="off"
                                                autoFocus={true}
                                                handleChange={handlePasswordChange}
                                                error={errorInput}
                                            />
                                            <TymeInputPassword
                                                label="Confirm password"
                                                placeholder="Re-enter your new password"
                                                value={confirmPassword}
                                                handleChange={handleConfirmPasswordChange}
                                                error={errorConfirm}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-offset-1 col-md-6 check-list">
                                            <PasswordCheck input={password} />
                                        </div>
                                    </div>
                                </div>
                                <div className="step-reset__footer">
                                    <div className="btn-control-container">
                                        <TymeButton
                                            className="btn tyme-btn btn-secondary"
                                            disabled={submitting}
                                            onClick={onCancel}
                                        >
                                            Cancel
                                        </TymeButton>
                                        <TymeButton
                                            disabled={submitting}
                                            isLoading={submitting}
                                            onClick={handleConfirm}
                                        >
                                            Confirm
                                        </TymeButton>
                                    </div>
                                </div>
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepReset.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default StepReset;
