import React from 'react';
import PropTypes from 'prop-types';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../../components/common/TymeButton';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE} from '../../constants';

export const CardVerifyAttemptModal = ({ isShow = false, onHide, onSubmit }) => {
    return (
        <ModalTemplate
            title="Too many unsuccessful attempts"
            type={MODAL_TYPE.ERROR}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p>
                    Due to too many unsuccessful attempts, we've blocked your Internet Banking,
                    Smart App, Kiosk, and USSD access.
                </p>
                <p>
                    Please try again in 24 hours or contact Customer Service Centre on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span> to unlock your
                    account.
                </p>
                <div className="btn-control-container">
                    <TymeButton onClick={onSubmit}>OK</TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

CardVerifyAttemptModal.propTypes = {
    isShow: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CardVerifyAttemptModal;
