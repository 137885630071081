import React from 'react';
import Page from './Page';
import Header from './Header';
import TymeButton from '../common/TymeButton';
import headerImageStart from '../../assets/images/header-4.png';

export const AppCrash = () => {
    const handleClick = () => {
        window.location.href = '/';
    };

    return (
        <Page>
            <Header image={headerImageStart}>
                <p className="heading">Oops! Something went wrong</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>Please come back later!</h1>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="btn-control-container">
                                <TymeButton onClick={handleClick}>Back to Home</TymeButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default AppCrash;
