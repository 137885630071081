import React from 'react';

const InputOTPItem = React.forwardRef(({ inputValue = '', onChange, ...rest }, ref) => {
    return (
        <div className="input-otp-item-container">
            <input
                className="input-control"
                type={inputValue ? 'password' : 'tel'}
                maxLength="1"
                value={inputValue}
                onChange={onChange}
                onFocus={e => e.target.select()}
                ref={ref}
                {...rest}
            />
        </div>
    );
});

export default InputOTPItem;
