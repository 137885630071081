import React, { useEffect, useState, useRef } from 'react';
import { $off, $on } from 'event-bus-e2z'; // eslint-disable-line

import { formatNumberOnly } from './utils';
import { Config } from '../config';

export const useError = initialErrors => {
    const [errors, setErrors] = useState(null);
    useEffect(() => {
        setErrors(initialErrors);
    }, [initialErrors]);
    return [errors, setErrors];
};

export const usePageTitle = pageTitle => {
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);
};

export const useScrollTop = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    }, []);
};

export const useInputVerificationCode = (inputArray, onChange) => {
    const refs = useRef(Array.from({ length: inputArray.length }));

    const handleChange = (id, value, isPL) => {
        let nVal = "";
        if (isPL) {
            if (/^[A-Za-z0-9]$/.test(value)) {
                nVal = value
            }
        } else {
            nVal = formatNumberOnly(value);
        }
        // const numberOnly = formatNumberOnly(value);
        const inputtingIndex = inputArray.findIndex(input => input.id === id);
        const newInputArray = [
            ...inputArray.slice(0, inputtingIndex),
            { id, value: nVal || '' },
            ...inputArray.slice(inputtingIndex + 1, inputArray.length),
        ];
        onChange(newInputArray);
        id <= inputArray.length - 2 && nVal && refs.current[id + 1].focus();
    };

    return [refs, handleChange];
};

export const useBodyClass = bobyClass => {
    useEffect(() => {
        document.body.className = bobyClass
    }, [bobyClass]);
}

export const useRunAfterUpdate = () => {
    const afterPaintRef = useRef(null);
    React.useLayoutEffect(() => {
        if (afterPaintRef.current) {
            afterPaintRef.current();
            afterPaintRef.current = null;
        }
    });
    return fn => (afterPaintRef.current = fn); // eslint-disable-line
};

export const isPersonalLoan = () => {
    const isPL = window.location &&
        window.location.pathname.includes(Config.appRoutes.PL_START);
    return isPL;
}

export const useEventBus = (name, callback) => {
    useEffect(() => {
        $on(name, callback)
        // eslint-disable-next-line func-names
        return function () {
            $off(name, callback)
        }
    }, [name, callback])
}

export default {
    useError,
    usePageTitle,
    useInputVerificationCode,
    useRunAfterUpdate,
    useScrollTop,
    useBodyClass,
    useEventBus
};
