import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { redirect } from '../../utils/utils';

export default function(ComposedComponent) {
    class Authorisation extends Component {
        static contextTypes = {
            router: PropTypes.object,
        };

        componentWillMount() {
            if (!this.props.authorized) {
                if (this.props.location.pathname.indexOf('reset-credentials') !== -1) {
                    redirect(this.context.router, '/reset-credentials');
                } else {
                    redirect(this.context.router, '/');
                }
            }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.authorized) {
                if (this.props.location.pathname.indexOf('reset-credentials') !== -1) {
                    redirect(this.context.router, '/reset-credentials');
                } else {
                    redirect(this.context.router, '/');
                }
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { authorized: state.recaptcha.data };
    }

    return connect(mapStateToProps)(Authorisation);
}
