import {
    VERIFY_RECAPTCHA,
    VERIFY_RECAPTCHA_SUCCESS,
    VERIFY_RECAPTCHA_FAILED,
} from '../actions/recaptcha';

import {
    SHOW_COMMON_EXCEPTION
} from '../actions/application';

const initialState = {
    isVerifyRecaptcha: false,
    success: false,
    failed: false,
    token: '',
    loading: false,
    isCreditCardEnable: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_RECAPTCHA:
            return {
                ...state,
                isVerifyRecaptcha: true,
                loading: true
            };
        case VERIFY_RECAPTCHA_SUCCESS:
            const { access_token } = action.payload; // eslint-disable-line
            return {
                ...state,
                isVerifyRecaptcha: false,
                token: access_token,
                loading: false,
                success: true,
                isCreditCardEnable: true,
            };
        case VERIFY_RECAPTCHA_FAILED:
            return {
                ...state,
                isVerifyRecaptcha: false,
                failed: true,
                loading: false
            };

        case SHOW_COMMON_EXCEPTION:
            return {
                loading: false,
                failed: true,
            }
        default:
            return state;
    }
};
