import React, { Fragment } from 'react';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import Button from '../form-elements/button';
import withBoldPhoneNumber from '../common/withBoldPhoneNumber';

const errorTranslation = i18n.t(translationKeys.errors, { returnObjects: true }).generic;
const buttonTranslation = i18n.t(translationKeys.button, { returnObjects: true });

export const modalHeader = (_error, isCustomMessage = false) =>
    isCustomMessage && _error ? _error : errorTranslation.heading;

export const modalBody = (_error, isCustomMessage = false) => {
    if (isCustomMessage && _error) return <p>{_error}</p>;
    return (
        <Fragment>
            {withBoldPhoneNumber(errorTranslation.body)}
            <p>
                {_error && (_error.errors || _error.errorCode) && (
                    <span className="error">
                        <br />
                        {errorTranslation.errorCodeLabel}
                        {i18n.t(errorTranslation.errorCode, {
                            errorCode: _error.errors
                                ? _error.errors[0].errorCode
                                : _error.errorCode,
                        })}
                    </span>
                )}
                {_error && !_error.errors && !_error.errorCode && (
                    <span className="error">
                        <br />
                        {typeof _error === 'object'
                            ? Object.keys(_error).map(key => {
                                  if (key.toLowerCase().indexOf('code') !== -1) {
                                      return `${key}: ${_error[key]}\r\n`;
                                  }

                                  return null;
                              })
                            : _error}
                    </span>
                )}
            </p>
        </Fragment>
    );
};

export const modalFooter = (_onClick = null) => (
    <div>
        <Button
            id="generic-error-ok"
            type="button"
            className="btn btn-solid"
            name="ok"
            onClick={_onClick}
            data-dismiss="modal"
            value={buttonTranslation.ok}
        />
    </div>
);
