import React from 'react';
import iconSuccess from '../../assets/images/success.png';
import iconCCSuccess from '../../assets/images/icon-cc-pass-success.svg';

export const PasswordCheckItem = ({ text, isFulfill = false, isCC }) => {
    return (
        <div className={`check-list__item ${isFulfill ? '' : 'not-fulfill'}`}>
            {
                isCC ? (
                    <img className="check" src={iconCCSuccess} alt="icon success" />
                ) : 
                (
                    <img className="check" src={iconSuccess} alt="icon success" />
                )
            }
            {text}
        </div>
    );
};

export default PasswordCheckItem;
