import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import {
    RESET_STATE,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_GOOGLE_TIME_SUCCESS,
    GET_GOOGLE_TIME_ERROR,
} from '../actions/types';

import recaptchaReducer from '../containers/recaptcha/reducers';
import saIdPinReducer from '../containers/enter-id-pin/reducers';
import otpReducer from '../components/otp/reducer';
import passwordReducer from '../containers/password/reducer';
import logoutReducer from '../containers/password/logout-reducer';
import searchSaIdReducer from '../containers/reset-credentials/enter-id/reducers';
import mcqReducer from '../containers/reset-credentials/mcq/reducers';
import resetPasswordReducer from '../containers/reset-credentials/password/reducer';
import resetPinReducer from '../containers/reset-credentials/pin/reducer';
import applicationReducer from './applicationReducer';

import newResetPinReducer from './resetPinReducer';
import searchSaidReducer from './searchSaidReducer';
import newOtpReducer from './otpReducer';
import checkEligibilityReducer from './checkEligibilityReducer';
import cardPanReducer from './cardPanReducer';
import setCredentialReducer from './setCredentialReducer';
import newMcqReducer from './mcqReducer';
import reCaptChaCCReducer from './reCaptChaCCReducer';
import searchCCSaidReducer from './searchCCSaidReducer';
import creditCardActivationReducer from './creditCardActivationReducer';
import captureTCReducer from './captureTCReducer';
import creditCardPanReducer from './creditCardPanReducer';
import captureTaxReducer from './captureTaxReducer';
import setCredentialCCPinReducer from './setCredentialCCPinReducer';
import setCredentialCCPasswordReducer from './setCredentialCCPasswordReducer';

const accessTokenReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ACCESS_TOKEN_SUCCESS:
            return { ...state, data: action.payload, error: undefined };
        case GET_ACCESS_TOKEN_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
};
const googleTimeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_GOOGLE_TIME_SUCCESS:
            return { ...state, data: action.payload, error: undefined };
        case GET_GOOGLE_TIME_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
};

const appReducer = combineReducers({
    form,
    recaptcha: recaptchaReducer,
    token: accessTokenReducer,
    time: googleTimeReducer,
    saIdPin: saIdPinReducer,
    otp: otpReducer,
    password: passwordReducer,
    saId: searchSaIdReducer,
    mcq: mcqReducer,
    resetPassword: resetPasswordReducer,
    resetPin: resetPinReducer,
    logout: logoutReducer,

    application: applicationReducer,
    newResetPin: newResetPinReducer,
    searchSaid: searchSaidReducer,
    newOtp: newOtpReducer,
    checkEligibility: checkEligibilityReducer,
    cardPan: cardPanReducer,
    setCredential: setCredentialReducer,
    newMcq: newMcqReducer,
    newReCaptcha: reCaptChaCCReducer,
    searchCCSaid: searchCCSaidReducer,
    creditCardActivation: creditCardActivationReducer,
    captureTC: captureTCReducer,
    creditCardPan: creditCardPanReducer,
    captureTax: captureTaxReducer,
    setCredentialCCPin: setCredentialCCPinReducer,
    setCredentialCCPassword: setCredentialCCPasswordReducer,
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
