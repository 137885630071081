import React from 'react'
import PropTypes from 'prop-types'

import NaturePurposeCheckbox from './NaturePurposeCheckbox'

const NaturePurposeBox = ({
    title,
    subTitle,
    name,
    listItem = [],
    selection = [],
    handleChange,
}) => {
    const checkChange = ({ value, checked }) => {
        if (!checked) {
            handleChange([...selection].filter(item => item !== value));
            return;
        }
        handleChange([...selection, value]);
    }

    return (
        <div className="col-xs-12 col-md-5">
            <div className="nature-purpose_label">
                <h4>{title}</h4>
                <div>{subTitle}</div>
            </div>

            <div className="nature-purpose_options">
                {listItem.map(item => {
                    return <NaturePurposeCheckbox
                        key={item.code}
                        name={name}
                        label={item.value}
                        value={item.code}
                        checked={selection.includes(item.code)}
                        handleChange={checkChange}
                    />;
                })}
            </div>
        </div>
    )
}

NaturePurposeBox.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    name: PropTypes.string,
    listItem: PropTypes.array,
    selection: PropTypes.array,
    handleChange: PropTypes.func,
};

export default NaturePurposeBox


