import axios from 'axios';
import { SESSION_STORAGE_KEY } from './constants';
import { getUuid } from './utils/utils';
import { fetchAccessToken } from './api';

function requestInterceptor(requestConfig) {
    const accessToken = sessionStorage.getItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
    requestConfig.headers = {
        Timestamp: new Date().toISOString(),
        Channel: 'Channel.Web',
        'Caller-Id': 'Channel.Web',
        'Device-Info': btoa(
            JSON.stringify({
                device_name: window.navigator.userAgent,
                device_os: '',
                device_id: '',
                sim_info: [],
                mac: '',
                geolocation: '',
            }),
        ),
        Authorization: `Bearer ${accessToken}`,
        'Correlation-Id': getUuid(),
        ...requestConfig.headers,
    };
    return requestConfig;
}

function responseInterceptor(response) {
    return response;
}

function responseErrorInterceptor(error) {
    const originalRequest = error.config;
    const isRetried = originalRequest.retry;
    const responseStatus = error.response.status;
    // 401: unauthorized or token expired
    if (responseStatus === 401 && !isRetried) {
        originalRequest.retry = true;
        return fetchAccessToken().then(response => {
            const accessToken = response.data.access_token;
            if (accessToken) {
                sessionStorage.setItem(SESSION_STORAGE_KEY.ACCESS_TOKEN, accessToken);

                // update accessToken for current request
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                // continue current request
                return axios(originalRequest);
            }
            return Promise.reject(error);
        });
    }
    return Promise.reject(error);
}

export default function setup() {
    return {
        request: axios.interceptors.request.use(requestInterceptor),
        response: axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor),
    };
}
