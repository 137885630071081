import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as actions from './actions';
import { getAccessToken, getGoogleTime } from '../../actions';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import Index from '../../layouts';
import headerImage from '../../assets/images/header-12.png';
import Modals from '../../components/password/modals';
import Loading from '../../components/loading';
import {
    showModal,
    dataResponse,
    encryption,
    errorHandling,
    validatePassword,
} from '../../utils/utils';
import Timeout from '../../components/common/TimeOut';
import { Config } from '../../config';
import ConnectionTimeoutModal from '../../components/modal/connection-timeout';
import PasswordCheck from '../../components/password-check/PasswordCheck';
import TymeInputPassword from '../../components/tyme-input/TymeInputPassword';
import TymeButton from '../../components/common/TymeButton';

const copy = i18n.t(translationKeys.password, { returnObjects: true });
let tokenRetry = 1;

export class Password extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            screen: '',
            errorCode: '',
            password: '',
            confirmPassword: '',
            submit: false,
            time: null,
            recallFunction: '',
            errorInput: null,
            errorConfirm: null,
        };
    }

    componentDidMount() {
        document.title = Config.pageTitle.password;
        document.body.className = 'password';
        window.scrollTo(0, 0);

        tokenRetry = 1;

        window.$('.modal').modal({
            backdrop: 'static',
            show: false,
        });
    }

    componentWillReceiveProps({ data, error, timeData, timeError, tokenData, tokenError }) {
        if (data) {
            this.logout();

            if (data.status === 204) {
                sessionStorage.clear();

                showModal('successModal');
            } else {
                errorHandling('');
            }
        }

        if (error) {
            this.logout();

            if (error.response) {
                const response = dataResponse(error.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                if (errorCode) {
                    errorHandling(errorCode);
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        }

        if (
            error === null ||
            data === null ||
            tokenData === null ||
            tokenError === null ||
            timeData === null ||
            timeError === null
        ) {
            errorHandling('');
        }

        if (timeError) {
            if (timeError.status === 401) {
                this.tokenResponseHandling(tokenData, tokenError, this.state.recallFunction);
            } else if (timeError.response) {
                const response = dataResponse(timeError.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                if (errorCode) {
                    errorHandling(errorCode);
                }
            } else {
                errorHandling('');
            }
        }
    }

    componentWillUnmount() {
        window.$('.modal').off();
        window.$('.modal').modal('hide');
    }

    getTokenAttempts = type => {
        if (tokenRetry === 3) {
            errorHandling('');
        } else {
            tokenRetry += 1;
            this.refetchAccessToken(type);
        }
    };

    getGoogleTime = password => {
        this.setState({ submit: true });

        tokenRetry = 1;

        this.props.getGoogleTime(data => {
            this.setState({ submit: false });

            if (data) {
                if (data.status === 401) {
                    this.refetchAccessToken('time');

                    this.setState({ recallFunction: 'time' });
                } else if (data.xhr) {
                    const response = dataResponse(data.xhr.response);

                    this.setState({ time: response });

                    this.submitPassword(password);
                } else if (data.response) {
                    const response = dataResponse(data.response);

                    const errorCode = response.errors
                        ? response.errors[0].errorCode
                        : response.errorCode;

                    if (errorCode) {
                        this.genericErrorHandling(errorCode, false);
                    }
                } else if (data.status === 0) {
                    showModal('connectionTimeout');
                } else {
                    this.genericErrorHandling('', false);
                }
            } else {
                this.genericErrorHandling('', false);
            }
        }, 'time');
    };

    logout = () => {
        const payload = {
            idToken: sessionStorage.getItem('idToken'),
            cookies: JSON.parse(sessionStorage.getItem('cookies')),
        };

        if (
            sessionStorage.getItem('idToken') &&
            sessionStorage.getItem('cookies') &&
            sessionStorage.getItem('cookies') !== 'undefined'
        ) {
            sessionStorage.setItem('ApiCall', true);
            this.props.activationLogout(payload, () => {
                this.setState({ submit: false });
            });
        }
    };

    submitPassword = password => {
        this.setState({ submit: true });

        tokenRetry = 1;

        const time = this.state.time ? this.state.time : new Date().getTime().toString();

        const encrypted = encryption(password, time);

        sessionStorage.setItem('ApiCall', true);
        this.props.submitPassword(
            {
                password: encrypted,
            },
            data => {
                this.setState({ submit: false });

                if (data) {
                    if (data.response) {
                        const response = dataResponse(data.response);

                        const errorCode = response.errors
                            ? response.errors[0].errorCode
                            : response.errorCode;

                        if (errorCode) {
                            this.genericErrorHandling(errorCode, false);
                        } else {
                            this.genericErrorHandling('', false);
                        }
                    } else if (data.status === 0) {
                        showModal('connectionTimeout');
                    } else if (data.status === 204) {
                        this.setState({ screen: 'success' });
                    } else {
                        this.genericErrorHandling('', false);
                    }
                } else {
                    this.genericErrorHandling('', false);
                }
            },
        );
    };

    refetchAccessToken = (type = 'token') => {
        this.setState({ submit: true });

        this.props.getAccessToken(
            null,
            tokenData => {
                this.setState({ submit: false });

                if (tokenData) {
                    if (tokenData.response) {
                        const response = dataResponse(tokenData.response);

                        if (!response.access_token) {
                            this.getTokenAttempts(type);
                        }
                    } else {
                        this.getTokenAttempts(type);
                    }
                } else {
                    this.getTokenAttempts(type);
                }
            },
            type,
        );
    };

    tokenResponseHandling = (tokenData, tokenError, recallFunction) => {
        if (tokenData) {
            if (tokenData.response) {
                const response = dataResponse(tokenData.response);

                if (response.access_token) {
                    if (tokenData.type === 'time') {
                        sessionStorage.setItem('timeAccessToken', response.access_token);
                    }

                    if (recallFunction === 'time') {
                        this.getGoogleTime(this.state.confirmPassword);
                    }
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        } else if (tokenError) {
            if (tokenData.response) {
                const response = dataResponse(tokenError.response);

                const errorCode = response.errors
                    ? response.errors[0].errorCode
                    : response.errorCode;

                if (errorCode) {
                    errorHandling(errorCode);
                } else {
                    errorHandling('');
                }
            } else {
                errorHandling('');
            }
        } else {
            errorHandling('');
        }
    };

    formSubmit = () => {
        this.getGoogleTime(this.state.password);
    };

    genericErrorHandling = (errorCode = '', show = true) => {
        errorHandling(errorCode, show, screen => {
            this.setState({
                screen,
                errorCode,
            });
        });
    };

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    validate = () => {
        const { password, confirmPassword } = this.state;

        const validatePasswordResult = validatePassword(password);
        if (validatePasswordResult !== '') {
            return validatePasswordResult;
        }
        if (password !== confirmPassword) {
            return 'Your confirm password does not match your password';
        }
        return '';
    };

    handleConfirm = () => {
        const validateResult = this.validate();
        if (validateResult === '') {
            this.setState(prevState => ({
                ...prevState,
                errorInput: null,
                errorConfirm: null,
            }));
            this.formSubmit();
        } else if (validateResult === 'Not match') {
            this.setState(prevState => ({
                ...prevState,
                errorInput: null,
                errorConfirm: { message: validateResult },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                errorInput: { message: validateResult },
                errorConfirm: null,
            }));
        }
    };

    render() {
        let errorObj = null;
        const {
            errorInput,
            errorConfirm,
            password,
            confirmPassword,
            submit,
            screen,
            errorCode,
        } = this.state;

        if (this.props.error) {
            if (this.props.error.response) {
                errorObj = dataResponse(this.props.error.response);
            } else {
                errorObj = {
                    'Status code': this.props.error.status,
                    'Status text': this.props.error.statusText,
                };
            }
        }

        return (
            <Index
                header={{
                    image: headerImage,
                    heading: copy.heading,
                    description: copy.description,
                }}
                footer={{
                    content: true,
                }}
            >
                <div className="col-md-12">
                    <p>
                        <strong>{copy.body.bold}</strong> {copy.body.normal}
                    </p>

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-5">
                            <TymeInputPassword
                                label="Password"
                                placeholder="Enter your password"
                                value={password}
                                autoComplete="off"
                                autoFocus={true}
                                handleChange={this.handleInputChange}
                                error={errorInput}
                                name="password"
                            />
                            <TymeInputPassword
                                label="Confirm password"
                                placeholder="Re-enter your password"
                                value={confirmPassword}
                                handleChange={this.handleInputChange}
                                error={errorConfirm}
                                name="confirmPassword"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-offset-1 col-md-6 check-list">
                            <PasswordCheck input={password} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <TymeButton disabled={submit} onClick={() => this.handleConfirm()}>
                                Activate my banking
                            </TymeButton>
                        </div>
                    </div>
                </div>
                <ConnectionTimeoutModal />
                <Modals
                    screen={screen}
                    errorCode={errorCode}
                    error={errorObj}
                    reset={() => this.reset()}
                />
                <Timeout />
                {submit && <Loading />}
            </Index>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.password.response,
        error: state.password.error,
        logoutData: state.logout.response,
        logoutError: state.logout.error,
        timeData: state.time.data,
        timeError: state.time.error,
        tokenData: state.token.data,
        tokenError: state.token.error,
    };
}

export default translate()(
    connect(mapStateToProps, { ...actions, getAccessToken, getGoogleTime })(Password),
);
