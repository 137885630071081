import { createAction } from 'redux-actions';

export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const requestOtpAction = createAction(REQUEST_OTP);
export const requestOtpSuccessAction = createAction(REQUEST_OTP_SUCCESS);
export const requestOtpFailedAction = createAction(REQUEST_OTP_FAILED);
export const verifyOtpAction = createAction(VERIFY_OTP);
export const verifyOtpSuccessAction = createAction(VERIFY_OTP_SUCCESS);
export const verifyOtpFailedAction = createAction(VERIFY_OTP_FAILED);
