import React from 'react';
import { CUSTOMER_SERVICE_CENTRE } from '../../constants';
import { Interpolate } from 'react-i18next';

const customerServiceNumberComponent = (
    <span style={{ fontWeight: 'bold' }}>{CUSTOMER_SERVICE_CENTRE}</span>
);
const withBoldPhoneNumber = (i18nKey, parent = 'p') => (
    <Interpolate
        parent={parent}
        i18nKey={`ns:${i18nKey}`}
        customerServiceNumber={customerServiceNumberComponent}
    />
);
export default withBoldPhoneNumber;
