import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
    VERIFY_CREDIT_CARD_PAN,
    verifyCreditCardPanSuccessAction,
    verifyCreditCardPanFailedAction,
} from '../actions/creditCard';

import { CREDIT_CARD_ERROR_CODES, CREDIT_CARD_RESET_STEPS, TOGGLE_FEATURE } from '../constants';

import {
    showNotActiveCreditCardAction,
    showCreditCardNotEligibleAction,
    showCommonExceptionAction,
    moveCreditCardStepAction
} from '../actions/application';
import { isPersonalLoan } from '../utils/useHooks';

export const verifyCreditCardPanEpic = (action$, store, { verifyCreditCardService }) =>
    action$.pipe(
        ofType(VERIFY_CREDIT_CARD_PAN),
        switchMap(action => {
            const { sessionId, cardPan, said } = action.payload;
            return verifyCreditCardService({ sessionId, cardPan, said }).pipe(
                mergeMap(() => {
                    const stepId = (isPersonalLoan() && TOGGLE_FEATURE.NATURE_PURPOSE) ?
                        CREDIT_CARD_RESET_STEPS.naturePurpose.stepId :
                        CREDIT_CARD_RESET_STEPS.crs.stepId;
                    return Observable.of(
                        verifyCreditCardPanSuccessAction(),
                        moveCreditCardStepAction(stepId)
                    );
                }),
                catchError(error => {
                    const { errors } = error.response.data;
                    const errorCode = errors && errors[0].errorCode;
                    if (error.response && error.response.status !== 401) {
                        switch (errorCode) {
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_NOT_ACTIVE:
                                return Observable.of(showNotActiveCreditCardAction(errorCode));
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_NOT_ELIGIBLE:
                                return Observable.of(showCreditCardNotEligibleAction(errorCode));
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_FIRST_SECOND:
                            case CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_MANY_ATTEMPTS:
                                return Observable.of(verifyCreditCardPanFailedAction(errorCode));
                            default:
                                return Observable.of(showCommonExceptionAction(errorCode));
                        }
                    }
                    return Observable.of(showCommonExceptionAction(errorCode));
                }),
            );
        }),
    );
