import {
    VERIFY_CREDIT_CARD_PAN,
    VERIFY_CREDIT_CARD_PAN_SUCCESS,
    VERIFY_CREDIT_CARD_PAN_FAILED,
    SET_CREDIT_CARD_ATTEMPT_ERROR,
} from '../actions/creditCard';

import {
    SHOW_NOT_ACTIVE_CREDIT_CARD,
    SHOW_CREDIT_CARD_NOT_ELIGIBLE,
    SHOW_COMMON_EXCEPTION
} from '../actions/application';

const initialState = {
    isVerifyCreditCardPan: false,
    isVerifyCreditCardPanFailed: false,
    isCreditCardAttemptError: false,
    errorCode: undefined,
    errorAttempt: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CREDIT_CARD_PAN:
            return {
                ...state,
                isVerifyCreditCardPan: true,
            };

        case VERIFY_CREDIT_CARD_PAN_SUCCESS:
            return {
                ...state,
                isVerifyCreditCardPan: false,
            };

        case VERIFY_CREDIT_CARD_PAN_FAILED:
            return {
                ...state,
                isVerifyCreditCardPan: false,
                isVerifyCreditCardPanFailed: true,
                errorCode: action.payload,
                errorAttempt: state.errorAttempt + 1,
            };

        case SET_CREDIT_CARD_ATTEMPT_ERROR:
            return {
                ...state,
                isVerifyCreditCardPan: false,
                isCreditCardAttemptError: true,
            };

        case SHOW_NOT_ACTIVE_CREDIT_CARD:
            return {
                ...state,
                isVerifyCreditCardPan: false
            };

        case SHOW_CREDIT_CARD_NOT_ELIGIBLE: 
            return {
                ...state,
                isVerifyCreditCardPan: false,
            };
        case SHOW_COMMON_EXCEPTION: 
            return {
                ...state,
                isVerifyCreditCardPan: false,
            }

        default:
            return state;
    }
};
