import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from "lodash"

import { resetAllState } from '../../actions';
import {
    hideCommonExceptionAction,
    hideBlockCreditCardProfileAction,
    showConfirmBeforeExitAction,
    hideConfirmBeforeExitAction,
    // hideNotActiveCreditCardAction,
    hideBlockCreditCardFeatureAction,
    hideNotCreditCardCustomerAction,
    // hideCreditCardNotEligibleAction,
    // showConfirmPayTaxAction,
    // hideConfirmPayTaxAction,
    hideNotRemoveFicaAction,
    // moveCreditCardStepAction,
    // hidePasswordTechnicalErrorAction,
    // hidePinTechnicalErrorAction,
} from '../../actions/application';

import Recaptcha from 'react-google-invisible-recaptcha';
import { verifyRecaptcha } from '../../actions/recaptcha';

import { CREDIT_CARD_RESET_STEPS, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getResetPinCreditCardSteps, trimBlank, validateInputSaid } from '../../utils/utils';
import CommonExceptionModal from '../../components/modals/CommonExceptionModal';
import ConfirmBeforeExitModal from '../../components/modals/ConfirmBeforeExitModal';
import NotCreditCardCustomerModal from '../../components/modals/NotCreditCardCustomerModal';
import NotRemoveFICAModal from '../../components/modals/NotRemoveFICAModal';
import BlockCCFeatureModal from '../../components/modals/BlockCCFeatureModal';
import BlockCCProfileModal from '../../components/modals/BlockCCProfileModal';

import { Config } from '../../config'

import { useError, usePageTitle, useBodyClass } from '../../utils/useHooks';
import TymeInputSAID, { maxInputLength } from '../../components/tyme-input/TymeInputSAID';
import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';
import StepBar from '../../components/common/StepBar';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import headerImage from '../../assets/images/header-4.png';
import Loading from '../../components/loading';

import { searchCreditCardSaidAction } from '../../actions/searchCCSaid';

const CCStepSAID = ({ verifyRecaptcha, loading, success, failed, initialErrors = null, history }) => {
    const [said, setSaid] = useState('');
    const [errors, setErrors] = useError(initialErrors);
    const recaptcha = React.useRef(null);

    const dispatch = useDispatch();
    const showConfirmBeforeExit = () => dispatch(showConfirmBeforeExitAction());
    const searchCCSaid = useSelector(state => state.searchCCSaid);

    usePageTitle('Enter your ID number | TymeBank');
    useBodyClass('credit-card');

    useEffect(() => {
        sessionStorage.getItem('accessToken') && sessionStorage.clear();
    }, [])

    useEffect(() => {
        if (success) {
            dispatch(searchCreditCardSaidAction(trimBlank(said)));
        }
    }, [success]);

    useEffect(() => {
        if (failed) {
            sessionStorage.clear();
        }
    }, [failed]);

    useEffect(() => {
        said.length === maxInputLength &&
            !validateInputSaid(said) &&
            setErrors({ message: 'Invalid ID number, please try again.' });
    }, [said]);

    const handleEnterSaidChange = inputValue => {
        setErrors(null);
        setSaid(inputValue);
    };

    const handleConfirmBeforeExitOnSubmit = () => {
        dispatch(hideConfirmBeforeExitAction());
        handleBackLoginPage();
    };

    const goToInternetBanking = () => {
        window.location = Config.loginUrl;
    };

    const handleCommonExceptionHide = () => {
        dispatch(hideCommonExceptionAction());
    };

    const handleNotCreditCardCCustomerHide = () => {
        dispatch(hideNotCreditCardCustomerAction());
    };

    const handleBackLoginPage = () => {
        window.location = Config.loginUrl;
    };

    const handleBlockCCProfileHide = () => {
        dispatch(hideBlockCreditCardProfileAction());
        dispatch(resetAllState());
    };

    const handleCreditCardNotRemoveFicaHide = () => {
        dispatch(hideNotRemoveFicaAction());
        goToInternetBanking();
    };

    const handleBlockCCFeatureHide = () => {
        dispatch(hideBlockCreditCardFeatureAction());
        // backToBegin();
        dispatch(resetAllState());
    };

    const onResolved = () => {
        verifyRecaptcha({
            response: recaptcha.current.getResponse(),
        })
    };

    const handleConfirmBeforeExitOnHide = () => dispatch(hideConfirmBeforeExitAction());

    const isDisabledSubmit = searchCCSaid.isSearchingCCSaid || loading || !!errors || said.length < maxInputLength;

    const handleNext = () => {
        if (recaptcha.current) {
            recaptcha.current.reset && recaptcha.current.reset();
            recaptcha.current.execute && recaptcha.current.execute();
        }
    };

    const handleKeyPress = keyCode => {
        return keyCode === 13 && !isDisabledSubmit && handleNext();
    };

    useEffect(() => {
        if (!searchCCSaid.sessionId) {
            return
        }

        if (searchCCSaid.identifier === Config.personLoan) {
            history.push(Config.appRoutes.PERSONAL_LOAN);
            return
        }

        history.push(Config.appRoutes.CREDIT_CARD);
    }, [searchCCSaid.sessionId, searchCCSaid.identifier])

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Enter your South African ID number</p>
                <p>We’ll use this to verify your identity.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.said.title}
                                currentStep={CREDIT_CARD_RESET_STEPS.said.stepProgress}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="step-creditcard-said">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 step-creditcard-said__input">
                                        <TymeInputSAID
                                            label="SAID number"
                                            value={said}
                                            error={errors}
                                            autoComplete="off"
                                            autoFocus
                                            onChange={handleEnterSaidChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="btn-control-container">
                                            <TymeButton
                                                id={BUTTON_ID.SAID_CANCEL}
                                                name={BUTTON_NAME.CANCEL}
                                                className="btn tyme-btn btn-secondary"
                                                onClick={() => showConfirmBeforeExit()}>
                                                Cancel
                                            </TymeButton>
                                            <TymeButton
                                                id={BUTTON_ID.SAID_NEXT}
                                                name={BUTTON_NAME.NEXT}
                                                disabled={isDisabledSubmit}
                                                onClick={handleNext}>
                                                Next
                                            </TymeButton>

                                            <Recaptcha
                                                ref={recaptcha}
                                                sitekey={Config.googleRecaptcha.sitekey}
                                                onResolved={onResolved}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="privacy-policy">
                                        * By entering your ID number and continuing, you agree to the TymeBank
                                        <a href={Config.termAndConditionsUrl} target="_blank"> Ts&Cs</a> and
                                        <a href={Config.privacyPolicyUrl} target="_blank"> Privacy policy.</a>
                                    </div>
                                </div>
                                {(searchCCSaid.isSearchingCCSaid || loading) && <Loading />}
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CommonExceptionModal
                onHide={handleCommonExceptionHide}
                onSubmit={handleCommonExceptionHide} />

            <ConfirmBeforeExitModal
                onSubmit={handleConfirmBeforeExitOnSubmit}
                onHide={handleConfirmBeforeExitOnHide} />

            <NotCreditCardCustomerModal
                onHide={handleNotCreditCardCCustomerHide}
                onSubmit={handleNotCreditCardCCustomerHide}
                onLoginIB={handleBackLoginPage} />                

            <BlockCCProfileModal
                onHide={handleBlockCCProfileHide}
                onSubmit={handleBlockCCProfileHide} />

            <NotRemoveFICAModal
                onHide={handleCreditCardNotRemoveFicaHide}
                onLoginIB={handleCreditCardNotRemoveFicaHide} />

            <BlockCCFeatureModal
                onHide={handleBlockCCFeatureHide}
                onSubmit={handleBlockCCFeatureHide} />
        </Page>
    );
};

CCStepSAID.propTypes = {
    // submitting: PropTypes.bool,
    initialErrors: PropTypes.object
};

const mapStateToProps = ({
    newReCaptcha: {
        loading,
        success,
        failed
    },
    state
}) => ({
    state,
    loading,
    success,
    failed
});


const mapDispatchToProps = {
    verifyRecaptcha
};

export default connect(mapStateToProps, mapDispatchToProps)(CCStepSAID);

