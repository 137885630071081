import React from 'react';
import InputOTPItem from './InputOTPItem';
import { useInputVerificationCode } from '../../utils/useHooks';

const InputOTP = ({
    otps = [],
    label = 'OTP',
    enableAutoFocus = true,
    disabled,
    errors,
    onChange,
}) => {
    const [otpRefs, handleChange] = useInputVerificationCode(otps, onChange);

    return (
        <div className={`input-otp-container ${disabled ? 'disabled' : ''}`}>
            <div className="input-otp__wrapper">
                <label className="input-otp__header">{label}</label>
                <div className={`input-otp__main ${errors ? 'has-error' : ''}`}>
                    {otps.map((item, index) => (
                        <InputOTPItem
                            key={item.id}
                            inputValue={item.value}
                            onChange={({ target: { value } }) => handleChange(item.id, value)}
                            autoFocus={enableAutoFocus && item.id === 0}
                            disabled={disabled}
                            ref={ref => (otpRefs.current[index] = ref)}
                        />
                    ))}
                </div>
                <div className="help-block">{errors ? errors.message : ''}</div>
            </div>
        </div>
    );
};

export default InputOTP;
