import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { isPersonalLoan, useError, usePageTitle, useBodyClass } from '../../../utils/useHooks';

import {
    generateArrayValues,
    getResetPinCreditCardSteps,
    makeStringFromNumbers as makeCardPAN,
} from '../../../utils/utils';
import { Config } from '../../../config';
import {
    CREDIT_CARD_ERROR_CODES,
    CREDIT_CARD_RESET_STEPS,
    BUTTON_ID,
    BUTTON_NAME,
} from '../../../constants';

import {
    setCreditCardAttemptErrorAction,
} from '../../../actions/creditCard';

import TymeButton from '../../../components/common/TymeButton';
import TimeOut from '../../../components/common/TimeOut';
import InputCardPAN from '../../../components/card/InputCardPAN';
import Page from '../../../components/layout/Page';
import Header from '../../../components/layout/Header';
import StepBar from '../../../components/common/StepBar';
import CreditCardFailedAttemptModal from '../../../components/modals/CreditCardFailedAttemptModal';
import headerImage from '../../../assets/images/header-1.png';
import headerImagePL from '../../../assets/images/header-10.png';
import tymeBankCreditCard from '../../../assets/images/tyme-bank-credit-card.svg';
import tymeBankPersonalLoan from '../../../assets/images/tyme-bank-personal-loan.svg';
import * as SELECTORS from '../../../selectors';
import Loading from '../../../components/loading';


export const CCStepCard = ({
    submitting = false,
    isAttemptError = false,
    initialErrors,
    onCancel,
    onSubmit,
    onAttempt,
    ...props
}) => {
    const errorCode = useSelector(SELECTORS.getCardPanErrorCode);
    const errorAttempt = useSelector(SELECTORS.getErrorAttempt);

    const [cardNumbers, setCardNumbers] = useState(generateArrayValues(Config.cardPan.numberOfInput));
    const [errors, setErrors] = useError(initialErrors);
    const [haveEmptyInput, setHaveEmptyInput] = useState(true);
    const dispatch = useDispatch();

    const isPL = isPersonalLoan(props);

    usePageTitle(isPL ? 'Enter your Personal Loan Number | TymeBank' : 'Enter your Credit Card Pan | TymeBank');
    useBodyClass('credit-card');

    useEffect(() => {
        const emptyItems = cardNumbers.filter(item => item.value === '');
        setHaveEmptyInput(emptyItems.length > 0);
    }, [cardNumbers]);

    useEffect(() => {
        errorCode && setCardNumbers(generateArrayValues(Config.cardPan.numberOfInput));
        if (errorAttempt === 1 && errorCode === CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_FIRST_SECOND) {
            setErrors({
                message: `Incorrect, please try again.`,
            });
        } else if (errorAttempt === 2 && errorCode === CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_FIRST_SECOND) {
            setErrors({
                message: `Incorrect, you have one more chance to try again.`,
            });
        } else if (errorAttempt === 3 && errorCode === CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_MANY_ATTEMPTS) {
            dispatch(setCreditCardAttemptErrorAction({ errorCode: CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PAN_FAILURE_MANY_ATTEMPTS }));
        }
    }, [errorAttempt, errorCode]);

    const handleChange = cardNumbers => {
        setErrors(null);
        setCardNumbers(cardNumbers);
    };
    const handleConfirm = () => {
        onSubmit(makeCardPAN(cardNumbers));
    };

    return (
        <Page>
            <Header image={isPL ? headerImagePL : headerImage}>
                <p className="heading">
                    {isPL ? "Enter the last 4 digits of your reference number" : "Enter the last 4 digits of your credit card"}
                </p>
                <p>
                    {isPL ? "We’re referring to the last four digits of your Personal Loan reference number from your loan agreement" : "We referring to the last four numbers on the front of your primary credit card."}
                </p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.creditCard.title}
                                currentStep={CREDIT_CARD_RESET_STEPS.creditCard.stepProgress}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="step-creditcard-pan">
                        <div className="row">
                            <div className="col-xs-12 col-sm-8 col-md-8">
                                <div className="step-creditcard-pan__input">
                                    <InputCardPAN
                                        numbers={cardNumbers}
                                        onChange={handleChange}
                                        errors={errors}
                                        isPL={isPL}
                                        label={isPL ? "Last 4 digits of your Personal Loan Reference No" : "Last 4 digits of your primary TymeBank credit card"}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-4 col-md-4 visible-xs-block">
                                    <div className="step-creditcard-pan__image">
                                        <img src={isPL ? tymeBankPersonalLoan : tymeBankCreditCard} alt="Tyme Bank" />
                                    </div>
                                </div>
                                <div className="step-creditcard-pan__buttons">
                                    <div className="btn-control-container">
                                        <TymeButton
                                            id={BUTTON_ID.CARD_CANCEL}
                                            name={BUTTON_NAME.CANCEL}
                                            className="btn tyme-btn btn-secondary"
                                            onClick={onCancel}>
                                            Cancel
                                        </TymeButton>
                                        <TymeButton
                                            id={BUTTON_ID.CARD_NEXT}
                                            name={BUTTON_NAME.NEXT}
                                            disabled={haveEmptyInput || submitting}
                                            onClick={handleConfirm}>
                                            Next
                                        </TymeButton>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 hidden-xs">
                                <div className="step-creditcard-pan__image">
                                    <img src={isPL ? tymeBankPersonalLoan : tymeBankCreditCard} alt="Tyme Bank" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {submitting && <Loading />}
                    <TimeOut />
                    <CreditCardFailedAttemptModal
                        onSubmit={onAttempt}
                        onHide={onAttempt}
                        isShow={isAttemptError}
                    />
                </div>
            </div>
        </Page>
    );
};

CCStepCard.propTypes = {
    submitting: PropTypes.bool,
    isAttemptError: PropTypes.bool,
    initialErrors: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAttempt: PropTypes.func.isRequired
};

export default CCStepCard;
