import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePageTitle, useBodyClass, useError } from '../../../utils/useHooks';
import {
    generateArrayValues as generatePinArray,
    checkConsecutive,
    checkRepeat,
    makeStringFromNumbers as makePinsCode,
    getResetPinCreditCardSteps,
} from '../../../utils/utils';
import {
    CREDIT_CARD_RESET_STEPS,
    BUTTON_ID,
    BUTTON_NAME
} from '../../../constants';
import { Config } from '../../../config';
import StepBar from '../../../components/common/StepBar';
import Page from '../../../components/layout/Page';
import Header from '../../../components/layout/Header';
import headerImage from '../../../assets/images/header-11.png';
import InputOTP from '../../../components/new-otp/InputOTP';
import TymeButton from '../../../components/common/TymeButton';
import TimeOut from '../../../components/common/TimeOut';
import Loading from '../../../components/loading';

const pinArray = generatePinArray(Config.pin.numberOfInput);
const confirmPinArray = generatePinArray(Config.pin.numberOfInput);
const validatePinsEmpty = pins => pins.filter(pin => pin.value === '').length > 0;

const CCStepPin = ({ 
    initialErrors, 
    submitting = false,
    onCancel,
    onSubmit
}) => {
    const [pins, onSetPins] = useState(pinArray);
    const [confirmPins, onSetConfirmPins] = useState(confirmPinArray);
    const [errors, setErrors] = useError(initialErrors);
    const [isDisabledButton, setIsDisabledButton] = useState(true);

    usePageTitle('Enter your Pin | TymeBank');
    useBodyClass('credit-card');

    useEffect(() => {
        setErrors(null);
        setIsDisabledButton(true);
        const isEmptyPins = validatePinsEmpty(pins);
        const isConfirmEmptyPins = validatePinsEmpty(confirmPins);
        if (!isEmptyPins) {
            const pinValues = pins.map(pin => Number(pin.value));
            if (checkConsecutive(pinValues)) {
                setErrors({
                    pins: {
                        message: 'Your login PIN cannot be in number order e.g: 1234.',
                    },
                });
                return;
            }
            if (checkRepeat(pinValues)) {
                setErrors({
                    pins: {
                        message: 'Your login PIN cannot be in number order e.g: 1234.',
                    },
                });
                return;
            }
            if (!isConfirmEmptyPins) {
                const pinsString = makePinsCode(pins);
                const confirmPinsString = makePinsCode(confirmPins);
                const isMatch = pinsString === confirmPinsString;
                !isMatch &&
                    setErrors({
                        confirmPins: { message: 'The login PINs entered do not match.' },
                    });
                isMatch && setIsDisabledButton(false);
            }
        }
    }, [pins, confirmPins]);

    const onHandleConfirm = () => {
        onSubmit(makePinsCode(pins));
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Great! Time to create your Login PIN</p>
                <p className="sub-heading">Your Login PIN will allow you to access your account via the TymeBank app, any TymeBank kiosk, and USSD.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.pin.title}
                                currentStep={CREDIT_CARD_RESET_STEPS.pin.stepId}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="step-creditcard-pin">
                                <p className="step-creditcard-pin__heading">
                                    Your PIN must be 4 digits long, may not be in number order (e.g. 1234), may not be all the same numbers (e.g. 1111), 
                                    nor your date of birth. Remember to never share your Login PIN with anyone.
                                </p>
                                <div className="step-creditcard-pin__content">
                                    <InputOTP
                                        otps={pins}
                                        onChange={onSetPins}
                                        errors={errors && errors.pins}
                                        label="Enter your Login PIN"
                                    />
                                    <InputOTP
                                        otps={confirmPins}
                                        onChange={onSetConfirmPins}
                                        errors={errors && errors.confirmPins}
                                        enableAutoFocus={false}
                                        label="Confirm your Login PIN"
                                    />
                                </div>
                                <div className="step-creditcard-pin__footer">
                                    <div className="btn-control-container">
                                        <TymeButton
                                            id={BUTTON_ID.PIN_CANCEL}
                                            name={BUTTON_NAME.CANCEL}
                                            className="btn tyme-btn btn-secondary"
                                            onClick={onCancel}>
                                            Cancel
                                        </TymeButton>
                                        <TymeButton
                                            id={BUTTON_ID.PIN_NEXT}
                                            name={BUTTON_NAME.NEXT}
                                            disabled={isDisabledButton || submitting}
                                            onClick={onHandleConfirm}>
                                            Next
                                        </TymeButton>
                                    </div>
                                </div>
                                { submitting && <Loading /> }
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

CCStepPin.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default CCStepPin;
