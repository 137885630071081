import React, { useState } from 'react';
import PropTypes from 'prop-types';
import showImage from '../../assets/images/show.png';
import hideImage from '../../assets/images/hide.png';

export const TymeInputPassword = ({
    label,
    value = '',
    error = null,
    containerClassName = 'input-container',
    labelClassName = 'input-label',
    inputClassName = 'input-control',
    handleBlur,
    handleChange,
    handleKeyPress = () => {},
    ...rest
}) => {
    const [show, setShow] = useState(true);
    const [type, setType] = useState('password');

    const toggleShow = () => {
        setShow(!show);
        show ? setType('text') : setType('password');
    };

    return (
        <div className={containerClassName}>
            <label className={labelClassName}>{label}</label>
            <div style={{ position: 'relative' }} className={`${error ? 'has-error' : ''}`}>
                <input
                    className={inputClassName}
                    type={type}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyPress={e => handleKeyPress(e.which)}
                    {...rest}
                />
                <div className="help-block">{error ? error.message : ''}</div>
                <div className="image-container">
                    {show && <img src={showImage} alt="eye show" onClick={toggleShow} />}
                    {!show && <img src={hideImage} alt="eye hide" onClick={toggleShow} />}
                </div>
            </div>
        </div>
    );
};

TymeInputPassword.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.exact({
        message: PropTypes.string,
    }),
    type: PropTypes.string,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleKeyPress: PropTypes.func,
};

export default TymeInputPassword;
