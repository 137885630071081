import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export class Button extends Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        disabled: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.string,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        disabled: '',
        value: '',
        onClick: () => {},
    };

    render() {
        let click = null;
        if (this.props.onClick) {
            click = this.props.onClick;
        }

        return (
            <button
                {..._.omit(this.props, ['value', 'btnRef'])}
                ref={this.props.btnRef}
                onClick={click}>
                {this.props.value}
            </button>
        );
    }
}

export default Button;
