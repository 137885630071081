import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE,  MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowConfirmBeforeExit} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const ConfirmBeforeExitModal = ({ onHide, onSubmit }) => {
    const isShowConfirmBeforeExit = useSelector(getIsShowConfirmBeforeExit);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_CONFIRM_BEFORE_EXIT}
            title="Are you sure you want to exit the process?"
            type={MODAL_TYPE.INFO}
            isShow={isShowConfirmBeforeExit}
            handleHide={onHide}>
            <div>
                <p>If you exit now, you will have to restart the process.</p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.CONFIRM_BEFORE_EXIT_YES}
                        name={BUTTON_NAME.YES}
                        className="btn tyme-btn btn-secondary" 
                        onClick={onSubmit}
                    >
                        Yes
                    </TymeButton>
                    <TymeButton
                        id={BUTTON_ID.CONFIRM_BEFORE_EXIT_NO}
                        name={BUTTON_NAME.NO}
                        onClick={onHide}
                    >
                        No
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

ConfirmBeforeExitModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ConfirmBeforeExitModal;
