import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textbox from '../form-elements/textbox';
import Label from '../form-elements/label';

export class Pin extends Component {
    static propTypes = {
        id: PropTypes.string,
        prefix: PropTypes.string,
        length: PropTypes.number,
        type: PropTypes.oneOf(['pin', 'otp']),
        onChange: PropTypes.func,
        error: PropTypes.string,
    };

    static defaultProps = {
        id: '',
        prefix: '',
        type: 'pin',
        length: 4,
        error: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            values: {},
        };
    }

    componentWillMount() {
        const { values } = this.state;

        for (let i = 0; i < this.props.length; i += 1) {
            values[i] = '';
        }

        this.setState({
            values: { ...values },
        });
    }

    onInputChange = e => {
        const [, idx] = e.target.id.split('-');
        const currentValue = e.target.value;

        this.setState(
            {
                values: {
                    ...this.state.values,
                    [idx]: currentValue,
                },
            },
            () => {
                if (currentValue) {
                    const next = this[`input-${Number(idx) + 1}`];

                    if (e.target.value) {
                        if (next) {
                            next.focus();

                            if (this.state.values[Number(idx) + 1].length) {
                                setTimeout(() => {
                                    next.setSelectionRange(0, 99999);
                                }, 50);
                            }
                        }
                    }
                }

                this.props.onChange && this.props.onChange(this.values);
            },
        );
    };

    onInputKeyDown = e => {
        const { keyCode, key } = e;
        if (
            !(
                keyCode === 8 ||
                keyCode === 9 ||
                keyCode === 13 ||
                keyCode === 37 ||
                keyCode === 39 ||
                keyCode === 46 ||
                keyCode === 116 ||
                keyCode === 229
            ) && // modifier keys
            !(key !== ' ' && key >= 0 && key <= 9)
        ) {
            e.preventDefault();
            return;
        }

        const [, idx] = e.target.id.split('-');

        if (keyCode === 8 || keyCode === 46) {
            const preInput = this[`input-${Number(idx) - 1}`];

            if (preInput) {
                if (this.state.values[Number(idx) - 1].length) {
                    setTimeout(() => {
                        preInput.focus();
                        preInput.setSelectionRange(0, 99999);
                    }, 50);
                } else {
                    setTimeout(() => {
                        preInput.focus();
                    }, 50);
                }
            }
        }

        if (
            (key >= 0 && key <= 9) ||
            e.target.value === key ||
            (e.target.value && key === 'Unidentified')
        ) {
            const next = this[`input-${Number(idx) + 1}`];

            if (next) {
                if (this.state.values[Number(idx) + 1].length) {
                    setTimeout(() => {
                        next.focus();
                        next.setSelectionRange(0, 99999);
                    }, 50);
                } else {
                    setTimeout(() => {
                        next.focus();
                    }, 50);
                }
            }
        }
    };

    onInputClick = e => {
        const [, idx] = e.target.id.split('-');

        if (this.state.values[idx].length) {
            setTimeout(() => this[`input-${Number(idx)}`].setSelectionRange(0, 99999), 50);
        }
    };

    get values() {
        return Object.keys(this.state.values)
            .map(k => this.state.values[k])
            .join('');
    }

    renderFields() {
        const inputType = this.props.type === 'pin' ? 'password' : 'tel';
        const fields = [];

        for (let i = 0; i < this.props.length; i += 1) {
            fields.push(
                <Textbox
                    key={i}
                    id={`${this.props.prefix}pin-${i}`}
                    className="form-control"
                    type={inputType}
                    name={`${this.props.prefix}pin_${i}`}
                    placeholder=""
                    maxlength="1"
                    rule="numbers"
                    onChange={this.onInputChange}
                    onKeyDown={this.onInputKeyDown}
                    onClick={this.onInputClick}
                    inputRef={input => {
                        this[`input-${i}`] = input;
                    }}
                    error={this.props.error}
                    autoComplete="off"
                />,
            );
        }

        return fields;
    }

    render() {
        return (
            <div id={this.props.id} className="pin-container">
                <div className="pin-wrapper">
                    {this.props.label && (
                        <Label htmlFor={this.props.id} labelText={this.props.label} />
                    )}
                    {this.renderFields()}
                    <Textbox
                        type="hidden"
                        id={`${this.props.prefix}pin-value`}
                        className="form-control"
                        name={`${this.props.prefix}pin_value`}
                        placeholder=""
                        maxlength="4"
                        rule="numbers"
                        inputRef={input => {
                            this.hiddenElement = input;
                        }}
                        empty={this.values !== ''}
                        inputValue={this.values}
                        error={this.props.error}
                    />
                </div>
            </div>
        );
    }
}

export default Pin;
