import React, { useState } from 'react';
import PropTypes from 'prop-types';
import joinclass from 'joinclass';
import { v4 } from 'uuid';

const NaturePurposeCheckbox = ({
    checked,
    value,
    className,
    labelClassName,
    type = 'checkbox',
    name,
    id,
    label,
    handleChange = () => { },
    getInputRef = () => { },
}) => {
    const [localId] = useState(id || v4());
    return (
        <div>
            <input
                ref={getInputRef}
                className={joinclass('form-check-input', className)}
                type={type}
                name={name}
                id={localId}
                checked={checked}
                value={value}
                onChange={e => handleChange({ value, checked: e.target.checked })}
            />
            <label
                className={joinclass('form-check-label', labelClassName)}
                htmlFor={localId}
            >
                {label}
            </label>
        </div>
    );
};

NaturePurposeCheckbox.propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    handleChange: PropTypes.func,
    getInputRef: PropTypes.func,
};

export default NaturePurposeCheckbox;
