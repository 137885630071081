import React from 'react';
import IbCircularProgressbar from '../react-circular-progressbar';

const HeaderIcon = props => (
    <div>
        {props.timer && (
            <IbCircularProgressbar
                seconds={props.timer.time}
                paused={props.timer.paused ? props.timer.paused : false}
                settings={props.timer.settings ? props.timer.settings : null}
                onComplete={props.timer.time !== 0 ? props.timer.complete : null}
            />
        )}
        {!props.timer && (
            <img
                className={props.className}
                src={props.icon}
                alt={props.heading}
                title={props.heading}
            />
        )}
    </div>
);

export default HeaderIcon;
