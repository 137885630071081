import {
    SET_CREDENTIAL,
    SET_CREDENTIAL_SUCCESS_RESET_PIN,
    SET_CREDENTIAL_FAILED_RESET_PIN,
    SET_CREDENTIAL_SUCCESS_RESET_PASSWORD,
    SET_CREDENTIAL_FAILED_RESET_PASSWORD,
} from '../actions/setCredential';

const initialState = {
    isSettingCredential: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CREDENTIAL:
            return {
                ...state,
                isSettingCredential: true,
            };
        case SET_CREDENTIAL_SUCCESS_RESET_PIN:
            return {
                ...state,
                isSettingCredential: false,
            };
        case SET_CREDENTIAL_FAILED_RESET_PIN:
            return {
                ...state,
                isSettingCredential: false,
            };
        case SET_CREDENTIAL_SUCCESS_RESET_PASSWORD:
            return {
                ...state,
                isSettingCredential: false,
            };
        case SET_CREDENTIAL_FAILED_RESET_PASSWORD:
            return {
                ...state,
                isSettingCredential: false,
            };
        default:
            return state;
    }
};
