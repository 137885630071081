import {

    CC_SET_CREDENTIAL_PASSWORD,
    CC_SET_CREDENTIAL_PASSWORD_SUCCESS,
    CC_SET_CREDENTIAL_PASSWORD_FAILED,
} from '../actions/creditCardSetCredential';

import {
    SHOW_PASSWORD_TECHNICAL_ERROR,
    MOVE_CREDIT_CARD_STEP
} from '../actions/application';

const initialState = {
    isCCSettingPassword: false,
    isCCSettingPasswordSuccess: false,
    isCCSettingPasswordFailed: false,
    errorPasswordAttempt: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CC_SET_CREDENTIAL_PASSWORD:
            return {
                ...state,
                isCCSettingPassword: true,
                errorPasswordAttempt: 0
            };
        case CC_SET_CREDENTIAL_PASSWORD_SUCCESS:
            return {
                ...state,
                isCCSettingPassword: false,
                isCCSettingPasswordSuccess: true,
            };

        case CC_SET_CREDENTIAL_PASSWORD_FAILED:
            return {
                ...state,
                isCCSettingPassword: false,
                isCCSettingPasswordFailed: true,
                errorPasswordAttempt: initialState.errorPasswordAttempt + 1
            };

        case MOVE_CREDIT_CARD_STEP: 
            return {
                ...state,
                isCCSettingPassword: false,
            };

        case SHOW_PASSWORD_TECHNICAL_ERROR:
            return {
                isCCSettingPassword: false
            }
        default:
            return state;
    }
};
