import { createAction } from 'redux-actions';

export const VERIFY_CREDIT_CARD_PAN = 'VERIFY_CREDIT_CARD_PAN';
export const VERIFY_CREDIT_CARD_PAN_SUCCESS = 'VERIFY_CREDIT_CARD_PAN_SUCCESS';
export const VERIFY_CREDIT_CARD_PAN_FAILED = 'VERIFY_CREDIT_CARD_PAN_FAILED';
export const SET_CREDIT_CARD_ATTEMPT_ERROR = 'SET_CREDIT_CARD_ATTEMPT_ERROR';

export const verifyCreditCardPanAction = createAction(VERIFY_CREDIT_CARD_PAN);
export const verifyCreditCardPanSuccessAction = createAction(VERIFY_CREDIT_CARD_PAN_SUCCESS);
export const verifyCreditCardPanFailedAction = createAction(VERIFY_CREDIT_CARD_PAN_FAILED);
export const setCreditCardAttemptErrorAction = createAction(SET_CREDIT_CARD_ATTEMPT_ERROR);
