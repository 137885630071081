import React from 'react';
import { useSpring, animated } from 'react-spring';
import Footer from './Footer';
import { useScrollTop } from '../../utils/useHooks';

export const Page = ({ children, className }) => {
    useScrollTop();
    const fadeIn = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    });

    return (
        <animated.div className={`page page-container ${className}`} style={fadeIn}>
            {children}
            <Footer />
        </animated.div>
    );
};

export default Page;
