import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import iconLock from '../../assets/images/lock.png';
import iconLogo from '../../assets/images/logo-black.png';

export class Footer extends Component {
    static propTypes = {
        footer: PropTypes.object.isRequired,
    };

    render() {
        const { t, footer } = this.props;
        const copy = t(translationKeys.footer, { returnObjects: true });

        return (
            <footer>
                {footer.licence && (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>{footer.licence}</p>
                            </div>
                        </div>
                    </div>
                )}
                {footer.content && (
                    <div className="page-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-7 info">
                                    <div className="row">
                                        <img
                                            className="icon pull-left hidden-xs"
                                            src={iconLock}
                                            alt=""
                                        />
                                        <div className="text">{copy.security}</div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-5 branding">
                                    <div className="row">
                                        <img src={iconLogo} alt="" />
                                        <div className="text">
                                            &copy; {new Date().getFullYear()} {copy.branding}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </footer>
        );
    }
}

export default translate()(Footer);
