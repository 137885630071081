import { RESET_PASSWORD_SAID_CAPTURE, RESET_PASSWORD_SAID_ERROR } from '../../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case RESET_PASSWORD_SAID_CAPTURE:
            return { ...state, data: action.payload, error: undefined };
        case RESET_PASSWORD_SAID_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
}
