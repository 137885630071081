import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowBlockCreditCardProfile} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const BlockCCProfileModal = ({ onHide, onSubmit }) => {
    const isShowBlockCreditCardProfile = useSelector(getIsShowBlockCreditCardProfile);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_PROFILE_BLOCK}
            title="Access Blocked"
            type={MODAL_TYPE.BLOCK}
            isShow={isShowBlockCreditCardProfile}
            handleHide={onHide}>
            <div>
                <p>
                    We've blocked you from setting up your login details right now for security reasons.
                </p>
                <p>
                    To remove this block, call Customer Service on <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.PROFILE_BLOCK_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}>
                        OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

BlockCCProfileModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default BlockCCProfileModal;
