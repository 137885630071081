import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeviceDetector from "device-detector-js";
import { $emit } from 'event-bus-e2z';
import { Config } from '../../config';
import * as SELECTORS from '../../selectors';
import {
    CREDIT_CARD_RESET_STEPS,
    INITIAL_OTP_RESEND_ATTEMPT,
    CC_CREDENTIAL_TYPE,
    DEVICE
} from '../../constants';
import {
    hideCommonExceptionAction,
    hideBlockCreditCardProfileAction,
    showConfirmBeforeExitAction,
    hideConfirmBeforeExitAction,
    hideNotActiveCreditCardAction,
    hideBlockCreditCardFeatureAction,
    hideNotCreditCardCustomerAction,
    hideCreditCardNotEligibleAction,
    showConfirmPayTaxAction,
    hideConfirmPayTaxAction,
    hideNotRemoveFicaAction,
    moveCreditCardStepAction,
    hidePasswordTechnicalErrorAction,
    hidePinTechnicalErrorAction,
} from '../../actions/application';
import { searchCreditCardSaidAction } from '../../actions/searchCCSaid';
import { verifyCreditCardPanAction } from '../../actions/creditCard';
import { captureTaxAction } from '../../actions/captureTax';
import { resetAllState } from '../../actions';
import { ccSetCredentialPinAction, ccSetCredentialPasswordAction } from '../../actions/creditCardSetCredential';
// import CCStepSAID from './CCStartSAID';
import CCStepOTP from './cc-step/CCStepOTP';
import CCStepCard from './cc-step/CCStepCard';
import CCStepTax from './cc-step/CCStepTax';
import CCStepPin from './cc-step/CCStepPin';
import CCStepPassword from './cc-step/CCStepPassword';
import CCStepSuccess from './cc-step/CCStepSuccess';
import CCStepUnSuccess from './cc-step/CCStepUnSuccess';
import NaturePurpose from '../../controller/nature-purpose/NaturePurpose';
import ConfirmBeforeExitModal from '../../components/modals/ConfirmBeforeExitModal';
import CommonExceptionModal from '../../components/modals/CommonExceptionModal';
import BlockCCProfileModal from '../../components/modals/BlockCCProfileModal';
import BlockCCFeatureModal from '../../components/modals/BlockCCFeatureModal';
import NotCreditCardCustomerModal from '../../components/modals/NotCreditCardCustomerModal';
import NotActiveCreditCardModal from '../../components/modals/NotActiveCreditCardModal';
import CreditCardNotEligibleModal from '../../components/modals/CreditCardNotEligibleModal';
import ConfirmPayTaxModal from '../../components/modals/ConfirmPayTaxModal';
import NotRemoveFICAModal from '../../components/modals/NotRemoveFICAModal';
import PasswordTechnicalErrorModal from '../../components/modals/PasswordTechnicalErrorModal';
import PinTechnicalErrorModal from '../../components/modals/PinTechnicalErrorModal';

const CreditCardResetPin = ({ location }) => {
    const step = useSelector(SELECTORS.getCreditCardActivationStep);

    const errors = useSelector(SELECTORS.getCreditCardActivationErrors);
    const said = useSelector(SELECTORS.getCCSaid);
    // const isSearchingCCSaid = useSelector(SELECTORS.getIsSearchingCCSaid);
    const isCheckingCreditCardEligibility = useSelector(SELECTORS.getIsCheckingCreditCardEligibility);
    const isVerifyCreditCardPan = useSelector(SELECTORS.getIsVerifyCreditCardPan);
    const isCreitCardAttemptError = useSelector(SELECTORS.getIsCreditCardAttemptError);
    const sessionId = useSelector(SELECTORS.getCreditCardSessionId);
    const isCRSLoading = useSelector(SELECTORS.getCRSLoading);
    const isCCSettingPin = useSelector(SELECTORS.getIsCCSettingPin);
    const isCCSettingPinSuccess = useSelector(SELECTORS.getIsCCSettingPinSuccess);
    const isCCSettingPassword = useSelector(SELECTORS.getIsCCSettingPassword);

    const dispatch = useDispatch();

    const [otpResendAttempt, setOtpResendAttempt] = useState(INITIAL_OTP_RESEND_ATTEMPT);

    const backToBegin = () => {
        // Stay out of flash change
        dispatch(resetAllState());
        setTimeout(() => $emit("movePageStart"), 250)
    };

    const goToInternetBanking = () => {
        window.location = Config.loginUrl;
    };

    const showConfirmBeforeExit = () => dispatch(showConfirmBeforeExitAction());
    const handleConfirmBeforeExitOnHide = () => dispatch(hideConfirmBeforeExitAction());

    const handleConfirmBeforeExitOnSubmit = () => {
        dispatch(hideConfirmBeforeExitAction());
        backToBegin();
    };

    // const handleSaidSubmit = said => {
    //     dispatch(searchCreditCardSaidAction(said));
    // };
    // const handleSaidCancel = () => {
    //     showConfirmBeforeExit();
    // };

    const handleOtpSubmit = () => {
        dispatch(searchCreditCardSaidAction(said));
    };

    const handleOtpNotSent = () => {
        backToBegin();
    };

    const handleOtpResend = () => {
        setOtpResendAttempt(prevAttempt => prevAttempt + 1);
    };

    const handleOtpInputAttempt = () => {
        backToBegin();
    };

    const handleOtpCancel = () => {
        showConfirmBeforeExit();
    };

    const handleExpiredExceed = () => {
        backToBegin();
    };

    const handleCommonExceptionHide = () => {
        dispatch(hideCommonExceptionAction());
        backToBegin();
    };

    const handleBlockCCFeatureHide = () => {
        dispatch(hideBlockCreditCardFeatureAction());
        backToBegin();
    };

    const handleBlockCCProfileHide = () => {
        dispatch(hideBlockCreditCardProfileAction());
        backToBegin();
    };

    const handleNotActiveCreditCardHide = () => {
        dispatch(hideNotActiveCreditCardAction());
        backToBegin();
    };

    const handleNotCreditCardCCustomerHide = () => {
        dispatch(hideNotCreditCardCustomerAction());
    };

    const handleBackLoginPage = () => {
        goToInternetBanking();
    };

    const handleCreditCardSubmit = cardPan => {
        dispatch(verifyCreditCardPanAction({
            sessionId,
            cardPan,
            said
        }))
    };

    const handleCreditCardCancel = () => {
        showConfirmBeforeExit();
    };

    const handleCreditCardAttempt = () => {
        backToBegin();
    };

    const handleCreditCardNotEligibleHide = () => {
        dispatch(hideCreditCardNotEligibleAction());
        backToBegin();
    };

    const handleCrsNo = () => {
        dispatch(captureTaxAction({
            sessionId,
            crs: false
        }))
    };

    const handleCrsYes = () => dispatch(showConfirmPayTaxAction());

    const handleYesConfirmTax = () => {
        dispatch(captureTaxAction({
            sessionId,
            crs: true
        }))
    };

    const handleNoConfirmTax = () => {
        dispatch(captureTaxAction({
            sessionId,
            crs: false
        }))
    };

    const handleConfirmPayTaxHide = () => {
        dispatch(hideConfirmPayTaxAction());
    }

    const handleCreditCardNotRemoveFicaHide = () => {
        dispatch(hideNotRemoveFicaAction());
        goToInternetBanking();
    };

    const handlePinSubmit = newPin => {
        dispatch(
            ccSetCredentialPinAction({
                sessionId,
                credential: newPin,
                type: CC_CREDENTIAL_TYPE.PIN
            }),
        );
    };

    const handlePinCancel = () => {
        showConfirmBeforeExit();
    };

    const handlePasswordSubmit = newPassword => {
        dispatch(
            ccSetCredentialPasswordAction({
                sessionId,
                credential: newPassword,
                type: CC_CREDENTIAL_TYPE.PASSWORD
            }),
        );
    };

    const handlePasswordCancel = () => {
        showConfirmBeforeExit();
    };

    const isCheckPinSuccess = () => {
        if (isCCSettingPinSuccess) {
            dispatch(moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.success.stepId))
        } else {
            dispatch(moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.unSuccess.stepId))
        }
    };

    const handleDolater = () => {
        isCheckPinSuccess();
    };

    const getDeviceAppURl = () => {
        const deviceDetector = new DeviceDetector();
        const {
            os: { name = '' } = {},
            device: { brand = '' } = {}
        } = deviceDetector.parse(navigator.userAgent);
        switch (name.toLowerCase()) {
            case DEVICE.IOS.toLowerCase():
                return Config.appleStoreUrl;
            case DEVICE.ANDROID.toLowerCase():
                if (brand.toLocaleLowerCase() === DEVICE.HUAWEI.toLowerCase()) {
                    return Config.huaweiUrl;
                }
                return Config.googlePlayUrl;
            default:
                return Config.googlePlayUrl;
        }
    };

    const handleDownloadApp = () => {
        const getDeviceUrl = getDeviceAppURl();
        window.open(getDeviceUrl);
    };

    const handleSetPasswordTechnicalErrorHide = () => {
        dispatch(hidePasswordTechnicalErrorAction());
        isCheckPinSuccess();
    };

    const handleSetPinTechnicalErrorHide = () => {
        dispatch(hidePinTechnicalErrorAction());
        backToBegin();
    };

    return (
        <Fragment>
            {step === CREDIT_CARD_RESET_STEPS.otp.stepId && (
                <CCStepOTP
                    key={otpResendAttempt}
                    submitting={isCheckingCreditCardEligibility}
                    initialErrors={errors}
                    otpResendAttempt={otpResendAttempt}
                    onSubmit={handleOtpSubmit}
                    onExpiredExceed={handleExpiredExceed}
                    onNotSent={handleOtpNotSent}
                    onResend={handleOtpResend}
                    onInputAttempt={handleOtpInputAttempt}
                    onCancel={handleOtpCancel}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.creditCard.stepId && (
                <CCStepCard
                    submitting={isVerifyCreditCardPan}
                    isAttemptError={isCreitCardAttemptError}
                    initialErrors={errors}
                    onSubmit={handleCreditCardSubmit}
                    onCancel={handleCreditCardCancel}
                    onAttempt={handleCreditCardAttempt}
                    location={location}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.naturePurpose.stepId && (
                <NaturePurpose
                    initialErrors={errors}
                    onCancel={showConfirmBeforeExit}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.crs.stepId && (
                <CCStepTax
                    submitting={isCRSLoading}
                    onYes={handleCrsYes}
                    onNo={handleCrsNo}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.pin.stepId && (
                <CCStepPin
                    submitting={isCCSettingPin}
                    initialErrors={errors}
                    onSubmit={handlePinSubmit}
                    onCancel={handlePinCancel}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.password.stepId && (
                <CCStepPassword
                    submitting={isCCSettingPassword}
                    initialErrors={errors}
                    onSubmit={handlePasswordSubmit}
                    onCancel={handlePasswordCancel}
                    onDoLater={handleDolater}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.success.stepId && (
                <CCStepSuccess
                    onClick={handleDownloadApp}
                    location={location}
                />
            )}

            {step === CREDIT_CARD_RESET_STEPS.unSuccess.stepId && (
                <CCStepUnSuccess
                    onClick={handleDownloadApp}
                />
            )}

            <CommonExceptionModal
                onHide={handleCommonExceptionHide}
                onSubmit={handleCommonExceptionHide} />

            <NotCreditCardCustomerModal
                onHide={handleNotCreditCardCCustomerHide}
                onSubmit={handleNotCreditCardCCustomerHide}
                onLoginIB={handleBackLoginPage} />

            <BlockCCFeatureModal
                onHide={handleBlockCCFeatureHide}
                onSubmit={handleBlockCCFeatureHide} />

            <BlockCCProfileModal
                onHide={handleBlockCCProfileHide}
                onSubmit={handleBlockCCProfileHide} />

            <ConfirmBeforeExitModal
                onSubmit={handleConfirmBeforeExitOnSubmit}
                onHide={handleConfirmBeforeExitOnHide} />

            <NotActiveCreditCardModal
                onHide={handleNotActiveCreditCardHide}
                onSubmit={handleNotActiveCreditCardHide}
                location={location}
            />

            <CreditCardNotEligibleModal
                onHide={handleCreditCardNotEligibleHide}
                onSubmit={handleCreditCardNotEligibleHide} />

            <ConfirmPayTaxModal
                onHide={handleConfirmPayTaxHide}
                onSubmitNo={handleNoConfirmTax}
                onSubmitYes={handleYesConfirmTax} />

            <NotRemoveFICAModal
                onHide={handleCreditCardNotRemoveFicaHide}
                onLoginIB={handleCreditCardNotRemoveFicaHide} />

            <PasswordTechnicalErrorModal
                onHide={handleSetPasswordTechnicalErrorHide}
                onSubmit={handleSetPasswordTechnicalErrorHide} />

            <PinTechnicalErrorModal
                onHide={handleSetPinTechnicalErrorHide}
                onSubmit={handleSetPinTechnicalErrorHide} />

        </Fragment>
    );
};

export default CreditCardResetPin;
