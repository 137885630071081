import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { usePageTitle, useBodyClass } from '../../../utils/useHooks';
import { 
    CREDIT_CARD_ERROR_CODES,
    CREDIT_CARD_RESET_STEPS,
    BUTTON_ID,
    BUTTON_NAME
} from '../../../constants';
import { 
    validateCCPassword,
    getResetPinCreditCardSteps
} from '../../../utils/utils';
import { 
    moveCreditCardStepAction
 } from '../../../actions/application';
import StepBar from '../../../components/common/StepBar';
import Page from '../../../components/layout/Page';
import Header from '../../../components/layout/Header';
import headerImage from '../../../assets/images/header-12.png';
import TymeInputPassword from '../../../components/tyme-input/TymeInputPassword';
import PasswordCheck from '../../../components/password-check/PasswordCheck';
import TymeButton from '../../../components/common/TymeButton';
import TimeOut from '../../../components/common/TimeOut';
import * as SELECTORS from '../../../selectors';
import Loading from '../../../components/loading';

const CCStepPassword = ({ submitting = false, onCancel, onSubmit, onDoLater }) => {
    const dispatch = useDispatch();

    const errorCode = useSelector(SELECTORS.getPinErrorCode);
    const isCCSettingPasswordSuccess = useSelector(SELECTORS.getIsCCSettingPasswordSuccess);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(null);
    const [isDisabledButton, setIsDisabledButton] = useState(true);

    usePageTitle('Enter your Password | TymeBank');
    useBodyClass('credit-card');

    useEffect(() => {
        setErrorPassword(null);
        setErrorConfirmPassword(null);
    }, [password, confirmPassword]);

    useEffect(() => {
        if(isCCSettingPasswordSuccess) {
            if(errorCode === CREDIT_CARD_ERROR_CODES.CREDIT_CARD_PROFILE_STILL_HOLD){
                dispatch(moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.unSuccess.stepId));
            } else {
                dispatch(moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.success.stepId));
            }
        }
    }, [isCCSettingPasswordSuccess]);

    let messageNotMatch = 'The passwords entered do not match';

    const validate = () => {
        const validatePasswordResult = validateCCPassword(password);
        if (validatePasswordResult !== '') {
            return validatePasswordResult;
        }
        if (password !== confirmPassword) {
            return messageNotMatch;
        }
        return '';
    };

    const handleOnPasswordChange = e => {
        if(e.target.value !== '') {
            setIsDisabledButton(false);
        } else {
            setIsDisabledButton(true);
        }
        setPassword(e.target.value);
    };

    const handleOnConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };

    const handleOnConfirm = () => {
        const validateConfirmResult = validate();
        if (validateConfirmResult === '') {
            setErrorPassword(null);
            setErrorConfirmPassword(null);
            onSubmit(password);
        } else if (validateConfirmResult === messageNotMatch) {
            setErrorPassword(null);
            setErrorConfirmPassword({ message: validateConfirmResult });
        } else {
            setErrorPassword({ message: validateConfirmResult });
            setErrorConfirmPassword(null);
        }
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">And now let’s create your password</p>
                <p className="sub-heading">Your password is just for logging into internet banking.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.password.title}
                                currentStep={CREDIT_CARD_RESET_STEPS.password.stepId}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="step-creditcard-password">
                                <div className="step-creditcard-password__heading">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <p>Keep your password safe and don’t share it with anyone.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="step-creditcard-password__content">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6 col-md-5">
                                            <TymeInputPassword
                                                label="Password"
                                                placeholder="Enter your new password"
                                                value={password}
                                                autoComplete="off"
                                                autoFocus={true}
                                                handleChange={handleOnPasswordChange}
                                                error={errorPassword}
                                            />
                                            <TymeInputPassword
                                                label="Confirm password"
                                                placeholder="Re-enter your new password"
                                                value={confirmPassword}
                                                handleChange={handleOnConfirmPasswordChange}
                                                error={errorConfirmPassword}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-5 col-md-6 check-list">
                                            <PasswordCheck input={password} isCC={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="step-creditcard-password__footer">
                                    <div className="btn-control-container">
                                        <TymeButton
                                            id={BUTTON_ID.PASSWORD_CANCEL}
                                            name={BUTTON_NAME.CANCEL}
                                            className="btn tyme-btn btn-secondary"
                                            onClick={onCancel}
                                        >
                                            Cancel
                                        </TymeButton>
                                        <TymeButton
                                            id={BUTTON_ID.PASSWORD_CONFIRM}
                                            name={BUTTON_NAME.CONFIRM}
                                            disabled={isDisabledButton || submitting}
                                            onClick={handleOnConfirm}
                                        >
                                            Confirm
                                        </TymeButton>
                                    </div>
                                </div>
                                <div className="step-creditcard-password__link">
                                    <a onClick={onDoLater}>I’ll set up my password later</a>
                                    <p>You can set it up at any time from the Internet Banking login page.</p>
                                </div>
                                { submitting && <Loading /> }
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

CCStepPassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDoLater: PropTypes.func.isRequired
};

export default CCStepPassword;
