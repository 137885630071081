import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { requestOtpAction, verifyOtpAction } from '../../actions/otp';
import { Config } from '../../config';
import { INITIAL_OTP_INPUT_ATTEMPT, RESET_STEPS } from '../../constants';
import { useError, usePageTitle } from '../../utils/useHooks';
import { generateArrayValues, getResetPinShowSteps, makeStringFromNumbers } from '../../utils/utils';
import * as SELECTORS from '../../selectors';
import TymeButton from '../../components/common/TymeButton';
import InputOTP from '../../components/new-otp/InputOTP';
import InnerLoading from '../../components/common/InnerLoading';
import CanNotSendOTPModal from '../../components/new-otp/CanNotSendOTPModal';
import InputAttemptOTPModal from '../../components/new-otp/InputAttemptOTPModal';
import ResendAttemptOTPModal from '../../components/new-otp/ResendAttemptOTPModal';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import StepBar from '../../components/common/StepBar';
import CountDownCircle from '../../components/common/CountDownCircle';
import TimeOut from '../../components/common/TimeOut';
import headerImage from '../../assets/images/header-1.png';

const StepOTP = ({
    submitting = false,
    initialErrors,
    otpResendAttempt,
    onSubmit,
    onExpiredExceed,
    onNotSent,
    onResend,
    onInputAttempt,
    onCancel,
}) => {
    const requestToken = useSelector(SELECTORS.getRequestToken);
    const isRequestingOTP = useSelector(SELECTORS.getIsRequestingOTP);
    const isRequestOTPFailed = useSelector(SELECTORS.getIsRequestingOTPFailed);
    const cellphoneNumber = useSelector(SELECTORS.getCellphoneNumber);
    const isVerifyingOTP = useSelector(SELECTORS.getIsVerifyingOTP);
    const isVerifyOTPFailed = useSelector(SELECTORS.getIsVerifyingOTPFailed);
    const initialRemainingSeconds = useSelector(SELECTORS.getOtpRemainingSecond);
    const stepUpToken = useSelector(SELECTORS.getStepUpToken);

    const dispatch = useDispatch();

    const [remainingSeconds, setRemainingSeconds] = useState(initialRemainingSeconds);
    const [otps, setOtps] = useState(generateArrayValues(Config.otp.numberOfInput));
    const [errors, setErrors] = useError(initialErrors);
    const [numberOfInputAttempt, setNumberOfInputAttempt] = useState(INITIAL_OTP_INPUT_ATTEMPT);
    const [haveEmptyInput, setHaveEmptyInput] = useState(true);
    const [isInputAttemptError, setInputAttemptError] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [isExpiredExceed, setIsExpiredExceed] = useState(false);

    usePageTitle('Input OTP | TymeBank');
    useEffect(() => {
        dispatch(requestOtpAction({ requestToken }));
    }, []);
    useEffect(() => {
        setRemainingSeconds(initialRemainingSeconds);
    }, [initialRemainingSeconds]);
    useEffect(() => {
        let timeInterval;
        if (remainingSeconds !== null) {
            if (
                remainingSeconds > 0 &&
                !submitting &&
                !isExpired &&
                !isInputAttemptError &&
                !isExpiredExceed
            ) {
                // Count down
                timeInterval = setInterval(() => {
                    setRemainingSeconds(prevSeconds => {
                        return prevSeconds - 1;
                    });
                }, 1000);
            }
            if (remainingSeconds === 0) {
                otpResendAttempt === Config.otp.maxResend
                    ? setIsExpiredExceed(true)
                    : setIsExpired(true);
            }
        }

        return () => clearInterval(timeInterval);
    }, [remainingSeconds, submitting, isExpired, isInputAttemptError, isExpiredExceed]);
    useEffect(() => {
        const emptyItems = otps.filter(item => item.value === '');
        setHaveEmptyInput(emptyItems.length > 0);
    }, [otps]);
    useEffect(() => {
        stepUpToken && onSubmit(); // Verify OTP Success
    }, [stepUpToken]);
    useEffect(() => {
        if (isVerifyOTPFailed) {
            numberOfInputAttempt > Config.otp.maxInput - 1 && setInputAttemptError(true);
            switch (numberOfInputAttempt) {
                case 1:
                    setErrors({
                        message: `The OTP you entered is incorrect, please try again.`,
                    });
                    break;
                case 2:
                    setErrors({
                        message: `The OTP you entered is incorrect, you have one more chance to try again.`,
                    });
                    break;
                default:
                    break;
            }
            setOtps(generateArrayValues(Config.otp.numberOfInput));
            setNumberOfInputAttempt(prevAttempt => prevAttempt + 1);
        }
    }, [isVerifyOTPFailed]);

    const handleChange = otps => {
        setErrors(null);
        setOtps(otps);
    };
    const handleConfirm = () => {
        const otpCode = makeStringFromNumbers(otps);
        dispatch(
            verifyOtpAction({
                otpCode,
                requestToken,
            }),
        );
    };

    const percentage = Math.floor((remainingSeconds / Config.otp.timer) * 100);
    const isRequestOTPSuccess = !isRequestingOTP && !isRequestOTPFailed && remainingSeconds !== null;
    const isShowInput =
        isRequestOTPSuccess && !isInputAttemptError && !isExpiredExceed ;
    const isDisabledConfirm = haveEmptyInput || submitting || isExpired || isVerifyingOTP;

    return (
        <Page>
            <Header image={headerImage}>
                {!isRequestingOTP && (
                    <div className="step-otp__header">
                        <CountDownCircle
                            percentage={percentage}
                            remainingSeconds={remainingSeconds}
                        />
                    </div>
                )}
                <p className="heading">Enter your One-Time-Pin (OTP)</p>
                <p>To verify your cellphone number, enter the OTP sent to {cellphoneNumber}</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    {!isRequestingOTP && (
                        <div className="row">
                            <div className="col-xs-12">
                                <StepBar
                                    label={RESET_STEPS.otp.title}
                                    currentStep={RESET_STEPS.otp.stepProgress}
                                    totalStep={getResetPinShowSteps().length}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-otp step-otp-container">
                                {isRequestingOTP && <InnerLoading label="Sending OTP" />}
                                {isShowInput && (
                                    <div className="step-otp__main">
                                        <p className="step-otp__heading">
                                            For your security, this OTP will expired in{' '}
                                            {Config.otp.timer} seconds. You need to enter it as soon
                                            as you receive it.
                                        </p>
                                        <div className="step-otp__input-container">
                                            <InputOTP
                                                otps={otps}
                                                errors={errors}
                                                disabled={isExpired}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="step-otp__input-container">
                                            {isExpired && (
                                                <TymeButton
                                                    className="btn tyme-btn btn-link"
                                                    onClick={onResend}>
                                                    RESEND OTP
                                                </TymeButton>
                                            )}
                                        </div>
                                        <div className="btn-control-container">
                                            <TymeButton
                                                className="btn tyme-btn btn-secondary"
                                                disabled={submitting || isVerifyingOTP}
                                                onClick={onCancel}>
                                                Cancel
                                            </TymeButton>
                                            <TymeButton
                                                isLoading={submitting || isVerifyingOTP}
                                                disabled={isDisabledConfirm}
                                                onClick={handleConfirm}>
                                                Confirm
                                            </TymeButton>
                                        </div>
                                    </div>
                                )}
                                <CanNotSendOTPModal
                                    isShow={isRequestOTPFailed}
                                    onSubmit={onNotSent}
                                    onHide={onNotSent}
                                />
                                <ResendAttemptOTPModal
                                    isShow={isExpiredExceed}
                                    onSubmit={onExpiredExceed}
                                    onHide={onExpiredExceed}
                                />
                                <InputAttemptOTPModal
                                    isShow={isInputAttemptError}
                                    onSubmit={onInputAttempt}
                                    onHide={onInputAttempt}
                                />
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepOTP.propTypes = {
    submitting: PropTypes.bool,
    initialErrors: PropTypes.object,
    requestToken: PropTypes.string,
    isRequestingOTP: PropTypes.bool,
    cellphoneNumber: PropTypes.string,
    isRequestOTPFailed: PropTypes.bool,
    isVerifyingOTP: PropTypes.bool,
    isVerifyOTPFailed: PropTypes.bool,
    initialRemainingSeconds: PropTypes.number,
    stepUpToken: PropTypes.string,
    otpResendAttempt: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onNotSent: PropTypes.func.isRequired,
    onResend: PropTypes.func.isRequired,
    onInputAttempt: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onExpiredExceed: PropTypes.func.isRequired,
};

export default StepOTP;
