import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../../actions';
import { Config } from '../../../config';
import {
    MCQ_DATA_REQUEST,
    MCQ_DATA_REQUEST_CAPTURE,
    MCQ_DATA_REQUEST_ERROR,
} from '../../../actions/types';
import { getUuid } from '../../../utils/utils';

export const mcqDataRequest = (args, callback) => ({
    type: MCQ_DATA_REQUEST,
    payload: { args, callback },
});

export const mcqDataRequestEpic = action$ =>
    action$.ofType(MCQ_DATA_REQUEST).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.getMCQ}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
            },
            body: {
                saId: action.payload.args.saId,
                stepUpToken: sessionStorage.getItem('stepUpToken'),
            },
        })
            .map(({ response }) => {
                action.payload.callback(response);

                return {
                    type: MCQ_DATA_REQUEST_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(MCQ_DATA_REQUEST_ERROR, xhr));
            }),
    );
