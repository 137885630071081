import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { RESET_STEPS, INITIAL_OTP_RESEND_ATTEMPT, RESET_CREDENTIAL_TYPE } from '../../constants';
import { searchSaidAction } from '../../actions/searchSaid';
import { verifyCardPanAction } from '../../actions/card';
import { checkEligibilityAction } from '../../actions/eligibility';
import { verifyMCQAction } from '../../actions/mcq';
import { setCredentialAction } from '../../actions/setCredential';
import {
    hideCommonExceptionAction,
    hideBlockFeatureAction,
    hideBlockProfileAction,
    showConfirmBeforeExitAction,
    hideConfirmBeforeExitAction,
    moveStepResetPinAction,
    showSkipMCQResetPinAction,
    hideSkipMCQResetPinAction,
} from '../../actions/application';
import { Config } from '../../config';
import * as SELECTORS from '../../selectors';
import StepSAID from '../reset/StepSAID';
import StepOTP from '../reset/StepOTP';
import StepCard from '../reset/StepCard';
import StepReset from './StepReset';
import StepMCQ from '../reset/StepMCQ';
import StepSuccess from '../reset/StepSuccess';
import CommonExceptionModal from '../../components/modals/CommonExceptionModal';
import BlockFeatureModal from '../../components/modals/BlockFeatureModal';
import BlockProfileModal from '../../components/modals/BlockProfileModal';
import ConfirmBeforeExitModal from '../../components/modals/ConfirmBeforeExitModal';
import SkipMCQResetPinModal from '../../components/modals/SkipMQCResetPinModal';
import header11 from '../../assets/images/header-11.png';

const ResetPin = ({ history }) => {
    const step = useSelector(SELECTORS.getResetPinStep);
    const errors = useSelector(SELECTORS.getResetPinErrors);
    const isSearchingSaid = useSelector(SELECTORS.getIsSearchingSaid);
    const said = useSelector(SELECTORS.getSaid);
    const isCheckingEligibility = useSelector(SELECTORS.getIsCheckingEligibility);
    const isVerifyCardPan = useSelector(SELECTORS.getIsVerifyCardPan);
    const isCardAttemptError = useSelector(SELECTORS.getIsCardAttemptError);
    const isSettingCredential = useSelector(SELECTORS.getIsSettingCredential);
    const isVerifyMCQ = useSelector(SELECTORS.getIsVerifyMCQ);
    const isVerifyMCQFailed = useSelector(SELECTORS.getIsVerifyMCQFailed);
    const isMCQAttemptError = useSelector(SELECTORS.getIsMCQAttempError);
    const stepUpToken = useSelector(SELECTORS.getStepUpToken);
    const isTurnOnMCQStep = process.env.REACT_APP_RESET_PIN_PASSWORD_MCQ === 'on';

    const dispatch = useDispatch();

    const [otpResendAttempt, setOtpResendAttempt] = useState(INITIAL_OTP_RESEND_ATTEMPT);

    const backToBegin = () => {
        // Stay out of flash change
        _.delay(history.push, 250, '/reset-credentials');
    };
    const navigateToInternetBanking = () => {
        window.location = Config.loginUrl;
    };

    const navigateToFindKiosk = () => {
        window.location = Config.findKioskUrl;
    };

    const showConfirmBeforeExit = () => dispatch(showConfirmBeforeExitAction());
    const handleConfirmBeforeExitOnHide = () => dispatch(hideConfirmBeforeExitAction());
    const handleConfirmBeforeExitOnSubmit = () => {
        dispatch(hideConfirmBeforeExitAction());
        backToBegin();
    };

    const handleSaidSubmit = said => {
        dispatch(searchSaidAction({ said, from: RESET_CREDENTIAL_TYPE.PIN }));
    };
    const handleSaidCancel = () => {
        showConfirmBeforeExit();
    };

    const handleOtpSubmit = () => {
        dispatch(checkEligibilityAction({ said, from: RESET_CREDENTIAL_TYPE.PIN }));
    };
    const handleOtpNotSent = () => {
        backToBegin();
    };
    const handleOtpResend = () => {
        setOtpResendAttempt(prevAttempt => prevAttempt + 1);
    };
    const handleOtpInputAttempt = () => {
        backToBegin();
    };
    const handleOtpCancel = () => {
        showConfirmBeforeExit();
    };
    const handleExpiredExceed = () => {
        backToBegin();
    };

    const handleCardSubmit = cardPAN => {
        dispatch(
            verifyCardPanAction({ said, cardPAN, stepUpToken, from: RESET_CREDENTIAL_TYPE.PIN }),
        );
    };
    const handleCardCancel = () => {
        showConfirmBeforeExit();
    };
    const handleCardAttempt = () => {
        backToBegin();
    };

    const handleSwitchToMCQ = () => {
        if(isTurnOnMCQStep) {
            dispatch(moveStepResetPinAction(RESET_STEPS.mcq.stepId));
        } else {
            dispatch(showSkipMCQResetPinAction());
        }
    };

    const handleVerifyMCQ = ({ creditAnswers, sessionId }) => {
        dispatch(
            verifyMCQAction({
                said,
                creditAnswers,
                sessionId,
                stepUpToken,
                from: RESET_CREDENTIAL_TYPE.PIN,
            }),
        );
    };
    const handleMCQCancel = () => {
        showConfirmBeforeExit();
    };
    const handleEmptyAnswer = () => {
        backToBegin();
    };
    const handleFailMCQ = () => {
        backToBegin();
    };
    const handleMCQAttempt = () => {
        backToBegin();
    };

    const handleRequestMCQFail = () => {
        backToBegin();
    };

    const handleResetSubmit = newPin => {
        dispatch(
            setCredentialAction({
                newCredential: newPin,
                type: RESET_CREDENTIAL_TYPE.PIN,
                said,
                stepUpToken,
                from: RESET_CREDENTIAL_TYPE.PIN,
            }),
        );
    };
    const handleResetCancel = () => {
        showConfirmBeforeExit();
    };

    const handleSuccessSubmit = () => {
        navigateToInternetBanking();
    };

    const handleCommonExceptionHide = () => {
        dispatch(hideCommonExceptionAction());
        backToBegin();
    };

    const handleSkipMCQHide = () => {
        dispatch(hideSkipMCQResetPinAction());
        navigateToInternetBanking();
    };

    const handleBlockFeatureHide = () => {
        dispatch(hideBlockFeatureAction());
        backToBegin();
    };

    const handleBlockProfileHide = () => {
        dispatch(hideBlockProfileAction());
        backToBegin();
    };

    return (
        <Fragment>
            {step === RESET_STEPS.said.stepId && (
                <StepSAID
                    submitting={isSearchingSaid}
                    initialErrors={errors}
                    onSubmit={handleSaidSubmit}
                    onCancel={handleSaidCancel}
                />
            )}
            {step === RESET_STEPS.otp.stepId && (
                <StepOTP
                    key={otpResendAttempt}
                    submitting={isCheckingEligibility}
                    initialErrors={errors}
                    otpResendAttempt={otpResendAttempt}
                    onSubmit={handleOtpSubmit}
                    onExpiredExceed={handleExpiredExceed}
                    onNotSent={handleOtpNotSent}
                    onResend={handleOtpResend}
                    onInputAttempt={handleOtpInputAttempt}
                    onCancel={handleOtpCancel}
                />
            )}
            {step === RESET_STEPS.card.stepId && (
                <StepCard
                    submitting={isVerifyCardPan}
                    isAttemptError={isCardAttemptError}
                    initialErrors={errors}
                    onSubmit={handleCardSubmit}
                    onCancel={handleCardCancel}
                    onAttempt={handleCardAttempt}
                    onSwitch={handleSwitchToMCQ}
                />
            )}
            {step === RESET_STEPS.mcq.stepId && isTurnOnMCQStep && (
                <StepMCQ
                    submitting={isVerifyMCQ}
                    isFailMCQ={isVerifyMCQFailed}
                    isAttemptError={isMCQAttemptError}
                    said={said}
                    from={RESET_CREDENTIAL_TYPE.PIN}
                    onSubmit={handleVerifyMCQ}
                    onCancel={handleMCQCancel}
                    onEmptyAnswer={handleEmptyAnswer}
                    onFailMCQ={handleFailMCQ}
                    onAttempt={handleMCQAttempt}
                    onRequestFail={handleRequestMCQFail}
                    onRequestMCQFail={navigateToFindKiosk}
                />
            )}
            {step === RESET_STEPS.reset.stepId && (
                <StepReset
                    submitting={isSettingCredential}
                    initialErrors={errors}
                    onSubmit={handleResetSubmit}
                    onCancel={handleResetCancel}
                />
            )}
            {step === RESET_STEPS.success.stepId && (
                <StepSuccess
                    heading="You've successfully reset your login PIN"
                    notification="You are now able to login with your new login PIN."
                    onSubmit={handleSuccessSubmit}
                    headerImage={header11}
                />
            )}
            <CommonExceptionModal
                onHide={handleCommonExceptionHide}
                onSubmit={handleCommonExceptionHide}
            />

            <SkipMCQResetPinModal onHide={handleSkipMCQHide} onSubmit={handleSkipMCQHide} />

            <BlockFeatureModal onHide={handleBlockFeatureHide} onSubmit={handleBlockFeatureHide} />
            <BlockProfileModal onHide={handleBlockProfileHide} onSubmit={handleBlockProfileHide} />
            <ConfirmBeforeExitModal
                onSubmit={handleConfirmBeforeExitOnSubmit}
                onHide={handleConfirmBeforeExitOnHide}
            />
        </Fragment>
    );
};

export default ResetPin;
