import { createAction } from 'redux-actions';

export const SET_CREDENTIAL = 'SET_CREDENTIAL';
export const SET_CREDENTIAL_SUCCESS_RESET_PIN = 'SET_CREDENTIAL_SUCCESS_RESET_PIN';
export const SET_CREDENTIAL_FAILED_RESET_PIN = 'SET_CREDENTIAL_FAILED_RESET_PIN';
export const SET_CREDENTIAL_SUCCESS_RESET_PASSWORD = 'SET_CREDENTIAL_SUCCESS_RESET_PASSWORD';
export const SET_CREDENTIAL_FAILED_RESET_PASSWORD = 'SET_CREDENTIAL_FAILED_RESET_PASSWORD';

export const setCredentialAction = createAction(SET_CREDENTIAL);
export const setCredentialSuccessResetPinAction = createAction(SET_CREDENTIAL_SUCCESS_RESET_PIN);
export const setCredentialSuccessResetPasswordAction = createAction(SET_CREDENTIAL_SUCCESS_RESET_PASSWORD);
