import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    VERIFY_CARD_PAN,
    setCardAttemptErrorAction,
    verifyCardPanFailedResetPinAction,
    verifyCardPanSuccessResetPinAction,
    verifyCardPanSuccessResetPasswordAction,
    verifyCardPanFailedResetPasswordAction,
} from '../actions/card';
import { RESET_CREDENTIAL_TYPE, RESET_ERROR_CODES } from '../constants';
import { showCommonExceptionAction } from '../actions/application';

export const verifyCardPanEpic = (action$, store, { verifyCardService }) =>
    action$.pipe(
        ofType(VERIFY_CARD_PAN),
        switchMap(action => {
            const { said, cardPAN, stepUpToken, from } = action.payload;
            return verifyCardService({ said, cardPAN, stepUpToken, type: from }).pipe(
                map(() => {
                    switch (from) {
                        case RESET_CREDENTIAL_TYPE.PIN:
                            return verifyCardPanSuccessResetPinAction();
                        case RESET_CREDENTIAL_TYPE.PASSWORD:
                            return verifyCardPanSuccessResetPasswordAction();
                        default:
                            return Observable.of(showCommonExceptionAction());
                    }
                }),
                catchError(error => {
                    if (error.response && error.response.status !== 401) {
                        const { errors } = error.response.data;
                        const errorCode = errors && errors[0].errorCode;
                        switch (errorCode) {
                            case RESET_ERROR_CODES.CARD_PAN_FAILURE_FIRST:
                                return Observable.of(
                                    verifyCardPanFailedResetPinAction({
                                        message: `Incorrect, please try again.`,
                                    }),
                                    verifyCardPanFailedResetPasswordAction({
                                        message: `Incorrect, please try again.`,
                                    }),
                                );
                            case RESET_ERROR_CODES.CARD_PAN_FAILURE_SECOND:
                                return Observable.of(
                                    verifyCardPanFailedResetPinAction({
                                        message: `Incorrect, you have one more chance to try again.`,
                                    }),
                                    verifyCardPanFailedResetPasswordAction({
                                        message: `Incorrect, you have one more chance to try again.`,
                                    }),
                                );
                            case RESET_ERROR_CODES.CARD_PAN_FAILURE_DISABLED:
                                return Observable.of(setCardAttemptErrorAction());
                            default:
                                return Observable.of(showCommonExceptionAction());
                        }
                    }
                    return Observable.of(showCommonExceptionAction());
                }),
            );
        }),
    );
