import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPE, CUSTOMER_SERVICE_CENTRE } from '../../constants';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../../components/common/TymeButton';

export const InputAttemptOTPModal = ({ isShow = false, onHide, onSubmit }) => {
    return (
        <ModalTemplate
            title="We are not able to verify you"
            type={MODAL_TYPE.ERROR}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p>You have entered an incorrect OTP too many times. Please try again later.</p>
                <p>
                    For further assistance, please call our Customer Service Centre on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton onClick={onSubmit}>OK</TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

InputAttemptOTPModal.propTypes = {
    isShow: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default InputAttemptOTPModal;
