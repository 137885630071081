import { MCQ_DATA_REQUEST_CAPTURE, MCQ_DATA_REQUEST_ERROR } from '../../../actions/types';

export default function(state = {}, action) {
    switch (action.type) {
        case MCQ_DATA_REQUEST_CAPTURE:
            return { ...state, data: action.payload, error: undefined };
        case MCQ_DATA_REQUEST_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
}
