import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { Config } from '../../config';
import { MAP_EVENT_CATEGORY } from '../../constants';

export default function(WrappedComponent, options = {}) {
    class GoogleTagManager extends Component {
        componentDidMount() {
            if (this.props.location) {
                const page = this.props.location.pathname;
                this.setTagManagerArgs(page);
            }
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.location && nextProps.location) {
                const currentPage = this.props.location.pathname;
                const nextPage = nextProps.location.pathname;

                if (currentPage !== nextPage) {
                    this.setTagManagerArgs(nextPage);
                }
            }
        }

        setTagManagerArgs = page => {
            const tagManagerArgs = {
                gtmId: Config.googleTagManager.gtmId,
                dataLayer: {
                    page,
                    ...options,
                },
                dataLayerName: 'PageDataLayer',
            };

            TagManager.dataLayer(tagManagerArgs);
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return GoogleTagManager;
}


export function EventButtonTracker(id, name) {
    const tagManagerArgs = {
        gtmId: Config.googleTagManager.gtmId,
        dataLayer: {
            event: 'Click-Button',
            category: MAP_EVENT_CATEGORY[window.location.pathname],
            action: name,
            label: id,
        },
        dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgs);
}
