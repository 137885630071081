import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    OTP_GENERATE,
    OTP_GENERATED,
    OTP_GENERATE_ERROR,
    OTP_VERIFY,
    OTP_VERIFIED,
    OTP_VERIFY_ERROR,
    OTP_RESET_STATE,
} from '../../actions/types';
import { getUuid } from '../../utils/utils';

export const generateOtp = (args, callback) => ({
    type: OTP_GENERATE,
    payload: { args, callback },
});

export const generateOtpEpic = action$ =>
    action$.ofType(OTP_GENERATE).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpGenerate}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
                'Delivery-Method': 'SMS',
            },
            body: {
                requestToken: action.payload.args.requestToken,
            },
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: OTP_GENERATED,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(OTP_GENERATE_ERROR, xhr));
            }),
    );

export const verifyOtp = (args, callback) => ({ type: OTP_VERIFY, payload: { args, callback } });

export const verifyOtpEpic = action$ =>
    action$.ofType(OTP_VERIFY).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpVerify}`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
                'Delivery-Method': 'SMS',
            },
            body: {
                otpCode: action.payload.args.otpCode,
                requestToken: action.payload.args.requestToken,
            },
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: OTP_VERIFIED,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(OTP_VERIFY_ERROR, xhr));
            }),
    );

export const resetState = () => ({ type: OTP_RESET_STATE });
