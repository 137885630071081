import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowNotCreditCardCustomer} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';
export const NotCreditCardCustomerModal = ({ onHide, onSubmit, onLoginIB }) => {
    const isShowNotCreditCardCustomer = useSelector(getIsShowNotCreditCardCustomer);
    
    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_NOT_CREDIT_CARD_CUSTOMER}
            title="You can’t set up login details right now"
            type={MODAL_TYPE.ERROR}
            isShow={isShowNotCreditCardCustomer}
            handleHide={onHide}>
            <div>
                <p>
                    Either you’re not signed up for this service, or your login details have already been set up so you should be able to log in now. If you’ve forgotten your login details, go back to the previous step and tap Forgot your Password or Login PIN.
                </p>
                <p>
                    If you’ve forgotten your login details, go back to the previous step and tap <b>Forgot your Password or Login PIN?</b>.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.NOT_CUSTOMER_CLOSE}
                        name={BUTTON_NAME.CLOSE}
                        className="btn tyme-btn btn-secondary"
                        onClick={onSubmit}>
                        Close
                    </TymeButton>
                    <TymeButton
                        id={BUTTON_ID.NOT_CUSTOMER_BACK_LOGIN}
                        name={BUTTON_NAME.BACK_LOGIN}
                        className="btn tyme-btn btn-primary btn-back-login"
                        onClick={onLoginIB}>
                        Login
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

NotCreditCardCustomerModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoginIB: PropTypes.func.isRequired,
};

export default NotCreditCardCustomerModal;
