import {
    SEARCH_SAID,
    SEARCH_SAID_FAILED_RESET_PIN,
    SEARCH_SAID_SUCCESS_RESET_PIN,
    SEARCH_SAID_SUCCESS_RESET_PASSWORD,
    SEARCH_SAID_FAILED_RESET_PASSWORD,
    SEARCH_SAID_FAILED_NOT_ACTIVATED_RESET_PASSWORD,
} from '../actions/searchSaid';

const initialState = {
    isSearchingSaid: false,
    said: '',
    requestToken: '',
    isNotActivated: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SAID:
            return {
                ...state,
                isSearchingSaid: true,
            };
        case SEARCH_SAID_SUCCESS_RESET_PIN:
            return {
                ...state,
                said: action.payload.said,
                requestToken: action.payload.requestToken,
                isSearchingSaid: false,
            };
        case SEARCH_SAID_FAILED_RESET_PIN:
            return {
                ...state,
                said: '',
                requestToken: '',
                isSearchingSaid: false,
            };
        case SEARCH_SAID_SUCCESS_RESET_PASSWORD:
            return {
                ...state,
                said: action.payload.said,
                requestToken: action.payload.requestToken,
                isSearchingSaid: false,
            };
        case SEARCH_SAID_FAILED_RESET_PASSWORD:
            return {
                ...state,
                said: '',
                requestToken: '',
                isSearchingSaid: false,
            };
        case SEARCH_SAID_FAILED_NOT_ACTIVATED_RESET_PASSWORD:
            return {
                said: action.payload.said,
                requestToken: '',
                isSearchingSaid: false,
                isNotActivated: true
            }
        default:
            return state;
    }
};
