import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { $emit } from 'event-bus-e2z';
// import Recaptcha from 'react-google-invisible-recaptcha';
import { isPersonalLoan, usePageTitle, useBodyClass } from '../../utils/useHooks';
import i18n from '../../i18n';
import translationKeys from '../../i18n/translationKeys';
import TymeButton from '../../components/common/TymeButton';
import headerImage from '../../assets/images/header-1.png';
import headerPL from '../../assets/images/header-11.png';
// import Loading from '../../components/loading';
import { Config } from '../../config';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import { verifyRecaptcha } from '../../actions/recaptcha';
import CommonExceptionModal from '../../components/modals/CommonExceptionModal';
import ConfirmBeforeExitModal from '../../components/modals/ConfirmBeforeExitModal';
import {
    hideCommonExceptionAction,
    showConfirmBeforeExitAction,
    hideConfirmBeforeExitAction
} from '../../actions/application';

import { resetAllState } from '../../actions';

import { BUTTON_ID, BUTTON_NAME } from '../../constants';

const copyText = i18n.t(translationKeys.creditCardSelection, { returnObjects: true }).Selection;

const CreditCardSelection = ({
    // verifyRecaptcha,
    loading,
    success,
    history,
    failed,
    ...props
}) => {
    const dispatch = useDispatch();
    const searchCCSaid = useSelector(state => state.searchCCSaid);

    const isPL = isPersonalLoan(props)
    const subHeading = isPL ? 'Congrats on your Personal Loan!' : copyText.subHeading;
    const buttonNext = isPL ? 'Set up login details' : `Let's do it`;
    const activeText = isPL ? 'Your Personal Loan reference number from your signed loan agreement' : copyText.body.list.active;
    const cellText = isPL ? 'Access to the cell number used in your loan application' : copyText.body.list.cell;

    usePageTitle(isPL ? "Personal Loan" : "Credit Card | TymeBank");
    useBodyClass('credit-card');

    useEffect(() => {
        if (searchCCSaid.requestToken || searchCCSaid.sessionId) {
            return
        }
        $emit("movePageStart")
    }, [searchCCSaid.requestToken, searchCCSaid.sessionId])

    const onNextEnterSaid = () => {
        if (!isPL) {
            history.push(Config.appRoutes.CREDIT_CARD_SET_PIN_PASSWORD);
        } else {
            history.push(Config.appRoutes.PERSONAL_LOAN_CODE);
        }
    };

    const handleCommonExceptionHide = () => {
        dispatch(hideCommonExceptionAction());
    };

    const backToBegin = () => {
        // Stay out of flash change
        dispatch(resetAllState());
        setTimeout(() => $emit("movePageStart"), 250)
    };

    const showConfirmBeforeExit = () => dispatch(showConfirmBeforeExitAction());
    const handleConfirmBeforeExitOnHide = () => dispatch(hideConfirmBeforeExitAction());

    const handleConfirmBeforeExitOnSubmit = () => {
        dispatch(hideConfirmBeforeExitAction());
        backToBegin();
    };

    const onNavigateToInternetBanking = () => {
        showConfirmBeforeExit();
    };

    return (
        <Page className="credit-card-selection">
            <Header className="selection-header" image={!isPL ? headerImage : headerPL}>
                <p>{subHeading}</p>
                <p className="heading">{copyText.heading}</p>
                <p className="sub-heading">{copyText.description}</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="selection-content">
                                <div className="you-need-info">
                                    <p>{copyText.body.note}</p>
                                    <ul>
                                        <li>{copyText.body.list.said}</li>
                                        <li>{activeText}</li>
                                        <li>{cellText}</li>
                                    </ul>
                                </div>
                                {!isPL && <strong className="question">{copyText.body.question}</strong>}
                                {!isPL && <p className="contact">It’s easy! Just call <strong>0861 007 250</strong> and follow the prompts. By the end of the call, your card should be activated and you can come back here to set up your Login PIN and password.</p>}
                            </div>
                            <div className="btn-control-container">
                                <TymeButton
                                    id={BUTTON_ID.START_SESSION_CANCEL}
                                    name={BUTTON_NAME.CANCEL}
                                    className="btn tyme-btn btn-secondary"
                                    onClick={onNavigateToInternetBanking}>
                                    Cancel
                                </TymeButton>
                                <TymeButton
                                    id={BUTTON_ID.LET_DO_IT}
                                    name={BUTTON_NAME.LET_DO_IT}
                                    className="btn tyme-btn btn-primary btn-selection"
                                    onClick={onNextEnterSaid}
                                >
                                    {buttonNext}
                                </TymeButton>
                            </div>
                            {/* <Recaptcha
                                ref={(captcha) => {
                                    recaptcha = captcha;
                                }}
                                sitekey={Config.googleRecaptcha.sitekey}
                                onResolved={onResolved}
                            /> */}
                            {/* {loading && <Loading />} */}
                            <CommonExceptionModal
                                onHide={handleCommonExceptionHide}
                                onSubmit={handleCommonExceptionHide}
                            />
                            <ConfirmBeforeExitModal
                                onSubmit={handleConfirmBeforeExitOnSubmit}
                                onHide={handleConfirmBeforeExitOnHide}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}

const mapStateToProps = ({
    newReCaptcha: {
        loading,
        success,
        failed
    },
    state
}) => ({
    state,
    loading,
    success,
    failed
});

const mapDispatchToProps = {
    verifyRecaptcha
};

export default translate()(connect(mapStateToProps, mapDispatchToProps)(CreditCardSelection));