import React from 'react';
import PropTypes from 'prop-types';
import { usePageTitle, useBodyClass } from '../../../utils/useHooks';
import DownloadApp from '../../../components/common/DownloadApp';
export const CCStepSuccess = ({ onClick }) => {
    usePageTitle('Credit Card  Successfull | TymeBank');
    useBodyClass('page-infomation');

    return (
        <div className="step-information step-information_unsuccessful">
            <div className="container">
                <div className="row row-no-gutters">
                    <div className="col-xs-12">
                        <div className="step-information__heading">
                            <span>Great!</span> We’re just busy processing your info.
                            <p className="sub-text">We’ll send you an  SMS/email soon updating you on your account status</p>
                        </div>
                        <div className="step-information__note">What to do once your account is active:</div>
                        <DownloadApp onClick={onClick} />
                    </div>
                </div>
            </div>
        </div>
    );
};

CCStepSuccess.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default CCStepSuccess;
