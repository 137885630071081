import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    generateArrayValues as generateArrayPin,
    checkConsecutive,
    checkRepeat,
    makeStringFromNumbers as makePinCode,
    getResetPinShowSteps,
} from '../../utils/utils';
import { Config } from '../../config';
import { usePageTitle, useError } from '../../utils/useHooks';
import { RESET_STEPS} from '../../constants';
import InputOTP from '../../components/new-otp/InputOTP';
import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';
import StepBar from '../../components/common/StepBar';
import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import headerImage from '../../assets/images/header-11.png';

const arrayPin = generateArrayPin(Config.pin.numberOfInput);
const arrayConfirmPin = generateArrayPin(Config.pin.numberOfInput);
const validateEmptyPins = pins => pins.filter(pin => pin.value === '').length > 0;

const StepReset = ({ initialErrors, submitting = false, onCancel, onSubmit }) => {
    const [pins, setPins] = useState(arrayPin);
    const [confirmPins, setConfirmPins] = useState(arrayConfirmPin);
    const [isDisabledButtonNext, setIsDisabledButtonNext] = useState(true);
    const [errors, setErrors] = useError(initialErrors);

    usePageTitle('Reset Pin | TymeBank');

    useEffect(() => {
        setErrors(null);
        setIsDisabledButtonNext(true);
        const isPinsEmpty = validateEmptyPins(pins);
        const isConfirmPinsEmpty = validateEmptyPins(confirmPins);
        if (!isPinsEmpty) {
            const pinValues = pins.map(pin => Number(pin.value));
            if (checkConsecutive(pinValues)) {
                setErrors({
                    pins: {
                        message: 'Your PIN cannot be in number order. e.g. 1234. Please try again.',
                    },
                });
                return;
            }
            if (checkRepeat(pinValues)) {
                setErrors({
                    pins: {
                        message: 'Your PIN cannot use repeat numbers. e.g. 1111. Please try again.',
                    },
                });
                return;
            }
            if (!isConfirmPinsEmpty) {
                const pinsString = makePinCode(pins);
                const confirmPinsString = makePinCode(confirmPins);
                const isMatch = pinsString === confirmPinsString;
                !isMatch &&
                    setErrors({
                        confirmPins: { message: 'The login PINs entered do not match' },
                    });
                isMatch && setIsDisabledButtonNext(false);
            }
        }
    }, [pins, confirmPins]);

    const handleConfirm = () => {
        onSubmit(makePinCode(pins));
    };

    return (
        <Page>
            <Header image={headerImage}>
                <p className="heading">Create your new login PIN</p>
                <p className="mb-0">Now enter and confirm your new login PIN.</p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <StepBar
                                label={RESET_STEPS.reset.title}
                                currentStep={RESET_STEPS.reset.stepId}
                                totalStep={getResetPinShowSteps().length}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 resetPin resetPin-container">
                            <div className="step-reset step-reset-container">
                                <p className="step-reset__heading">
                                    Your login PIN will be used to login to Internet Banking, Smart
                                    App, Kiosk, and USSD. Don't share this login PIN with anyone.
                                </p>
                                <div className="step-reset__main">
                                    <InputOTP
                                        otps={pins}
                                        onChange={setPins}
                                        errors={errors && errors.pins}
                                        label="Login PIN"
                                    />
                                    <InputOTP
                                        otps={confirmPins}
                                        onChange={setConfirmPins}
                                        errors={errors && errors.confirmPins}
                                        enableAutoFocus={false}
                                        label="Confirm login PIN"
                                    />
                                </div>
                                <div className="step-reset__footer">
                                    <div className="btn-control-container">
                                        <TymeButton
                                            className="btn tyme-btn btn-secondary"
                                            disabled={submitting}
                                            onClick={onCancel}>
                                            Cancel
                                        </TymeButton>
                                        <TymeButton
                                            disabled={isDisabledButtonNext || submitting}
                                            isLoading={submitting}
                                            onClick={handleConfirm}>
                                            Confirm
                                        </TymeButton>
                                    </div>
                                </div>
                                <TimeOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

StepReset.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default StepReset;
