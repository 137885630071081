import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

let key = 0;
export class Textbox extends Component {
    static propTypes = {
        placeholder: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        maxlength: PropTypes.string.isRequired,
        rule: PropTypes.string,
    };

    static defaultProps = {
        rule: '',
    };

    onKeyDown = e => {
        key = e.keyCode;
    };

    renderField = field => {
        const {
            meta: { error },
        } = field;
        const className = `form-group ${error || field.error ? 'has-error' : ''}`;

        if (typeof field.rule !== 'undefined') {
            if (field.rule === 'characters') {
                // allow only upper and lower case characters
                field.input.value = field.input.value.replace(/[^a-zA-Z]/g, '');
            } else if (field.rule === 'numbers') {
                // allow only numbers
                field.input.value = field.input.value.replace(/[^\d]/g, '');
            } else if (field.rule === 'id') {
                // allow id format and only numbers
                field.input.value = field.input.value.replace(/[^\d\s]/g, '');

                if (field.input.value.length === 7 && key !== 8) {
                    field.input.value = `${field.input.value.substr(
                        0,
                        6,
                    )} ${field.input.value.substr(6, 1)}`;
                } else if (field.input.value.length === 12 && key !== 8) {
                    field.input.value = `${field.input.value.substr(
                        0,
                        11,
                    )} ${field.input.value.substr(11, 1)}`;
                } else if (field.input.value.length === 15 && key !== 8) {
                    field.input.value = `${field.input.value.substr(
                        0,
                        14,
                    )} ${field.input.value.substr(14, 1)}`;
                }

                if (
                    field.input.value.substr(field.input.value.length - 1, 1) === ' ' &&
                    key === 8
                ) {
                    field.input.value = field.input.value.substr(0, field.input.value.length - 1);
                }
            } else if (field.rule === 'msisdn') {
                // allow msisdn format and only numbers
                field.input.value = field.input.value.replace(/[^\d\s]/g, '');

                if (field.input.value.length === 4 && key !== 8) {
                    field.input.value = `${field.input.value.substr(
                        0,
                        3,
                    )} ${field.input.value.substr(3, 1)}`;
                } else if (field.input.value.length === 8 && key !== 8) {
                    field.input.value = `${field.input.value.substr(
                        0,
                        7,
                    )} ${field.input.value.substr(7, 1)}`;
                }

                if (
                    field.input.value.substr(field.input.value.length - 1, 1) === ' ' &&
                    key === 8
                ) {
                    field.input.value = field.input.value.substr(0, field.input.value.length - 1);
                }
            }
        }

        field.input.value = field.empty ? field.inputValue : field.input.value;

        return (
            <fieldset className={className}>
                <input
                    {...field.input}
                    {..._.omit(field, [
                        'input',
                        'meta',
                        'maxlength',
                        'inputRef',
                        'inputValue',
                        'empty',
                        'rule',
                        'error',
                    ])}
                    type={field.type ? field.type : 'text'}
                    maxLength={field.maxlength}
                    ref={field.inputRef}
                    onClick={field.onClick ? field.onClick : field.input.onClick}
                    onKeyDown={field.onKeyDown ? field.onKeyDown : this.onKeyDown}
                    autoComplete="off"
                />
                {(error || field.error) && (
                    <p className="help-block">{!error ? field.error : error}</p>
                )}
            </fieldset>
        );
    };

    renderNumberField = field => {
        const {
            meta: { error },
        } = field;
        const className = `form-group ${error || field.error ? 'has-error' : ''}`;

        field.input.value = field.empty ? field.inputValue : field.input.value;

        return (
            <fieldset className={className}>
                <NumberFormat
                    {...field.input}
                    {..._.omit(field, [
                        'input',
                        'meta',
                        'maxlength',
                        'inputRef',
                        'inputValue',
                        'empty',
                        'rule',
                        'error',
                        'numberType',
                        'fieldFormat',
                    ])}
                    type={field.type ? field.type : 'text'}
                    format={field.fieldFormat}
                />
                {(error || field.error) && (
                    <p className="help-block">{!error ? field.error : error}</p>
                )}
            </fieldset>
        );
    };

    render() {
        if (this.props.numberType === 'true') {
            return <Field {..._.omit(this.props, [])} component={this.renderNumberField} />;
        }

        return <Field {..._.omit(this.props, [])} component={this.renderField} />;
    }
}

export default Textbox;
