import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowSkipMCQResetPassword } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const SkipMCQResetPasswordModal = ({ onHide, onSubmit }) => {
    const isShow = useSelector(getIsShowSkipMCQResetPassword);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_SKIP_MCQ_RESET_PASSWORD}
            title="You’ll need a debit card in order to reset your password"
            type={MODAL_TYPE.UNDEFINED}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p>Use your active debit card to reset your password on Internet Banking.</p>
                <p>
                    If you don’t have an active card, you’ll need to go to a Kiosk and get a new
                    debit card, then follow the above process.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.SKIP_MCQ_RESET_PASSWORD_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}>
                        OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

SkipMCQResetPasswordModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default SkipMCQResetPasswordModal;
