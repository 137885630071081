import React from 'react';
import PropTypes from 'prop-types';
import InputCardPANItem from './InputCardPANItem';

import { useInputVerificationCode } from '../../utils/useHooks';

const InputCardPAN = ({ enableAutoFocus = true, numbers, label, errors, onChange, isPL }) => {
    const [numberRefs, handleChange] = useInputVerificationCode(numbers, onChange);

    return (
        <div className="input-card-pan-container">
            <label className="input-card-pan__header">{label}</label>
            <div className={`input-card-pan__main ${errors ? 'has-error' : ''}`}>
                {numbers.map((item, index) => (
                    <InputCardPANItem
                        key={item.id}
                        inputValue={item.value}
                        isPL={isPL}
                        onChange={({ target: { value } }) => handleChange(item.id, value, isPL)}
                        autoFocus={enableAutoFocus && item.id === 0}
                        ref={ref => (numberRefs.current[index] = ref)}
                    />
                ))}
            </div>
            <div className="help-block">{errors ? errors.message : ''}</div>
        </div>
    );
};

InputCardPAN.propTypes = {
    enableAutoFocus: PropTypes.bool,
    numbers: PropTypes.array.isRequired,
    label: PropTypes.string,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

export default InputCardPAN;
