import { applyMiddleware, createStore, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './epics';
import rootReducer from './reducers';
import axiosSetup from './axiosInterceptor';
import {
    searchSaidService,
    requestOTPService,
    verifyOTPService,
    checkEligibilityService,
    verifyCardService,
    setCredentialService,
    requestMCQService,
    verifyMCQService,
    getTimeService,
    getTimeAccessTokenService,
    verifyRecaptchaService,
    searchCCSaidService,
    captureTCService,
    verifyCreditCardService,
    captureTaxService,
    creditCardSetCredentialService
} from './api';

const initialState = {};
const enhancers = [];
const dependencies = {
    searchSaidService,
    requestOTPService,
    verifyOTPService,
    checkEligibilityService,
    verifyCardService,
    setCredentialService,
    requestMCQService,
    verifyMCQService,
    getTimeService,
    getTimeAccessTokenService,
    verifyRecaptchaService,
    searchCCSaidService,
    captureTCService,
    verifyCreditCardService,
    captureTaxService,
    creditCardSetCredentialService
};
// TODO: Do we need reduxThunk?
const middleware = [createEpicMiddleware(rootEpic, { dependencies }), reduxThunk];

if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-underscore-dangle */
    if (
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function'
    ) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

axiosSetup(store);

export default store;
