import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { $emit } from 'event-bus-e2z';

export const newRequireAuth = (WrappedComponent) => {
    const Wrapper = ({ authorized, ...rest }) => {
        useEffect(() => {
            if (!authorized) {
                const { history, location } = rest;
                if(location.pathname.indexOf('profile-activation') !== -1)  {
                    setTimeout(() => $emit("movePageStart"))
                } else {
                    history.push('/');
                }
            }
        }, []);
        return <WrappedComponent {...rest} />;
    };

    const mapStateToProps = ({ newReCaptcha: { token } }) => {
        return {
            authorized: token,
        };
    };

    return connect(mapStateToProps)(Wrapper);
};

export default newRequireAuth;
