import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    getResetPinCreditCardSteps,
} from '../../utils/utils';

import {
    CREDIT_CARD_RESET_STEPS,
    BUTTON_ID,
    BUTTON_NAME,
} from '../../constants';

import { useError, usePageTitle, useBodyClass } from '../../utils/useHooks';
import { moveCreditCardStepAction, showCommonExceptionAction } from '../../actions/application';
import * as SELECTORS from '../../selectors';

import TymeButton from '../../components/common/TymeButton';
import TimeOut from '../../components/common/TimeOut';

import Page from '../../components/layout/Page';
import Header from '../../components/layout/Header';
import StepBar from '../../components/common/StepBar';
import Loading from '../../components/loading';

import NaturePurposeBox from './NaturePurposeBox';
import { useCaptureNaturePose, useFetchNaturePurpose } from './useApiNaturePurpose';

import headerImagePL from '../../assets/images/header-11.png';

export const NaturePurpose = ({
    initialErrors,
    onCancel,
    // ...props,
}) => {
    const [errors] = useError(initialErrors);
    const sessionId = useSelector(SELECTORS.getCreditCardSessionId);

    const [selectionNatures, setSelectionNatures] = useState([]);
    const [selectionPurposes, setSelectionPurposes] = useState([]);
    const [isReady, setReady] = useState(false);

    const dispatch = useDispatch();

    const {
        fetchData,
        data,
        error: isFechingError,
        loading,
    } = useFetchNaturePurpose();

    const {
        data: resulsSaveData,
        postData,
        error: isSaveError,
        loading: isLoadingPost
    } = useCaptureNaturePose({ sessionId, natures: selectionNatures, purposes: selectionPurposes });

    usePageTitle('Enter your Personal Loan | Natures and purposes');
    useBodyClass('credit-card');

    const isSelectionError = selectionNatures.length === 0 || selectionPurposes.length === 0;

    const dataNature = data.filter(item => item.type === 'NATURE' && !item.code.includes('ALL_OF_'));
    const dataPurpose = data.filter(item => item.type === 'PURPOSE' && !item.code.includes('ALL_OF_'));

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isFechingError || isSaveError) {
            dispatch(showCommonExceptionAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFechingError, isSaveError]);

    useEffect(() => {
        if (resulsSaveData.status) {
            dispatch(moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.crs.stepId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resulsSaveData])


    const handleConfirm = () => {
        setReady(true);
        if (isSelectionError) {
            return;
        }
        postData();
    };

    return (
        <Page>
            <Header image={headerImagePL}>
                <p className="heading">
                    Nature and Purpose of relationship
                </p>
            </Header>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 credit-card-step-bar">
                            <StepBar
                                label={CREDIT_CARD_RESET_STEPS.creditCard.title}
                                replaceStep={4}
                                totalStep={getResetPinCreditCardSteps().length}
                            />
                        </div>
                    </div>
                    <div className="nature-purpose-container">
                        <div className="nature-purpose_content">
                            <NaturePurposeBox
                                title="I want an account for"
                                subTitle="Select all options that apply"
                                name="natures"
                                listItem={dataNature}
                                selection={selectionNatures}
                                handleChange={setSelectionNatures}
                            />

                            <NaturePurposeBox
                                title="I use the account to"
                                subTitle="Select all options that apply"
                                name="purposes"
                                listItem={dataPurpose}
                                selection={selectionPurposes}
                                handleChange={setSelectionPurposes}
                            />
                        </div>

                        {isSelectionError && isReady && <div className='nature-purpose_label_error'>Please select your answers from both sets of options.</div>}

                        <div className="btn-control-container">
                            <TymeButton
                                id={BUTTON_ID.CARD_CANCEL}
                                name={BUTTON_NAME.CANCEL}
                                className="btn tyme-btn btn-secondary"
                                onClick={onCancel}
                            >
                                Cancel
                            </TymeButton>

                            <TymeButton
                                id={BUTTON_ID.CARD_NEXT}
                                name={BUTTON_NAME.NEXT}
                                disabled={errors || loading || isLoadingPost || isFechingError}
                                onClick={handleConfirm}
                            >
                                Next
                            </TymeButton>
                        </div>
                    </div>
                    {(loading || isLoadingPost) && <Loading />}
                    <TimeOut />
                </div>
            </div>
        </Page>
    );
};

NaturePurpose.propTypes = {
    initialErrors: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
};

export default NaturePurpose;

