import React from 'react';
import PasswordCheckItem from './PasswordCheckItem';

export const haveLowerCharacter = input => input.search(/[a-z]/) !== -1;
export const haveUppercaseCharacter = input => input.search(/[A-Z]/) !== -1;
export const haveNumber = input => input.search(/[0-9]/) !== -1;
export const haveSpecialCharacter = input => input.search(/[^a-zA-Z0-9]/) !== -1;
export const haveMinLength = input => input.length >= 8;

export const PasswordCheck = ({ input, isCC }) => {
    return (
        <div className="check-list__container">
            <p className="check-list__heading">Your password must contain:</p>
            <PasswordCheckItem
                text="At least one lowercase letter"
                isFulfill={haveLowerCharacter(input)}
                isCC={isCC}
            />
            <PasswordCheckItem
                text="One uppercase letter"
                isFulfill={haveUppercaseCharacter(input)}
                isCC={isCC}
            />
            <PasswordCheckItem 
                text="One number" 
                isFulfill={haveNumber(input)}
                isCC={isCC}
            />
            <PasswordCheckItem
                text="One special character (e.g.#*%)"
                isFulfill={haveSpecialCharacter(input)}
                isCC={isCC}
            />
            <PasswordCheckItem 
                text="At least 8 characters" 
                isFulfill={haveMinLength(input)}
                isCC={isCC}
            />
        </div>
    );
};

export default PasswordCheck;
