import React from 'react';
import PropTypes from 'prop-types';
import { isPersonalLoan, usePageTitle, useBodyClass } from '../../../utils/useHooks';
import DownloadApp from '../../../components/common/DownloadApp';

export const CCStepSuccess = ({ onClick, ...props }) => {
    const isPL = isPersonalLoan(props)

    usePageTitle(isPL ? "Personal Loan Successfull" : "Credit Card Successfull | TymeBank");
    useBodyClass('page-infomation');

    return (
        <div className="step-information">
            <div className="container">
                <div className="row row-no-gutters">
                    <div className="col-xs-12">
                        <div className="step-information__heading"><span>Nice job!</span> Now you can manage your account by simply logging into your TymeBank profile.</div>
                        <div className="step-information__note">What’s next?</div>
                        <DownloadApp onClick={onClick} />
                    </div>
                </div>
            </div>
        </div>
    );
};

CCStepSuccess.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default CCStepSuccess;
