import {
    REQUEST_MCQ,
    REQUEST_MCQ_SUCCESS,
    REQUEST_MCQ_FAILED_RESET_PASSWORD,
    REQUEST_MCQ_FAILED_RESET_PIN,
    VERIFY_MCQ,
    VERIFY_MCQ_SUCCESS_RESET_PIN,
    VERIFY_MCQ_FAILED_RESET_PIN,
    VERIFY_MCQ_SUCCESS_RESET_PASSWORD,
    VERIFY_MCQ_FAILED_RESET_PASSWORD,
    SET_MCQ_ATTEMPT_ERROR,
    HIDE_REQUEST_MCQ_FAILED_MODAL,
    SHOW_REQUEST_MCQ_FAILED_MODAL,
} from '../actions/mcq';

const initialState = {
    isRequestMCQ: false,
    isRequestMCQFailResetPassword: false,
    isRequestMCQFailResetPin: false,
    isShowRequestMCQFailedModal: false,
    isVerifyMCQ: false,
    isVerifyMCQFailed: false,
    isMCQAttemptError: false,
    mcq: {
        sessionId: '',
        questions: null,
    },
    requestMCQFail: {
        errorCode: undefined,
        resetType: undefined,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_MCQ:
            return {
                ...state,
                isRequestMCQ: true,
            };
        case REQUEST_MCQ_SUCCESS: {
            const { sessionId, questions } = action.payload;
            return {
                ...state,
                isRequestMCQ: false,
                mcq: {
                    sessionId,
                    questions,
                },
            };
        }
        case REQUEST_MCQ_FAILED_RESET_PASSWORD:
            return {
                ...state,
                isRequestMCQ: false,
                isRequestMCQFailResetPassword: true,
            };
        case REQUEST_MCQ_FAILED_RESET_PIN:
            return {
                ...state,
                isRequestMCQ: false,
                isRequestMCQFailResetPin: true,
            };
        case VERIFY_MCQ:
            return {
                ...state,
                isVerifyMCQ: true,
            };
        case VERIFY_MCQ_SUCCESS_RESET_PIN:
            return {
                ...state,
                isVerifyMCQ: false,
            };
        case VERIFY_MCQ_FAILED_RESET_PIN:
            return {
                ...state,
                isVerifyMCQ: false,
                isVerifyMCQFailed: true,
            };
        case VERIFY_MCQ_SUCCESS_RESET_PASSWORD:
            return {
                ...state,
                isVerifyMCQ: false,
            };
        case VERIFY_MCQ_FAILED_RESET_PASSWORD:
            return {
                ...state,
                isVerifyMCQ: false,
                isVerifyMCQFailed: true,
            };
        case SET_MCQ_ATTEMPT_ERROR:
            return {
                ...state,
                isMCQAttemptError: true,
            };
        case SHOW_REQUEST_MCQ_FAILED_MODAL: {
            const { errorCode, resetType } = action.payload;
            return {
                ...state,
                isShowRequestMCQFailedModal: true,
                requestMCQFail: {
                    errorCode,
                    resetType,
                },
            };
        }
        case HIDE_REQUEST_MCQ_FAILED_MODAL:
            return {
                ...state,
                isShowRequestMCQFailedModal: false,
                requestMCQFail: {
                    errorCode: undefined,
                    resetType: undefined,
                },
            };
        default:
            return state;
    }
};
