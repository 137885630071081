import i18n from 'i18next';

i18n
    .init({
        resources: {
            eng: {
                translations: {
                    button: {
                        login: 'Login',
                        ok: 'OK',
                        next: 'Next',
                        cancel: 'Cancel',
                        confirm: 'Confirm',
                        createPassword: 'Create password',
                        tryAgain: 'Try again',
                        resendOtp: 'Resend OTP',
                        activate: 'Activate my banking',
                        register: 'Register',
                        resetPassword: 'Reset password',
                        resetPin: 'Reset login PIN',
                        continue: 'Continue',
                        exit: 'Exit',
                    },
                    errors: {
                        attempt: '({{attempt}} of 3 attempts)',
                        generic: {
                            heading: 'Oops, something went wrong',
                            body: `An unexpected error has occurred.
                            
                                For further assistance, please call our Customer Service Centre on {{customerServiceNumber}}.`,
                            errorCodeLabel: 'Error code: ',
                            errorCode: '{{errorCode}}',
                        },
                    },
                    password: {
                        heading: 'Setup your Internet Banking password',
                        description: 'Please enter the password you will be using to login to Internet Banking.',
                        body: {
                            bold: 'Make sure that no one can see you enter your password.',
                            normal: 'Don\'t share this password with anyone.',
                        },
                        enterPassword: 'Password',
                        confirmPassword: 'Confirm Password',
                        errors: {
                            empty: 'Please enter your Password',
                            noMatch: 'Your password does not match. Please try again.',
                        },
                        modals: {
                            success: {
                                heading: 'Password succesfully set',
                                body: 'Your password has been successfully set and you will now be able to login to your Internet Banking profile.',
                            },
                        },
                    },
                    otp: {
                        verify: {
                            heading: 'OTP Verification',
                            body: 'Enter the One-Time-PIN (OTP) we have sent as an SMS to {{cellNumber}}.',
                            label: 'Enter OTP',
                            errors: {
                                invalid: `The OTP you have entered is incorrect, please try again. ({{attempt}} remaining)`,
                            },
                        },
                        verifyFail: {
                            heading: 'We are not able to verify you',
                            body: `You have failed to enter a correct OTP. The action you were trying to perform has been cancelled. Please try again.

                                For further assistance, please call our Customer Service Centre on {{customerServiceNumber}}.`,
                        },
                        resendLimit: {
                            heading: 'One-Time-PIN (OTP) limit exceeded',
                            body: `You have exceeded your OTP resend attempts.

                                If you are experiencing any issues you can call our Customer Service Centre on {{customerServiceNumber}}.`,
                        },
                        expired: {
                            heading: 'Expired One-Time-PIN (OTP)',
                            body: `The OTP sent to your cellphone number {{cellNumber}} has expired.

                                You need to enter the OTP sent to your cellphone as soon as you receive it. \
                                ({{attempt}} of 3 attempts)`,
                        },
                        sendFail: {
                            heading: 'OTP not sent',
                            body: `Our system could not send an SMS to your cellphone number. 
                             
                                For assistance, please contact us on {{customerServiceNumber}}.`,
                        },
                        pageTitle: 'Input OTP | TymeBank',
                    },
                    mcq: {
                        start: {
                            heading: 'We need to ask you a few questions to verify it\'s you.',
                            description: '',
                            body: 'Your ID number has been submitted to Home Affairs.',
                            bodyPassword: 'For your protection we are going to ask you a few questions to verify your identity before we reset your password',
                            bodyPin: 'For your protection we are going to ask you a few questions to verify your identity before we reset your PIN',
                            btnNext: 'Next',
                            errors: {
                                noMcqs: 'Unable to retrieve identity confirmation questions, please try again',
                                fidesCommon: {
                                    heading: 'We seem to have a problem',
                                    body: {
                                        non_critical: `Our team is aware of the problem and is working on it. ({{errorCode}})

                                        Please try again later. If the problem continues, contact us on {{customerServiceNumber}}.`,
                                        critical: `We are unable to reset your {{item}}. ({{errorCode}})

                                        For further assistance, please call our Customer Service Centre on {{customerServiceNumber}} or try again later.`,
                                    },
                                },
                            },
                        },
                        select: {
                            heading: 'Question {{selection}} of {{questions}}',
                            description: 'In order to verify your identity, please select your full name',
                        },
                    },
                    enterIdPin: {
                        heading: 'Let\'s activate your Internet Banking profile',
                        prompt: 'For your security, we need to create an additional password for your profile to login to Internet Banking.',
                        body: 'For security purposes, we require that you enter your ID number and TymeBank login PIN.',
                        idLabel: 'Enter your ID number',
                        placeholder: 'e.g. 920123 1234 12 3',
                        errors: {
                            invalid: 'Invalid ID number, please try again.',
                        },
                        pinLabel: 'Enter your login PIN',
                        modals: {
                            registered: {
                                heading: 'Password already created',
                                body: `The ID number you entered is already associated with an existing TymeBank bank \
                                    account and your Internet Banking profile is already active.
                                
                                    Please login or reset your password. Alternatively call our Customer Service Centre \
                                    on {{customerServiceNumber}} for more information.`,
                            },
                            invalid: {
                                heading: 'Combination of ID and PIN number do not match',
                                body: `Please ensure that you've entered the correct ID number and PIN. \
                                    Please try again.
                                    
                                    If you do not have a TymeBank account yet, please register to enjoy the full \
                                    benefits of your account.
                                    
                                    For further assistance, please call our Customer Service Centre on {{customerServiceNumber}}.`,
                            },
                        },
                    },
                    reset: {
                        information: {
                            heading: 'Forgotten your password or login PIN?',
                            description: 'If you have forgotten your password or login PIN, you can reset it here.',
                            body: {
                                copy: `We need to gather your information to reset your password or login PIN.
                                
                                    Before you begin, have the following handy:`,
                                handy: {
                                    said: 'Your South African ID number',
                                    cell: 'Your cellphone number linked to your TymeBank account',
                                },
                            },
                        },
                        informationWithoutPIN: {
                            heading: 'Forgotten your password?',
                            description: 'If you have forgotten your password, you can reset it here.',
                            body: {
                                copy: `We need to gather your information to reset your password.
                                
                                    Before you begin, have the following handy:`,
                                handy: {
                                    said: 'Your South African ID number',
                                    cell: 'Your cellphone number linked to your TymeBank account',
                                },
                            },
                        },
                        enterId: {
                            pageTitle: 'Enter your ID number | TymeBank',
                            heading: 'Let\'s reset your Internet Banking password',
                            pinHeading: 'Let\'s reset your PIN',
                            body: 'For security purposes, we require that you enter your South African ID number.',
                            idLabel: 'Enter your ID number',
                            placeholder: 'e.g. 920123 1234 12 3',
                            errors: {
                                invalid: 'Invalid ID number, please try again.',
                            },
                            pinLabel: 'Enter your login PIN',
                            modals: {
                                invalid: {
                                    heading: 'It seems like you haven\'t entered a valid South African ID number',
                                    body: `Your 13 digit South African ID number tells us who you are. Please try again.
                                            
                                        For further assistance, please call our Customer Service Centre on {{customerServiceNumber}}.`,
                                },
                                profileNotFound: {
                                    heading: 'Oops, something went wrong',
                                    body: 'We could not find your profile at this moment. Please try again later. (404)',
                                },
                            },
                            identify: 'To identify you, please enter your South African ID number.'
                        },
                    },
                    timeout: {
                        heading: 'Are you still there?',
                        body: 'If you need help, please read our <a href="https://www.tymebank.co.za/help-support" target="_blank"><strong>FAQs</strong></a> or call our Customer Service Center at <strong>0860 999 119</strong>. Please continue if you need more time.',
                    },
                    footer: {
                        security: 'Your security is extremely important to us. All information is constantly protected.',
                        data: 'No mobile data costs using this site',
                        branding: 'TymeBank',
                    },
                    connectionTimeout: {
                        heading: 'Connection timed out',
                        body: `The server is taking longer than expected to respond.
                        
                        Please try again later.`,
                    },
                    resetPassword: {
                        heading: 'Reset your Internet Banking password',
                        description: 'Please enter the new password you will use to login to Internet Banking.',
                        body: {
                            bold: 'Make sure that no one can see you enter your password.',
                            normal: 'Don\'t share this password with anyone.',
                        },
                        enterPassword: 'Password',
                        confirmPassword: 'Confirm Password',
                        errors: {
                            empty: 'Please enter your Password',
                            noMatch: 'Your password does not match. Please try again.',
                        },
                        modals: {
                            error: {
                                heading: 'We are having trouble verifying your personal details.',
                                body: 'Unfortunately we are experiencing some technical difficulties. Please try again later.',
                            },
                            success: {
                                heading: 'Password reset successful',
                                body: 'Your password has been successfully reset, you will be able to login with your new password.',
                            },
                        },
                    },
                    resetPin: {
                        heading: 'Reset your Internet Banking PIN',
                        description: 'Please enter the new PIN you will use to login to Internet Banking.',
                        rules: 'Your PIN must be 4 digits, may not be in a number order (e.g. 1234), all the same numbers (e.g. 1111) nor your date of birth.',
                        label: {
                            create: 'Create PIN',
                            confirm: 'Confirm PIN',
                        },
                        body: {
                            bold: 'Make sure that no one can see you enter your login PIN.',
                            normal: 'Don\'t share this PIN with anyone.',
                        },
                        enterPin: 'PIN',
                        confirmPin: 'Confirm PIN',
                        modals: {
                            error: {
                                heading: 'We are having trouble verifying your personal details.',
                                body: 'Unfortunately we are experiencing some technical difficulties. Please try again later.',
                            },
                            success: {
                                heading: 'PIN reset successful',
                                body: 'Your PIN has been successfully reset, you will be able to login with your new PIN.',
                            },
                        },
                        errors: {
                            consecutive: 'Your PIN cannot be in number order. e.g. 1234. Please try again.',
                            same: 'Your PIN cannot use repeat numbers. e.g. 1111. Please try again.',
                            noMatch: 'Your PIN does not match. Please try again.',
                        },
                        card: {
                            pageTitle: 'Input Card Detail | TymeBank',
                        },
                    },
                    creditCardSelection: {
                        Selection: {
                            heading: 'Now let’s setup your login details',
                            subHeading: 'Congrats on your TymeBank credit card!',
                            description: 'You’ll only need to do this once! Your Login PIN will allow you to manage your account using the TymeBank app, TymeBank kiosks and USSD. Your password will give you access to internet banking.',
                            body: {
                                note: 'All you need is:',
                                list: {
                                    said: 'Your South African ID number',
                                    active: 'Your activated TymeBank credit card',
                                    cell: 'Access to the cell number used to register your credit card',
                                },
                                question: 'Haven’t activated your credit card yet?',
                            },
                        }
                    },
                    welcomeNewUser: {
                        heading: "To use Internet Banking, you’ll first need to create a password",
                        steps: {
                            title: "You’ll need the following to create a password:",
                            first: "Your SA ID number",
                            second: "TymeBank Login PIN",
                            third: "One-Time PIN (OTP) that will be sent to the cellphone number registered to your TymeBank account"
                        },
                        warning: "Don’t share your password with anyone. This will help keep your account safe and secure."
                    },
                },
            },
        },
        fallbackLng: 'eng',
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });

export default i18n;
