import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MODAL_TYPE, MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowSkipMCQResetPin } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';

export const SkipMCQResetPinModal = ({ onHide, onSubmit }) => {
    const isShow = useSelector(getIsShowSkipMCQResetPin);

    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_SKIP_MCQ_RESET_PIN}
            title="We’ll need to verify your identity to continue"
            type={MODAL_TYPE.UNDEFINED}
            isShow={isShow}
            handleHide={onHide}>
            <div>
                <p className="list-options">There are a few ways you can do that:</p>
                <ul>
                    <li>Reset your PIN at a Kiosk, or</li>
                    <li>Reset your PIN on TymeBank app, or</li>
                    <li>Use your active debit card to reset your PIN on Internet Banking.</li>
                </ul>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.SKIP_MCQ_RESET_PIN_OK}
                        name={BUTTON_NAME.OK}
                        onClick={onSubmit}>
                        OK
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

SkipMCQResetPinModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default SkipMCQResetPinModal;
