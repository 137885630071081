import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../actions';
import { Config } from '../config';
import {
    GET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_GOOGLE_TIME_ERROR,
    GET_GOOGLE_TIME,
    GET_GOOGLE_TIME_SUCCESS,
} from '../actions/types';
import { verifyRecaptchaEpic } from '../containers/recaptcha/actions';
import { submitSaIdPinEpic } from '../containers/enter-id-pin/actions';
import { generateOtpEpic, verifyOtpEpic } from '../components/otp/actions';
import { submitPasswordEpic, activationLogoutEpic } from '../containers/password/actions';
import { searchSaIdEpic } from '../containers/reset-credentials/enter-id/actions';
import { mcqDataRequestEpic } from '../containers/reset-credentials/mcq/mcq-actions';
import { resetPasswordEpic } from '../containers/reset-credentials/password/actions';
import { resetPinEpic } from '../containers/reset-credentials/pin/actions';

import { searchSaidEpic } from './searchSaidEpic';
import { requestOtpEpic, verifyOTPEpic } from './otpEpic';
import { checkEligibilityEpic } from './checkEligibilityEpic';
import { verifyCardPanEpic } from './cardPanEpic';
import { setCredentialEpic } from './setCredentialEpic';
import { requestMCQEpic, verifyMCQEpic } from './mcqEpic';

import { newVerifyRecaptchaEpic } from './reCaptChaEpic';
import { searchCCSaidEpic } from './searchCCSaidEpic';
import { captureTCEpic } from './captureTCEpic';
import { verifyCreditCardPanEpic } from './creditCardPanEpic' ;
import { captureTaxEpic } from './captureTaxEpic';
import { setCredentialCCPinEpic } from './setCredentialCCPinEpic';
import { setCredentialCCPasswordEpic } from './setCredentialCCPasswordEpic';

import {
    searchSaidSuccessResetPinEpic,
    checkEligibilitySuccessResetPinEpic,
    verifyCardPanSuccessResetPinEpic,
    setCredentialSuccessResetPinEpic,
    verifyMCQSuccessResetPinEpic,
    requestMCQFailedResetPinEpic,
    requestMCQFailedResetPasswordEpic,
} from './resetPinEpic';

import { getUuid } from '../utils/utils';

const accessTokenEpic = action$ =>
    action$.ofType(GET_ACCESS_TOKEN).mergeMap(action => {
        let body;
        let url;
        let headers;

        switch (action.payload.access) {
            case 'time':
                url = `${Config.rootUrl}/token`;
                body = { ...Config.tokenBody };
                headers = {
                    ...Config.timeTokenHeaders,
                    'Correlation-Id': getUuid(),
                };
                break;
            default:
                url = `${Config.rootUrl}/token`;
                body = { ...Config.registeredTokenBody };
                headers = {
                    ...Config.registeredTokenHeaders,
                    'Correlation-Id': getUuid(),
                };
                break;
        }

        return Observable.ajax({
            url,
            method: 'POST',
            crossDomain: true,
            headers,
            body,
        })
            .map(response => {
                action.payload.callback({ ...response, type: action.payload.access });

                return {
                    type: GET_ACCESS_TOKEN_SUCCESS,
                    payload: { ...response, type: action.payload.access },
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(GET_ACCESS_TOKEN_ERROR, xhr));
            });
    });

const getGoogleTimeEpic = action$ =>
    action$.ofType(GET_GOOGLE_TIME).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.time}/${Config.apiVersion}${Config.apiUri.time}`,
            method: 'GET',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('timeAccessToken')}`,
                'Correlation-Id': getUuid(),
            },
            responseType: 'xml',
        })
            .map(response => {
                action.payload(response);

                return {
                    type: GET_GOOGLE_TIME_SUCCESS,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload(xhr);

                return Observable.of(errorHelper(GET_GOOGLE_TIME_ERROR, xhr));
            }),
    );

const rootEpic = combineEpics(
    verifyRecaptchaEpic,
    accessTokenEpic,
    getGoogleTimeEpic,
    submitSaIdPinEpic,
    generateOtpEpic,
    verifyOtpEpic,
    submitPasswordEpic,
    searchSaIdEpic,
    mcqDataRequestEpic,
    resetPasswordEpic,
    resetPinEpic,
    activationLogoutEpic,

    searchSaidEpic,
    searchSaidSuccessResetPinEpic,
    requestOtpEpic,
    verifyOTPEpic,
    checkEligibilityEpic,
    checkEligibilitySuccessResetPinEpic,
    verifyCardPanEpic,
    verifyCardPanSuccessResetPinEpic,
    setCredentialEpic,
    setCredentialSuccessResetPinEpic,
    requestMCQEpic,
    requestMCQFailedResetPinEpic,
    requestMCQFailedResetPasswordEpic,

    verifyMCQEpic,
    verifyMCQSuccessResetPinEpic,
    
    newVerifyRecaptchaEpic,
    searchCCSaidEpic,
    captureTCEpic,
    verifyCreditCardPanEpic,
    captureTaxEpic,
    setCredentialCCPinEpic,
    setCredentialCCPasswordEpic
);

export default rootEpic;
