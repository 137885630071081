import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE } from '../../constants';
import { getIsShowBlockFeature } from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';
import { Config } from '../../config';

const isTurnOnResetPin = Config.resetPinToggle === 'on';

export const BlockFeatureModal = ({ onHide, onSubmit }) => {
    const isShowBlockFeature = useSelector(getIsShowBlockFeature);

    return (
        <ModalTemplate
            title="Too many unsuccessful attempts"
            type={MODAL_TYPE.ERROR}
            isShow={isShowBlockFeature}
            handleHide={onHide}>
            <div>
                <p>
                    For security reasons we are unable to reset your {isTurnOnResetPin ? 'login PIN or' : ''} password at this
                    time due to too many unsuccessful attempts.
                </p>
                <p>
                    Please try again in 24 hours or contact Customer Service Centre on{' '}
                    <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span> to unlock your
                    account.
                </p>
                <div className="btn-control-container">
                    <TymeButton onClick={onSubmit}>OK</TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

BlockFeatureModal.propTypes = {
    isShowBlockFeature: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default BlockFeatureModal;
