import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../../actions';
import { Config } from '../../../config';
import {
    RESET_PASSWORD_SUBMIT,
    RESET_PASSWORD_CAPTURE,
    RESET_PASSWORD_ERROR,
} from '../../../actions/types';
import { getUuid } from '../../../utils/utils';

export const resetPassword = (args, callback) => ({
    type: RESET_PASSWORD_SUBMIT,
    payload: { args, callback },
});

export const resetPasswordEpic = action$ =>
    action$.ofType(RESET_PASSWORD_SUBMIT).mergeMap(action =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.reset}/${Config.apiVersion}${Config.apiUri.setCredentialWithMCQ}?type=password`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': getUuid(),
            },
            body: {
                sessionId: action.payload.args.sessionId,
                saId: sessionStorage.getItem('idNumber'),
                newPin: action.payload.args.password,
                creditAnswers: action.payload.args.mcqAnswers,
                stepUpToken: sessionStorage.getItem('stepUpToken'),
            },
            responseType: 'xml',
        })
            .map(response => {
                action.payload.callback(response);

                return {
                    type: RESET_PASSWORD_CAPTURE,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(RESET_PASSWORD_ERROR, xhr));
            }),
    );
