import {
    CAPTURE_TC,
    CAPTURE_TC_SUCCESS,
    CAPTURE_TC_FAILED,
} from '../actions/captureTC';

import {
    SHOW_COMMON_EXCEPTION
} from '../actions/application';

const initialState = {
    success: false,
    failed: false,
    loading: false,
    consent: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CAPTURE_TC:
            return {
                ...state,
                loading: true
            };
        case CAPTURE_TC_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                consent: true,
            };
        case CAPTURE_TC_FAILED:
            return {
                ...state,
                loading: false,
                failed: true
            };

        case SHOW_COMMON_EXCEPTION:
            return {
                loading: false
            }
        default:
            return state;
    }
};
