import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_CENTRE, MODAL_TYPE,  MODAL_ID, BUTTON_ID, BUTTON_NAME } from '../../constants';
import { getIsShowNotRemoveFica} from '../../selectors';
import ModalTemplate from '../layout/ModalTemplate';
import TymeButton from '../common/TymeButton';
export const NotRemoveFICAModal = ({ onHide, onLoginIB }) => {
    const isShowNotRemoveFica = useSelector(getIsShowNotRemoveFica);
    
    return (
        <ModalTemplate
            id={MODAL_ID.MODAL_NOT_REMOVE_FICA}
            title="We can’t set up login details for you yet"
            type={MODAL_TYPE.ERROR}
            isShow={isShowNotRemoveFica}
            handleHide={onHide}>
            <div>
                <p>
                You’re a little early! You need to wait for an email/SMS with instructions on setting up your login details. Once you’ve received it, follow the link to finish the process.
                </p>
                <p>
                    For more help with this issue, call Customer Service on {' '} <span className="font-bold">{CUSTOMER_SERVICE_CENTRE}</span>.
                </p>
                <div className="btn-control-container">
                    <TymeButton
                        id={BUTTON_ID.NOT_FICA_BACK_LOGIN}
                        name={BUTTON_NAME.BACK_LOGIN}
                        onClick={onLoginIB}
                        className="btn tyme-btn btn-primary btn-back-login"
                    >
                        Back to login page
                    </TymeButton>
                </div>
            </div>
        </ModalTemplate>
    );
};

NotRemoveFICAModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onLoginIB: PropTypes.func.isRequired,
};

export default NotRemoveFICAModal;
