import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
    CAPTURE_TC,
    captureTCSuccessAction,
} from '../actions/captureTC';
import {
    showCommonExceptionAction,
    moveCreditCardStepAction,
} from '../actions/application';
import { CREDIT_CARD_RESET_STEPS } from '../constants';

export const captureTCEpic = (action$, store, { captureTCService }) =>
action$.pipe(
    ofType(CAPTURE_TC),
    switchMap(action => {
        const { sessionId, consent } = action.payload;

        const data = {
            sessionId,
            tncItems: [
                {
                    category: "BankTnc",
                    consent,
                    type: "TymeDigital",
                }
            ]
        }
        
        return captureTCService(data).pipe(
            mergeMap(() => {
                return Observable.of( 
                    captureTCSuccessAction(),
                    moveCreditCardStepAction(CREDIT_CARD_RESET_STEPS.creditCard.stepId)
                )
            }),
            catchError(() => {
                return Observable.of(showCommonExceptionAction());
            }),
        );
    }),
);
