export const getRequestToken = state => state.searchSaid.requestToken;
export const getIsSearchingSaid = state => state.searchSaid.isSearchingSaid;
export const getSaid = state => state.searchSaid.said;
export const getIsNotActivated = state => state.searchSaid.isNotActivated;

export const getIsRequestingOTP = state => state.newOtp.isRequestingOTP;
export const getIsRequestingOTPFailed = state => state.newOtp.isRequestOTPFailed;
export const getCellphoneNumber = state => state.newOtp.cellphoneNumber;
export const getIsVerifyingOTP = state => state.newOtp.isVerifyingOTP;
export const getIsVerifyingOTPFailed = state => state.newOtp.isVerifyOTPFailed;
export const getOtpRemainingSecond = state => state.newOtp.otpRemainingSecond;
export const getStepUpToken = state => state.newOtp.stepUpToken;

export const getIsRequestMCQ = state => state.newMcq.isRequestMCQ;
export const getMCQQuestions = state => state.newMcq.mcq.questions;
export const getMCQSessionId = state => state.newMcq.mcq.sessionId;
export const getIsVerifyMCQ = state => state.newMcq.isVerifyMCQ;
export const getIsVerifyMCQFailed = state => state.newMcq.isVerifyMCQFailed;
export const getIsMCQAttempError = state => state.newMcq.isMCQAttemptError;
export const getIsShowRequestMCQFailedModal = state => state.newMcq.isShowRequestMCQFailedModal;
export const getRequestMCQFailedError = state => state.newMcq.requestMCQFail;

export const getResetPinStep = state => state.newResetPin.step;
export const getResetPinErrors = state => state.newResetPin.errors;

export const getIsCheckingEligibility = state => state.checkEligibility.isCheckingEligibility;

export const getIsVerifyCardPan = state => state.cardPan.isVerifyCardPan;
export const getIsCardAttemptError = state => state.cardPan.isCardAttemptError;

export const getIsSettingCredential = state => state.setCredential.isSettingCredential;

export const getIsShowCommonException = state => state.application.isShowCommonException;
export const getIsShowBlockFeature = state => state.application.isShowBlockFeature;
export const getIsShowBlockProfile = state => state.application.isShowBlockProfile;
export const getIsShowConfirmBeforeExit = state => state.application.isShowConfirmBeforeExit;
export const getIsShowTimeOut = state => state.application.isShowTimeOut;

export const getCreditCardAccessToken = state => state.newReCaptcha.token;
export const getIsCreditCardEnable = state => state.newReCaptcha.isCreditCardEnable;
export const getCCSaid = state => state.searchCCSaid.idNumber;
export const getIsSearchingCCSaid = state => state.searchCCSaid.isSearchingCCSaid;
export const getIsSearchCCSaidFailed = state => state.searchCCSaid.failed;
export const getCreditCardActivationStep = state => state.creditCardActivation.step;
export const getCreditCardActivationErrors = state => state.creditCardActivation.errors;
export const getIsShowBlockCreditCardProfile = state => state.application.isShowBlockCreditCardProfile;
export const getIsShowNoActiveCreditCard = state => state.application.isShowNoActiveCreditCard;
export const getIsVerifyOTPSuccess = state => state.newOtp.isVerifyOTPSuccess;
export const getCreditCardRequestToken = state => state.searchCCSaid.requestToken;
export const getIsShowBlockCreditCardFeature = state => state.application.isShowBlockCreditCardFeature;
export const getIsShowNotCreditCardCustomer = state => state.application.isShowNotCreditCardCustomer;
export const getIsCheckingCreditCardEligibility = state => state.searchCCSaid.isCheckingCreditCardEligibility;
export const getCreditCardSessionId = state => state.searchCCSaid.sessionId;
export const getCreditCardConsent = state => state.captureTC.consent;
export const getIsVerifyCreditCardPan = state => state.creditCardPan.isVerifyCreditCardPan;
export const getIsCreditCardAttemptError = state => state.creditCardPan.isCreditCardAttemptError;
export const getIsShowCreditCardNotEligible = state => state.application.isShowCreditCardNotEligible;
export const getIsShowConfirmPayTax = state => state.application.isShowConfirmPayTax;
export const getIsShowNotRemoveFica = state => state.application.isShowNotRemoveFica;
export const getIsVerifyCreditCardPanFailed = state => state.creditCardPan.isVerifyCreditCardPanFailed;
export const getCardPanErrorCode = state => state.creditCardPan.errorCode;
export const getErrorAttempt = state => state.creditCardPan.errorAttempt;
export const getIsCCSettingPin = state => state.setCredentialCCPin.isCCSettingPin;
export const getIsShowSetPasswordLaterModal = state => state.application.isShowSetPasswordLaterModal;
export const getIsCCSettingPassword = state => state.setCredentialCCPassword.isCCSettingPassword;
export const getIsCCSettingPasswordSuccess = state => state.setCredentialCCPassword.isCCSettingPasswordSuccess;
export const getIsCCSettingPasswordFailed = state => state.setCredentialCCPassword.isCCSettingPasswordFailed;
export const getPinErrorCode = state => state.setCredentialCCPin.errorCode;
export const getIsCCSettingPinSuccess = state => state.setCredentialCCPin.isCCSettingPinSuccess;
export const getIsCCSettingPinFailed = state => state.setCredentialCCPin.isCCSettingPinFailed;
export const getPinErrorAttempt = state => state.setCredentialCCPin.errorAttempt;
export const getIsShowPasswordTechnicalError = state => state.application.isShowPasswordTechnicalError;
export const getIsShowRetryResult = state => state.application.isShowRetryResult;
export const getCRSLoading = state => state.captureTax.loading;
export const getErrorPasswordAttempt = state => state.setCredentialCCPassword.errorPasswordAttempt;
export const getIsShowPinTechnicalError = state => state.application.isShowPinTechnicalError;
export const getIsShowSkipMCQResetPin = state => state.application.isShowSkipMCQResetPin;
export const getIsShowSkipMCQResetPassword = state => state.application.isShowSkipMCQResetPassword;